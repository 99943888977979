// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { icons } from '../../elements/icons';

type SendMessageWidgetViewProps = {
  text: string,
  onSubmit: string => void,
  onChange: string => void,
}

export default function SendMessageWidgetView({
  text,
  onSubmit,
  onChange,
}: SendMessageWidgetViewProps) {
  const { t } = useTranslation();

  let inputEl = null;

  return (
    <div className="send-message">
      <form
        className="input-group"
        onSubmit={e => {
          e.preventDefault();
          onSubmit(text);
          // Return focus to continue chatting
          if (inputEl) {
            inputEl.focus();
          }
        }}
      >
        <input
          ref={el => { inputEl = el }}
          type="text"
          className="form-control"
          placeholder={t('Your message')}
          aria-label={t('Your message')}
          value={text}
          onChange={e => onChange(e.currentTarget.value)}
        />
        {text ? (
          <div className="input-group-append">
            <button type="submit" className="btn input-group-text" title={t('Send')}>
              {icons.send}
            </button>
          </div>
        ) : null}
      </form>
    </div>
  );
}
