// @flow
import React from 'react';
import type { EventMenuWidgetViewProps } from './views/EventMenuWidgetView';
import type { ImageMeta } from '../core/data/rules';

export type LayoutMeta = {
  title: string,
  titleImage?: ?ImageMeta,
  titleLink?: ?string,
  showHamburger?: boolean,
  showTitle?: boolean,
  onBack?: ?() => void,
  eventMenu?: ?EventMenuWidgetViewProps,
}

export type LayoutContextContents = {
  screen: string,
  width: number,
  contentWidth: number,
  height: number,
  contentHeight: number,
  meta: LayoutMeta,
  setMeta: LayoutMeta /* partial */ => void,
}

export default React.createContext<LayoutContextContents>(null);

function getScreenFromWidth(width) {
  return (width >= 700 ? 'desktop' : 'mobile');
}

function getContentWidth(width) {
  return width;
}

const bottomPadding = 24;
const eventMenuHeight = 57;

function getContentHeight(height, isLargerThanPhone, hasTopBar, hasEventMenu) {
  return height
    - (hasEventMenu ? eventMenuHeight : 0)
    - (isLargerThanPhone ? 40 : 0);
}

export function fillLayoutContext({
  width,
  height,
  topBar = null,
  eventMenu = null,
  meta,
  setMeta,
}): LayoutContextContents {
  const screen = getScreenFromWidth(width);
  const pageBodyHeight = getContentHeight(
    height,
    width >= 400,
    topBar != null && topBar.title != null,
    eventMenu != null
  );
  return {
    screen,
    width,
    contentWidth: getContentWidth(width),
    height,
    pageBodyHeight,
    contentHeight: pageBodyHeight - bottomPadding,
    bottomPadding,
    meta,
    setMeta,
  };
}
