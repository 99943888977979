// @flow
import { loginPageRoutes } from './LoginPage';
import { registerPageRoutes } from './RegisterPage';
import { authHomePageRoutes } from './AuthHomePage';
// ### GENERATED IMPORTS GO HERE ###

export function authRoutes() {
  return [
    loginPageRoutes(),
    registerPageRoutes(),
    authHomePageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ];
}
