// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import { string, nested } from '../../core/data/rules';
import { remindOptionsMetaModel } from './RemindOptions.model';
import type { Event } from './Event.model';
import type { User } from '../../users/model/User.model';
import type { RemindOptions } from './RemindOptions.model';
import type { MetaModel } from '../../core/data/rules';

export type UserEvent = {|
  id: string,
  eventId: string,
  event: Event,
  userId: string,
  user: User,
  status: string,
  remindOptions: RemindOptions,
|}


export const userEventMetaModel: MetaModel = {
  name: 'UserEvent',
  rules: {
    eventId: [string(), required()],
    userId: [string(), required()],
    status: [string(), required()],
    remindOptions: [nested(remindOptionsMetaModel), required()],
  },
};
