// @flow
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isPlainObject from "lodash/isPlainObject";
import type { Routes } from "metaup/routing/routingUtils";
import { sanitizeRouteParams } from "metaup/routing/routingUtils";
import ErrorCapsule from "../core/exceptions/ErrorCapsule";
import PollPageView from "./views/PollPageView";
import { deletePollVote } from "./model/PollVote.client";
import type { LayoutContextContents } from "../layout/LayoutContext";
import LayoutContext from "../layout/LayoutContext";
import { redirect } from "../core/data/router.redux";
import { getEvent } from '../events/model/Event.client';
import { editPoll, extractPollVotes, removePoll, saveVote } from './utils';
import { joinEvent } from '../events/EventPage';
import { listUserEvents } from '../events/model/UserEvent.client';

const eventShape = `{
  id
  title
  start
  startTime
  end
  endTime
  description
  organizerId
}`;


type Props = {
  id?: string,
  noBack?: string,
};

function PollPage({ id, noBack }: Props) {
  const dispatch = useDispatch();
  const { setMeta }: LayoutContextContents = useContext(LayoutContext);
  // eslint-disable-next-line no-shadow
  const { polls }: PollState = useSelector(({ polls }) => polls.pollX);

  const currentUserId = useSelector(({ auth }) => auth.state.id);
  const [isAdmin, setIsAdmin] = useState(false);
  const [vote, setVote] = useState([]);
  const [poll, setPoll] = useState(null);
  const [event, setEvent] = useState(null);

  async function removeQuestionVotes(questionId) {

    const votes = vote.filter(
      (x) => x.questionId === questionId && x.userId === currentUserId,
    );

    try {

      if (votes.length) {
        const removedVotes = votes.map(async (v) => {
          await deletePollVote(v.id);
        });
        await Promise.all(removedVotes);
      }

    } catch (err) {

      return removeQuestionVotes(
        new ErrorCapsule(err, () => [removeQuestionVotes(questionId)]),
      );
    }
    return true;
  }

  // Filter Particular event Poll
  useEffect(() => {
    if (!polls)
      return;
    const tmp = polls.filter((p) => {
      return p.eventId === id;
    });
    setPoll(tmp);
  }, [polls]);

  // Load Event
  useEffect(() => {
    const fetchData = async () => {
      const result = await getEvent(id, eventShape);
      setEvent(result);
    };
    fetchData();

  }, [id]);

  // check admin and set Meta
  useEffect(() => {

    if (event && !(event instanceof ErrorCapsule)) {
      setIsAdmin(
        event.organizerId === currentUserId || currentUserId === 'admin',
      );
      setMeta({
        title: event.title,
        titleLink: `/events/${event.id}/`,
        titleImage: event.cover ? event.cover.cover : null,
      });
    }

  }, [event, currentUserId]);

  // Extract Votes from Poll
  useEffect(() => {
    if (poll && !(poll instanceof ErrorCapsule)) {
      extractPollVotes(poll, setVote);
    }
  }, [poll]);

  // Join event automatically on No Back Poll Link
  useEffect(() => {
    if (noBack && event) {
      const eventJoin = async () => {
        const userEvent = await listUserEvents({
          userId: currentUserId,
          eventId: event.id,
        }, `{id}`);
        if (!userEvent.length)
          await joinEvent(event.id, currentUserId);
      };
      eventJoin();
    }
  }, [noBack, event]);

  // Check if Every Question in the Poll has been voted
  const checkAllVoted = (singlePoll) => {

    let temp = true;
    if (
      singlePoll &&
      !(singlePoll instanceof ErrorCapsule) &&
      vote &&
      !(vote instanceof ErrorCapsule)
    ) {
      singlePoll.questions.map((question) => {
        temp =
          temp &&
          vote.filter(
            (v) => v.questionId === question.id && v.userId === currentUserId,
          ).length !== 0;
        return true;
      });
    }
    return temp;
  };

  // redirect to Poll Creation screen
  const redirectCreatePoll = () => {
    dispatch([redirect('/polls/' + event.id + '/new')]);
  };

  // redirect to Poll Edit screen
  const redirectEditPoll = (pollId) => {
    dispatch([
      redirect('/polls/' + event.id + '/edit/' + pollId),
    ]);
  };

  // redirect to Poll Full Screen
  const redirectPollFull = (pollId) => {
    const win = window.open('/polls/full/' + pollId, "_blank");
    win.focus();
  };


  // Render
  return (
    <PollPageView
      currentUserId={currentUserId}
      polls={_isPlainObject(poll) ? poll : poll}
      userVotes={vote}
      onSubmit={async (values, pollId) => {
        await saveVote(values, currentUserId, pollId);
      }}
      deleteVote={async (questionId) => {
        await removeQuestionVotes(questionId, currentUserId, id);
      }}
      updatePoll={async (values) => {
        await editPoll(values);
      }}
      allVoted={checkAllVoted}
      deletePoll={async (pollToDeleted) => {
        await removePoll(pollToDeleted);
      }}
      redirectCreatePoll={redirectCreatePoll}
      redirectEditPoll={redirectEditPoll}
      redirectPollFull={redirectPollFull}
      isAdmin={isAdmin}
    />
  );
}

export function pollPageRoutes(): Routes {
  return [
    {
      title: (t) => t('Poll'),
      path: ':id/noback/',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        showTitle: true,
        onBack: null,
        eventMenu: 'poll',
      },
      render: (params) => (
        <PollPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
          noBack='true'
        />
      ),
    },
    {
      title: (t) => t('Poll'),
      path: ':id/',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        showTitle: true,
        onBack: (history) => history.goBack(),
        eventMenu: 'poll',
      },
      render: (params) => (
        <PollPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
    },

  ];
}
