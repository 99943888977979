// @flow
import React from 'react';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { RouteDef } from 'metaup/routing/routingUtils';
import Error404PageView from './views/Error404PageView';

export default function Error404Page() {
  return <Error404PageView />;
}

export function error404PageCatchingRoute(): RouteDef {
  return {
    title: t => t('Error 404'),
    path: '/',
    isPrefix: true,
    render: params => (
      <Error404Page
        {...sanitizeRouteParams(params, {
          id: 'id',
        })}
      />
    ),
    design: require('./views/Error404PageView.design@mobile.png'), // eslint-disable-line global-require
  };
}
