// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Set } from 'immutable';
import { useTranslation } from 'react-i18next';
import { bemCompose, renderImageMeta } from 'metaup/render/visualUtils';

import type { UserEvent } from '../model/UserEvent.model';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { icons } from '../../elements/icons';

type AttendeesPageViewProps = {
  userEvents: null | Array<UserEvent> | ErrorCapsule,
  filter: string | false,
  expanded: Set<string>,
  onFilterChange: string => void,
  onSetExpanded: (userId: string, expanded: boolean) => void,
}

export default function AttendeesPageView({
  userEvents,
  filter,
  expanded,
  onFilterChange,
  onSetExpanded,
}: AttendeesPageViewProps) {
  const { t } = useTranslation();

  if (!userEvents) return <Loader />;
  if (userEvents instanceof ErrorCapsule) return <PageLoadFailed error={userEvents} />;

  return (
    <div className="attendees">
      {filter === false ? null : (
        <div className="input-group input-group-shadowed attendees__wrap-search">
          <input
            type="text"
            className="form-control"
            placeholder={t('Search')}
            aria-label={t('Search')}
            value={filter}
            onChange={e => onFilterChange(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">{icons.search}</span>
          </div>
        </div>
      )}
      {userEvents.map(({
        user: {
          id,
          firstName,
          lastName,
          avatar,
          position,
          companyTitle,
          interests,
          lookingFor,
        },
      }: UserEvent) => (
        <span key={id} className="attendees__user">
          <Link to={`/people/${id}/`} className="attendees__summary d-flex">
            <span className="attendees__avatar">
              {avatar ? renderImageMeta(avatar.cover, `${firstName} ${lastName}`) : null}
            </span>
            <span className="attendees__body">
              <span className="attendees__name">{firstName} {lastName}</span>
              <span className="attendees__position">{position}</span>
              <span className="attendees__company">{companyTitle}</span>
              { interests || lookingFor ?
                <button
                  type="button"
                  className={'btn ' + bemCompose('attendees', 'toggle', { expand: !expanded.has(id) })}
                  onClick={e => { onSetExpanded(id, !expanded.has(id)); e.preventDefault() }}
                >
                  {icons.collapse}
                </button> :null
              }
            </span>
          </Link>
          <span className={bemCompose('attendees', 'details', { expanded: expanded.has(id) })}>
            <span className="attendees__detail-text">
              <span className="attendees__detail-title">{t('Interested in:')}</span>
              {' '} {interests}
            </span>
            <span className="attendees__detail-text">
              <span className="attendees__detail-title">{t('Looking for:')}</span>
              {' '} {lookingFor}
            </span>
          </span>
        </span>
      ))}
    </div>
  );
}
