// @flow
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { email } from 'redux-form-validators';
import CombinedMetaModel from 'metaup/data/CombinedMetaModel';
import { renderImageMeta } from 'metaup/render/visualUtils';

import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
} from '../../elements/DefaultForm';
import type { User } from '../model/User.model';
import { userMetaModel } from '../model/User.model';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import LayoutContext from '../../layout/LayoutContext';

type ProfileEditPageViewProps = {
  user: null | User | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
  onAvatarUpload: x => void,
}

const combinedMetaModel = new CombinedMetaModel();
combinedMetaModel.add(userMetaModel);
combinedMetaModel.add({
  rules: {
    phone: [],
    contactEmail: [email()], // Note: there's the email field in User
  },
});

export default function ProfileEditPageView({
  user,
  onSubmit,
  onAvatarUpload,
}: ProfileEditPageViewProps) {
  const { t } = useTranslation();
  const { pageBodyHeight } = useContext(LayoutContext);

  if (!user) return <Loader />;
  if (user instanceof ErrorCapsule) return <PageLoadFailed error={user} />;

  const { avatar } = user;

  return (
    <DefaultForm
      className="profile-edit layout__revert-bottom-padding d-flex flex-column"
      style={{ height: pageBodyHeight + 'px' }}
      form="profileEdit"
      metaModel={combinedMetaModel}
      initialValues={user}
      onSubmit={values => onSubmit(values)}
    >
      <div className="layout__nested-body">
        <div className="page-cover">
          {avatar ? renderImageMeta(avatar.cover, t('Avatar')) : null}
          <label className="page-cover__btn">
            {t('Photo')}
            <input
              type="file"
              style={{ position: 'absolute', left: '-9999em' }}
              onChange={e => {
                if (e.target.files.length) {
                  onAvatarUpload(e.target.files[0]);
                }
              }}
            />
          </label>
        </div>
        <div className="profile-edit__form">
          <DefaultFormGlobalValidation />
          <DefaultFormText attribute="firstName" autoFocus />
          <DefaultFormText attribute="lastName" />
          <DefaultFormText attribute="position" />
          <DefaultFormText attribute="companyTitle" />
          <h2 className="form-section-header">{t('Personal goals')}</h2>
          <DefaultFormText attribute="interests" />
          <DefaultFormText attribute="lookingFor" />
          <h2 className="form-section-header">{t('Contact information')}</h2>
          <DefaultFormText attribute="phone" label={t('Phone')} />
          <DefaultFormText attribute="contactEmail" label={t('Email')} />
        </div>
      </div>
      <div className="layout__bottom-button-place">
        <DefaultFormSubmit label={t('Save')} />
      </div>
    </DefaultForm>
  );
}
