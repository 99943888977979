// @flow
import uuid from 'uuid/v4';

let errorHandler = (error, errorId, extras?) => {
  console.error('#' + errorId, error, extras);
};

export function setErrorHandler(fn: (Error, errorId: string, extras?: any) => void) {
  errorHandler = fn;
}

function newErrorId() {
  return uuid();
}

export function asyncErrorHandler(promise: Promise<any>) {
  promise.catch(error => {
    errorHandler(error, newErrorId());
  });
}

export function handleError(error: any, extras?): string {
  const errorId = newErrorId();
  errorHandler(error, errorId, extras);
  return errorId;
}
