// @flow
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { required } from "redux-form-validators";
import moment from "moment";
import LayoutContext from "../../layout/LayoutContext";
import {
  DefaultForm,
  DefaultFormArrayField2,
  DefaultFormGlobalValidation,
  DefaultFormSubmit2,
  DefaultFormText2,
} from "../../elements/DefaultForm";
import type { MetaModel } from "../../core/data/rules";
import { string } from "../../core/data/rules";
import Loader from "../../elements/Loader";
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import PageLoadFailed from "../../elements/PageLoadFailed";
import type { Poll } from "../model/Poll.model";
import { focusOnFirstError } from '../utils';

export const eventPollMetaModel: MetaModel = {
  rules: {
    title: [],
    questionType: [],
    publishDetails: [required()],
    publishAt: [],
    pollTitle: [],
    "questions[0]title": [string(), required()],
    "questions[0]questionType": [],
    "questions[0]choices[0]title": [required()],
    "questions[0]choices[1]title": [required()],
  },
};

export type PollAddPageViewProps = {
  poll: null | Array<Poll> | ErrorCapsule,
  pollId: string,
  onCancel: () => void,
  onSubmit: () => void,
};

export default function PollAddPageView(props: PollAddPageViewProps) {
  const { t } = useTranslation();
  const { onSubmit, onCancel, poll, pollId } = props;
  const { pageBodyHeight } = useContext(LayoutContext);
  const [isMultiQuestion, setIsMultiQuestion] = useState(!!(poll && poll.questions.length > 1));

  // Initial Values for the Form 
  const initialValue = {
    questions: [{ choices: [{}, {}], questionType: "Single Choice Question" }],
    publishAt: moment
      .utc()
      .add(10, "year")
      .format("YYYY-MM-DD HH:mm:ss"),
    createdAt: moment
      .utc()
      .add(10, "year")
      .format("YYYY-MM-DD HH:mm:ss"),
    publishDetails: "Later",
  };

  // Set Initial Values and Initial Meta Rules for Questions and Choices
  useEffect(() => {
    if (poll && poll.questions) {
      const initialQuestions = [];
      poll.questions.map((question, index) => {
        eventPollMetaModel.rules["questions[" + index + "]title"] = [
          required(),
        ];
        eventPollMetaModel.rules["questions[" + index + "]questionType"] = [
          required(),
        ];
        const questionNo = `questions[${index}]`;
        const initialChoices = [];
        question.choices.map((choice, i) => {
          eventPollMetaModel.rules[`${questionNo}choices[${i}]title`] = [
            string(),
            required(),
          ];
          initialChoices.push({ ...choice });
          return true;
        });
        initialQuestions.push({ ...question, choices: initialChoices });
        return true;
      });
      initialValue.questions = initialQuestions;
      if (poll.title) {
        initialValue.pollTitle = poll.title;
      }
      setIsMultiQuestion(poll.questions.length > 1);
    }
  }, [poll]);

  if (pollId && !poll) return <Loader/>;

  if (poll instanceof ErrorCapsule) return <PageLoadFailed error={poll}/>;

  return (
    <div
      className="event-poll layout__revert-bottom-padding d-flex flex-column"
      style={{ height: pageBodyHeight + "px" }}
    >
      <div className="layout__nested-body mt-5">
        <div className="event-poll-add__title">{t("Base settings")}</div>

        {/* Display Type of the Poll */}
        <div className="mt-1">
          <div className="event-poll-add__label mb-1">{t("Poll Type")}</div>
          <div className="d-flex justify-content-center">

            {/* one Question Button */}
            <button
              type="button"
              className={`btn btn-primary btn-md event-poll-add__${
                isMultiQuestion
                  ? "inactive-question-button"
                  : "active-question-button"
              }`}
              onClick={() => {
                setIsMultiQuestion(false);
              }}
            >
              {t("One Question")}
            </button>

            {/* Multi Question Button */}
            <button
              type="button"
              className={`btn btn-primary btn-md event-poll-add__${
                isMultiQuestion
                  ? "active-question-button"
                  : "inactive-question-button"
              }`}
              onClick={() => {
                setIsMultiQuestion(true);
              }}
            >
              {t("Multi Question")}
            </button>
          </div>
        </div>
        <div className="event-poll-add__form">
          <DefaultForm
            form="event-poll"
            metaModel={eventPollMetaModel}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            onSubmitFail={(errors) => {
              focusOnFirstError(errors);
            }}
            initialValues={initialValue}
          >
            <DefaultFormGlobalValidation/>

            {/* show poll title field if multi Question */}
            {isMultiQuestion ? (
              <DefaultFormText2
                hint={t("e.g. My Poll")}
                attribute="pollTitle"
                type="text"
                autoFocus
                label={t("Poll Title")}
              />
            ) : null}

            {/* Display Question's Array Field */}
            <DefaultFormArrayField2
              name="questions"
              eventPollMetaModel={eventPollMetaModel}
              isMultiQuestion={isMultiQuestion}
              poll={poll}
            />
            {/* Commented Code Block for future Use */}
            {/*  <div className="form-group">
              <div className="event-poll-add__title">Production Details</div>
              <div className="d-flex flex-column mt-2 pl-2">
                <div className="d-flex event-poll-add__publication-title ">
                  <DefaultFormRadio
                    attribute="publishDetails"
                    label={null}
                    value="Now"
                    styleClass="event-poll-add__publication-radio "
                    setPublishAt={setPublishAt}
                  />
                  <div className="ml-2"> Publish Now</div>
                </div>
                <div className="d-flex event-poll-add__publication-title mt-2">
                  <DefaultFormRadio
                    attribute="publishDetails"
                    label={null}
                    value="Later"
                    styleClass="event-poll-add__publication-radio "
                    setPublishAt={setPublishAt}
                  />
                  <div className="ml-2"> Publish Later</div>
                </div>
              </div>
              {publishAt ? (
                <div className="d-flex align-items-center">
                  <div className="mr-2">{icons.calender}</div>
                  <DefaultFormDateTime
                    attribute="publishAt"
                    type="datetime-local"
                    label={null}
                    stretched
                    value={scheduledTime}
                    min={new Date(event.start + ' ' + event.startTime)
                      .toISOString()
                      .replace('Z', '')}
                    max={new Date(event.end + ' ' + event.endTime)
                      .toISOString()
                      .replace('Z', '')}
                    onChange={(value) => {
                      setScheduledTime(
                        new Date(value).toISOString().replace('Z', '')
                      );
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="d-flex mb-3  event-poll-add__time-difference">
              {publishAt ? (
                new Date(scheduledTime + 'Z') - new Date() < 0 ? (
                  <div> You have chosen time in the past </div>
                ) : (
                  <div>
                    The poll will be posted after{' '}
                    {duration(new Date(), new Date(scheduledTime + 'Z'))}
                  </div>
                )
              ) : null}
            </div> */}

            {/* Save and Cancel Button */}
            <div className="d-flex mb-3 justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-md event-poll-add__cancel-button"
                onClick={onCancel}
              >
                {t("Cancel")}
              </button>
              <DefaultFormSubmit2
                label={t("Save")}
                styleClass="event-poll-add__save-button ml-2"
              />
            </div>
          </DefaultForm>
        </div>
      </div>
    </div>
  );
}


