// @flow
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { bemCompose, renderImageMeta } from 'metaup/render/visualUtils';

import type { Message } from '../model/Message.model';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import SendMessageWidget from '../SendMessageWidget';
import type { SendMessageWidgetViewProps } from './SendMessageWidgetView';
import LayoutContext from '../../layout/LayoutContext';
import ScrollToBottomArea from '../../elements/ScrollToBottomArea';
import type { LayoutContextContents } from '../../layout/LayoutContext';

export type ChatPageViewProps = {
  currentUserId: string,
  messages: null | Array<Message> | ErrorCapsule,
  sendMessageWidget: SendMessageWidgetViewProps,
  hideDelivered?: boolean,
}

const sendMessageWidgetBottomFrame = 16; // px

export default function ChatPageView({
  currentUserId,
  messages,
  sendMessageWidget,
  hideDelivered,
}: ChatPageViewProps) {
  const { t } = useTranslation();
  const { pageBodyHeight }: LayoutContextContents = useContext(LayoutContext);

  if (!messages) return <Loader />;
  if (messages instanceof ErrorCapsule) return <PageLoadFailed error={messages} />;

  let previousUserId = null;

  return (
    <div
      className="chat layout__revert-bottom-padding d-flex flex-column"
      style={{ height: (pageBodyHeight - sendMessageWidgetBottomFrame) + 'px' }}
    >
      <ScrollToBottomArea className="chat__body flex-grow-1">
        {messages.map(({
          id,
          userId,
          user,
          text,
          sent,
        }: Message) => {
          let result;
          if (userId === currentUserId) {
            result = (
              <div
                key={id}
                className={bemCompose('chat', 'message', { next: previousUserId === userId })}
              >
                <div className="chat__text chat__text_my">
                  <div className="chat__msg-text">{formatLinks(text, 'chat__link chat__link_my')}</div>
                </div>
                {!hideDelivered && <div className="chat__delivery">Delivered</div>}
              </div>
            );
          } else {
            if (previousUserId !== userId) {
              result = (
                <div key={id} className="chat__message d-flex align-items-start">
                  <Link to={`/people/${userId}/`} className="chat__user">
                    {user.avatar ? renderImageMeta(user.avatar.cover, user.firstName, 'g__avatar') : null}
                  </Link>
                  <div className="chat__text chat__text_their">
                    <div className="chat__user-name">{user.firstName} {user.lastName}</div>
                    <div className="chat__msg-text">{formatLinks(text, 'chat__link chat__link_their')}</div>
                    <div className="chat__msg-sent">{moment(sent + 'Z').format('H:mm')}</div>
                  </div>
                </div>
              );
            } else {
              result = (
                <div key={id} className="chat__message chat__message_next d-flex align-items-start">
                  <div className="chat__text chat__text_their">
                    <div className="chat__msg-text">{formatLinks(text, 'chat__link chat__link_their')}</div>
                    <div className="chat__msg-sent">{moment(sent + 'Z').format('H:mm')}</div>
                  </div>
                </div>
              );
            }
          }
          previousUserId = userId;
          return result;
        })}
      </ScrollToBottomArea>
      <SendMessageWidget {...sendMessageWidget} />
    </div>
  );
}

function formatLinks(text: string, linkClass: boolean) {
  const items = text.split(' ');
  const lastIndex = items.length - 1;
  return (
    <>
      {items.map((txt, index) => {
        if (isLink(txt)) {
          return (
            <>
              <a
                className={linkClass}
                key={txt}
                target="_blank"
                rel="noopener noreferrer"
                href={!/^\w+:/.test(txt) ? 'https://' + txt : txt}
              >
                {txt}
              </a>{(index < lastIndex) ? ' ' : null}
            </>
          );
        } else {
          return (index < lastIndex) ? txt + ' ' : txt;
        }
      })}
    </>
  );
}

export function isLink(text: string) {
  // RegEx URL = https://stackoverflow.com/a/19696443
  // eslint-disable-next-line no-useless-escape
  const urlRegex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  return !!text.match(urlRegex);
}
