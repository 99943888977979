// @flow
import React from 'react';
import { Router } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import './theme/app.scss';
import { getHistory } from './data/router.redux';
import { suspendWhileLoadingI18n } from './i18n/i18n';
import { DialogsProvider } from '../elements/Dialog';
import GlobalErrors from '../elements/GlobalErrors';

export default function App() {
  suspendWhileLoadingI18n();
  return (
    <Router history={getHistory()}>
      <DialogsProvider>
        <GlobalErrors />
        <MainLayout />
      </DialogsProvider>
    </Router>
  );
}
