// @flow
import React, { useContext, useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import _isPlainObject from 'lodash/isPlainObject';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import { createMessage, onChatMessages } from './model/Message.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import ChatPageView from './views/ChatPageView';
import type { Message } from './model/Message.model';
import RealtimeCollection from '../core/data/RealtimeCollection';
import type { Chat } from './model/Chat.model';
import { getChat, getChatForEvent, getOrCreatePmChat } from './model/Chat.client';
import { emitMarkChatRead } from './redux/Chat.redux';
import type { LayoutContextContents, LayoutMeta } from '../layout/LayoutContext';
import LayoutContext from '../layout/LayoutContext';

type ChatState = {
  chat: null | Chat | ErrorCapsule,
}

export const messageShape = `{
  id
  userId
  user {
    id
    firstName
    lastName
    avatar {
      id
      cover(width: 36, height: 40) { width height url }
    }
  }
  text
  sent
  read
  delivered
}`;

const chatShape = `{
  id
  messages ${messageShape}
  event {
    id
    title
    cover {
      id
      cover(width: 40, height: 40) { width height url }
    }
  }
  partner {
    id
    firstName
    lastName
    avatar {
      id
      cover(width: 40, height: 40) { width height url }
    }
  }
}`;

const initialState: ChatState = {
  chat: null,
};

const ACTION_SET_CHAT = 'messenger/chat/ACTION_SET_CHAT';
const ACTION_SET_MESSAGES = 'messenger/chat/ACTION_SET_MESSAGES';

function setChat(chat: null | Array<Message> | ErrorCapsule) {
  return {
    type: ACTION_SET_CHAT,
    chat,
  };
}

function setMessages(messages: null | Array<Message> | ErrorCapsule) {
  return {
    type: ACTION_SET_MESSAGES,
    messages,
  };
}

async function loadChat({ id, toUserId, eventId }) {
  try {
    let currentChat: Chat;
    if (id) {
      currentChat = await getChat(id, chatShape);
    } else if (toUserId) {
      currentChat = await getOrCreatePmChat(toUserId, chatShape);
    } else {
      currentChat = await getChatForEvent(eventId, chatShape);
    }

    return [
      setChat(currentChat),
      emitMarkChatRead(currentChat.id),
    ];
  } catch (err) {
    return setChat(new ErrorCapsule(err, () => [
      setChat(null),
      loadChat({ toUserId, eventId }),
    ]));
  }
}

export function chatReducer(
  state: ChatState = initialState,
  action: AnyAction,
) {
  switch (action.type) {

    case ACTION_SET_CHAT:
      return {
        ...state,
        chat: action.chat,
      };

    case ACTION_SET_MESSAGES:
      return {
        ...state,
        chat: {
          ...state.chat,
          messages: action.messages,
        },
      };

    default:
      return state;
  }
}

type Props = {
  id?: string,
  toUserId?: string,
  eventId?: string,
}

function ChatPage({
  id,
  toUserId,
  eventId,
}: Props) {
  const dispatch = useDispatch();
  const { setMeta }: LayoutContextContents = useContext(LayoutContext);
  const { chat }: ChatState = useSelector(({ messenger }) => messenger.chat);
  const currentUserId = useSelector(({ auth }) => auth.state.id);
  const [collection, setCollection] = useState(null);

  // Load chat id (and messages)
  useEffect(() => {
    dispatch(loadChat({ toUserId, eventId }));
  }, [id, toUserId, eventId]);

  // Subscribe and load chat
  useEffect(() => {
    if (!_isPlainObject(chat)) {
      return undefined;
    }

    // Set page meta
    const { event, partner } = chat;
    if (event) {
      setMeta({
        title: event.title,
        titleLink: `/events/${event.id}/`,
        titleImage: event.cover ? event.cover.cover : null,
      });
    } else {
      setMeta({
        title: `${partner.firstName} ${partner.lastName}`,
        titleLink: `/people/${partner.id}/`,
        titleImage: partner.avatar ? partner.avatar.cover : null,
      });
    }

    const newCollection = new RealtimeCollection({
      list: () => chat.messages,
      subscribe: listener => onChatMessages(chat.id, messageShape, listener),
      listener: newMessages => {
        dispatch(setMessages(newMessages));
      },
      create: (preId, { chatId, text }) => createMessage({ chatId, text, preId }, messageShape),
    });
    newCollection.safeOpen(capsule => dispatch(setChat(capsule)));
    setCollection(newCollection);

    // Bind unsubscribe
    return () => {
      newCollection.close();
      dispatch(setChat(null));
    };
  }, [chat ? chat.id : null]);

  // Render
  return (
    <ChatPageView
      currentUserId={currentUserId}
      messages={_isPlainObject(chat) ? chat.messages : chat}
      sendMessageWidget={{
        onSend: (text) => {
          collection.create({
            chatId: chat.id,
            text,
            userId: currentUserId,
          });
        },
      }}
      hideDelivered // Not implemented
    />
  );
}

export function chatPageRoutes(): Routes {
  return [
    {
      path: '/to/:toUserId',
      isEnabled: ({ isUser }) => isUser,
      meta: (t): LayoutMeta => ({
        title: t('Direct Message'),
        showHamburger: true,
        showTitle: true,
        onBack: history => history.goBack(),
      }),
      render: (params, auth: RouteAuth) => (
        <ChatPage
          {...sanitizeRouteParams(params, {
            toUserId: 'id',
          })}
        />
      ),
      design: require('./views/ChatPageView.design.mobile.png'), // eslint-disable-line global-require
    },
    {
      title: t => t('Chat'),
      path: '/event/:eventId',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        showTitle: true,
        onBack: history => history.goBack(),
        eventMenu: 'messenger',
      },
      render: params => (
        <ChatPage
          {...sanitizeRouteParams(params, {
            eventId: 'id',
          })}
        />
      ),
      design: require('./views/ChatPageView.design.mobile.png'), // eslint-disable-line global-require
    },
  ];
}
