// @flow


export default class TwilioException extends Error {

  constructor(message?: string, t) {
    let finalMessage = message;
    if (message === "Permission denied") {
      finalMessage = t("No access to your camera or microphone.\nPlease close all apps and browser tabs that can use the camera to avoid conflicts (e.g. Skype, Google Meet, etc.).\nPlease check the settings of your browser - access to the camera should be allowed for this website");
    } else if (message === "Starting video failed" || message === "Could not start video source") {
      finalMessage = t("Error while accessing your camera or microphone.\nPlease check your camera and microphone then refresh the page.\n");
    }
    super(finalMessage || t('Inconsistent twilio error is encountered'));
  }
}