// @flow
import React from 'react';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';

/* eslint-disable max-len */

export default function PrivacyPolicy() {
  return (
    <div className="text-page">
        <h1>WESTA Privacy Policy</h1>
        <p>25 July 2022</p>
        <p>We do not “sell” the personal information of our users as the term “sale” or “sell” is defined in the California Consumer Protection Act (“CCPA”). In other words, we do not sell, or disclose your personal information in exchange for money or something else of value.</p>
    </div>
  );
}

export function privacyPolicyRoutes(): Routes {
  return [
    {
      title: t => t('Privacy Policy'),
      path: '/privacy/',
      nav: {
        showHamburger: true,
        showTitle: true,
      },
      render: (params, { isGuest }: RouteAuth) => (
        <PrivacyPolicy />
      ),
    },
  ];
}
