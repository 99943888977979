// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { pollQuestionMetaModel } from './PollQuestion.model';

export function filterQuestionInput(values) {
  return _pickBy(
    values,
    (_, key) => key in pollQuestionMetaModel.rules || key === 'id',
  );
}

export function listPollQuestions(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: PollQuestionsFilter) {
        listPollQuestions(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function createPollQuestion(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PollQuestionCreate!) {
        createPollQuestion(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function updatePollQuestion(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PollQuestionsFilter!) {
        updatePollQuestion(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function deletePollQuestion(id: string) {
  return apolloSubmitForm(
    gql`
      mutation ($id: String!) {
        deletePollQuestion(id: $id)
      }
    `,
    { id },
  );
}
