// @flow
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import { AnyAction, Dispatch } from 'redux';
import gql from 'graphql-tag';

import type { AsyncAction, GetState } from '../core/data/reduxStore';
import { apolloAuth, apolloQuery } from '../core/data/apolloClient';
import { redirect } from '../core/data/router.redux';
import type { User } from '../users/model/User.model';
import devUtils from '../dev/dev.frontend';

export type AuthStatus = 'loading' | 'guest' | 'user'

export type AuthState = {
  status: AuthStatus,
  id: ?string,
  user: ?User,
}

export const initialState: AuthState = {
  status: 'loading',
  id: null,
  user: null,
};

const ACTION_SET_USER = 'auth/state/ACTION_SET_USER';


export function authStateReducer(state: AuthState = initialState, action: AnyAction): AuthState {

  switch (action.type) {


    case ACTION_SET_USER:
      return {
        ...state,
        status: action.user ? 'user' : 'guest',
        id: action.user ? action.user.id : null,
        user: action.user,
      };


    default:
      return state;
  }
}

export function getAuth(reduxState): AuthState {
  return reduxState.auth.state;
}

export function getAuthUser(reduxState): AuthState {
  const { user } = reduxState.auth.state;
  return user ? user.user : null; // TODO: Rearchitect state structure
}

function saveAuthForQuickStart(user: ?User) {
  localStorage.auth = JSON.stringify(user);
}

function getSavedAuth() {
  // Read
  const json = localStorage.auth;
  if (json) {
    let saved;
    try {
      saved = JSON.parse(json);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error();
    }
    // Avoid problems from previous bugs
    if (_isObject(saved) && _isString(saved.id) && saved.id !== '') {
      return saved;
    }
  }

  return null;
}

export function setUser(user: ?User) {
  // Persist auth
  saveAuthForQuickStart(user);

  // Use token
  if (user) {
    apolloAuth(user.token);
  }

  return {
    type: ACTION_SET_USER,
    user,
  };
}

export const authShape = `{
  id
  token
  user {
    id
    isOrganizer
    isAdmin
    isOnboarded
    firstName
    lastName
    avatar {
      square(width: 200) { url width height }
    }
    interests
  }
}`;

export function logout() {
  return [
    setUser(null),
    redirect('/'),
  ];
}

async function refresh(): ?AsyncAction {
  const result = await apolloQuery(
    gql`
      {
        authRefresh ${authShape}
      }
    `,
  );

  if (!result) {
    return [
      setUser(null),
      redirect('/login/'),
    ];
  }

  return setUser(result);
}

export function authStateInit(dispatch: Dispatch, getState: GetState): void {
  // Override by QA
  const qaStateOverride = devUtils.getQaAuthStateOverride();
  if (qaStateOverride) {
    dispatch(setUser(qaStateOverride === 'guest' ? null : qaStateOverride));
    return;
  }

  // Use saved asap
  const saved = getSavedAuth();
  dispatch(setUser(saved || null));

  // Then refresh
  if (saved) {
    dispatch(refresh());
  }
}
