// @flow
import Validators from 'redux-form-validators';
import _each from 'lodash/each';
import _mapValues from 'lodash/mapValues';
import validatorMessages from './validatorMessages';

export type Model = { [string]: any };

export type Rule = (value: any, allValues: Model) => ?string;
export type RulesList = Array<Rule>;
export type FieldRules = RulesList | {|
  must?: RulesList,
  // Don't: // serverOnly?: RulesList, // Merge rules on backend instead
  clientOnly?: RulesList,
  warn?: RulesList,
|};
export type ModelRules = { [string]: FieldRules };
export type Errors = Array<string>;

export type FileParams = {
  type: 'file' | 'image',
};

export type MetaModel = {|
  name: string,
  rules: ModelRules,
  labels?: { [string]: string },
  hints?: { [string]: string },
  files?: { [string]: FileParams },
|}

export type FileMeta = {|
  id: string,
  url: string,
  mime: ?string,
  size: number,
|}

export type ImageMeta = FileMeta & {|
  width: number,
  height: number,
|}

export type ImageTransformParams = {|
  kind: string,
  width: ?number,
  height: ?number,
|}

export type ImageField = {|
  id: string,
  original?: ImageMeta,
  transformed?: [ImageMeta],
  square?: ImageMeta,
  cover?: ImageMeta,
|}

// Customize messages
Object.assign(Validators.messages, validatorMessages);
export function localizeValidatorMessages(t) {
  Object.assign(Validators.messages, _mapValues(validatorMessages, value => t(value)));
}

// Fix defaults
Object.assign(Validators.defaultOptions, {
  allowBlank: true,
});

export function string() {
  // TODO: Convert number, ignore null or undefined, complain on the rest non-string
  return () => undefined;
}

export function boolean() {
  return () => undefined; // TODO: Implement
}
export function number() {
  return () => undefined; // TODO: Implement
}
export function time() {
  return string(); // TODO: Implement
}

export function datetime() {
  return string(); // TODO: Implement
}

export function html() {
  return string(); // TODO: Implement
}

export function image(value) {
  return () => undefined; // TODO: Implement
}

export function validate(metaModel: MetaModel, model: Model): ?Errors {
  const errors = [];

  // Iterate fields
  _each(metaModel.rules, (rules: FieldRules, fieldName: string) => {
    // Identify subject
    const value = fieldName === '_' ? model : model[fieldName];

    // Apply validators
    (Array.isArray(rules) ? rules : [rules]).forEach(rule => {
      const error = rule(value);
      if (error != null) {
        errors.push(error);
      }
    });
  });

  return errors.length ? errors : null;
}

export function stringifyErrors(errors: ?Errors): ?string {
  return errors ? errors.join('\n') : undefined;
}

export function nested(itsMetaModel: MetaModel): Rule {
  return object => stringifyErrors(validate(itsMetaModel, object));
}

export function reference(to) {
  // TODO: Require format { id: 'something' }
  return object => undefined;
}
