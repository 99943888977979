// @flow
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClipboard } from 'use-clipboard-copy';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { bemCompose } from 'metaup/render/visualUtils';
import { icons } from '../../elements/icons';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Poll } from '../model/Poll.model';
import type { PollVote } from '../model/PollVote.model';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import {
  countChoiceVotes,
  countPollFullChoicePercentage,
  countPollFullUserResponded,
  isPublished,
} from '../utils';

export type PollPageViewProps = {
  poll: null | Poll | ErrorCapsule,
  votes: null | Array<PollVote> | ErrorCapsule,
  redirectPoll: () => void,
  publishPoll: () => void,
  stopPoll: () => void,
  resetPoll: () => void,
  redirect: () => void,
  isAdmin: boolean,
  isBack: boolean,
  isNext: boolean,
};

export default function PollFullPageView(props: PollPageViewProps) {
  const { t } = useTranslation();
  const {
    poll, votes, redirectPoll, isAdmin, publishPoll, stopPoll, resetPoll, redirect, isNext, isBack,
  } = props;
  const clipboard = useClipboard({ copiedTimeout: 750 });
  const handle = useFullScreenHandle();
  const [isStopped, setIsStopped] = useState(false);
  const [isPub, setIsPub] = useState(null);

  useEffect(() => {
    // check if poll is published
    if (poll) {
      const tmpPub = isPublished(poll.publishAt);
      setIsPub(tmpPub);
      if (tmpPub)
        setIsStopped(false);
    }
  }, [poll]);

  // Toggle start stop reset
  const toggleStartStop = async () => {
    if (isStopped === true && votes.length) {
      resetPoll(poll.id);
      await stopPoll(poll.id);
      setIsStopped(false);
    } else if (!isPub) {
      await publishPoll(poll.id);
      setIsStopped(false);
    } else {
      await stopPoll(poll.id);
      setIsStopped(true);
    }
  };

  if (!poll || !votes) return <Loader/>;

  if (poll instanceof ErrorCapsule || votes instanceof ErrorCapsule)
    return <PageLoadFailed error={poll}/>;

  return (
    // Full Screen Module of react-full-screen
    // for more info https://www.npmjs.com/package/react-full-screen
    <FullScreen handle={handle}>
      <div className="poll-full__top-container">
        {/* check if admin or is fullscreen */}
        {isAdmin && !handle.active ? (
          <div className="poll-full__topItemsContainer">

            {/* show Back Button */}
            {isBack ?
              <button
                type="button"
                className="poll-full__previousButton"
                onClick={async () => {
                  await redirectPoll('previous');
                }}
              >
                {' '}
                {icons.leftArrow}
              </button> : null}

            {/* show Next Button */}
            {isNext ?
              <button
                type="button"
                className="poll-full__nextButton"
                onClick={async () => {
                  await redirectPoll('next');
                }}
              >
                {' '}
                {icons.rightArrow}
              </button> : null}

            {/* Clickable Westa Logo  */}
            <button type="button" className="poll-full__LogoContainer" onClick={() => {
              redirect("/");
            }}>
              <div className="poll-full__westaLogo">{icons.pollWesta}</div>
              <div className="poll-full__pollLogo">{icons.pollLogo}</div>
            </button>

            <div className="poll-full__linksContainer">
              {/* Copy Url to clipboard */}
              <input ref={clipboard.target}
                     value={`${window.location.origin}/polls/${poll.event.id}/noback/`} hidden/>

              {/* Copy Url Button */}
              <button type="button" className="d-flex poll-full__linkButton "
                      onClick={clipboard.copy}>
                <div>{icons.link}</div>
                <div className="poll-full__link-text">
                  {clipboard.copied ? t('Copied') : t('Link for attendees')}
                </div>
              </button>

              {/* Full Screen Button */}
              {!handle.active ? (
                <button type="button" className="d-flex mt-2 poll-full__fullScreenButton"
                        onClick={handle.enter}>
                  <div>{icons.fullScreen}</div>
                  <div className="poll-full__link-text">{t('Full screen')}</div>
                </button>
              ) : null}

            </div>
          </div>
        ) : isAdmin && handle.active ?
          <button type="button" className="poll-full__exitFullScreenButton" onClick={handle.exit}>
            {icons.exitFullScreen}
          </button> : null}

        {/* Display Poll Questions */}
        <div className="poll-full__mid-container"
             style={{ top: poll.questions && poll.questions[0].choices ? poll.questions[0].choices.length <= 3 ? "50%" : "40%" : '50%' }}>

          {poll.questions.map((question) => {

            return isPub || isAdmin ? (
              <div className="poll-full__container" key={question.id}>

                {/* display question title */}
                <div className="d-flex justify-content-between">
                  <div className="poll-full__title">{question.title}</div>
                </div>

                {/* display question Details */}
                <div className="d-flex justify-content-between mt-4">
                  <div className="poll-full__type">{t("Anonymous Poll")}</div>
                  <div className="poll-full__user-responded">
                    {`${countPollFullUserResponded(question.id, votes)} ${t("votes")}`}
                  </div>
                </div>

                {/* display Choices */}
                <div className="d-flex flex-column mt-4">
                  {question.choices.map((choice, index) => (
                    <div className="d-flex poll-full__choice" key={choice.id}>

                      {/* display choice's vote percentage */}
                      <div className="poll-full__choice-percentage">
                        {`${countPollFullChoicePercentage(
                          question.choices,
                          index,
                          votes,
                        )}% (${countChoiceVotes(choice.id, votes)})`}
                      </div>

                      {/* display choice title and percentage bar */}
                      <div className="d-flex flex-column w-100">
                        <div className="d-flex poll-full__choice-title">
                          {choice.title}
                        </div>
                        <div id="progress-bar" className="all-rounded">
                          <div
                            id="progress-bar-percentage"
                            className="all-rounded"
                            style={{
                              width: `${countPollFullChoicePercentage(
                                question.choices,
                                index,
                                votes,
                              )}%`,
                              background: '#436F98',
                            }}
                          />
                        </div>
                      </div>

                    </div>
                  ))}
                </div>
              </div>
            ) : null;
          })}
          <div style={{ height: "1%" }}/>
        </div>

        {/* Start / Stop / Reset button */}
        {isAdmin ? (
          <button
            type="button"
            className={
              bemCompose('poll-full', 'toggle-button', {
                active: !isPub && isStopped !== true || !isPub && !votes.length,
                inactive: isPub && isStopped !== true,
                reset: isStopped === true && votes.length,
              }) + ' btn'
            }
            onClick={toggleStartStop}
          >
            {isPub
              ? isStopped === true && votes.length
                ? t('Reset')
                : t('Stop')
              : isStopped === true && votes.length
                ? t('Reset')
                : t('Start')}
          </button>
        ) : null}
      </div>
    </FullScreen>
  );
}
