// @flow
import moment from 'moment';

export function formatEventStart(t, start, startTime) {
  if (!startTime) {
    return t('{{moment, D MMM (dd)}}', { moment: moment(start) });
  }
  const when = moment(start + ' ' + startTime);
  if (when.minutes() === 0) {
    return t('{{moment, D MMM (dd)}} at {{moment, h a}}', { moment: when });
  }
  return t('{{moment, D MMM (dd)}} at {{moment, h:mm a}}', { moment: when });
}

export function formatSent(t, value) {
  return moment(value + 'Z').format(t('HH:mm'));
}
