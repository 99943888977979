// @flow
import { email, required } from 'redux-form-validators';
import { string, image, boolean } from '../../core/data/rules';
import type { Company } from './Company.model';
import type { UserContact } from './UserContact.model';
import type { ImageField, MetaModel } from '../../core/data/rules';

export type User = {|
  id: string,
  email: ?string,
  firstName: ?string,
  lastName: ?string,
  avatar: ?ImageField,
  isOnboarded: boolean,
  isOrganizer: boolean,
  isAdmin: boolean,
  isBanned: boolean,
  position: ?string,
  companyTitle: ?string,
  companyId: ?string,
  company: ?Company,
  interests: ?string,
  lookingFor: ?string,
  publicContacts: [UserContact],
|}


export const userMetaModel: MetaModel = {
  name: 'User',
  rules: {
    email: [email()],
    firstName: [string()],
    lastName: [string()],
    avatar: [image()],
    isOnboarded: [boolean(), required()],
    isOrganizer: [boolean(), required()],
    isAdmin: [boolean(), required()],
    isBanned: [boolean(), required()],
    position: [string()],
    companyTitle: [string()],
    companyId: [string()],
    interests: [string()],
    lookingFor: [string()],
  },
  labels: {
    email: t => t('Email'),
    firstName: t => t('First Name'),
    lastName: t => t('Last Name'),
    avatar: t => t('Avatar'),
    position: t => t('Position'),
    companyTitle: t => t('Company'),
    companyId: t => t('Company'),
    interests: t => t('My interests'),
    lookingFor: t => t('I’m looking for'),
  },
};
