// @flow
import gql from 'graphql-tag';
import { apolloQuery } from '../../core/data/apolloClient';


export function getToken(id: string,room: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!,$room: String!) {
        getToken(id: $id, room:$room) ${shape}
      }
    `,
    { id,room },
  );
}

