// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import {
  apolloQuery,
  apolloSubmitForm,
  apolloSubscribe,
  getSubscriptionAuthorization,
} from '../../core/data/apolloClient';
import { messageMetaModel } from './Message.model';
import type { SubscriptionListener } from '../../core/data/apolloClient';

export function filterMessageInput(values) {
  return _pickBy(values, (_, key) => key in messageMetaModel.rules || key === 'id');
}

export function listMessages(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($chatId: String!) {
        listMessages(chatId: $chatId) ${shape}
      }
    `,
    { filter }
  );
}

export function getMessage(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getMessage(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createMessage(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: MessageCreate!) {
        createMessage(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateMessage(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: MessageUpdate!) {
        updateMessage(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function onChatMessages(chatId: string, shape, listener: SubscriptionListener) {
  return apolloSubscribe(
    gql`
      subscription ($authorization: String!, $chatId: String!) {
        chatMessages(authorization: $authorization, chatId: $chatId) { action preId id model ${shape} }
      }
    `,
    { chatId, authorization: getSubscriptionAuthorization() },
    listener
  );
}
