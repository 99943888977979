// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { required } from 'redux-form-validators';
import { useTranslation, Trans } from 'react-i18next';

import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
} from '../../elements/DefaultForm';
import { string } from '../../core/data/rules';
import { authRegisterMetaModel } from '../model/AuthRegister.model';

export type RegisterPageViewProps = {
  urlPrefix?: string,
  onSubmit: (values: { [string]: any }) => void,
}

export default function RegisterPageView({
  urlPrefix = '',
  onSubmit,
}: RegisterPageViewProps) {
  const { t } = useTranslation();

  function passwordsMatch(value, allValues) {
    return value !== allValues.password ? t("Passwords don't match") : undefined;
  }

  return (
    <div className="login">
      <div className="login__logo">
        <img src="/img/layout/logo.svg" width="160" height="172" alt="Logo" />
        <div className="login__logo-text">Westa</div>
      </div>
      <p className="login__text login__text_register">{t('Register Westa account')}</p>
      <DefaultForm
        form="register"
        metaModel={authRegisterMetaModel}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormText attribute="email" type="email" autoFocus />
        <DefaultFormText attribute="password" type="password" />
        <DefaultFormText
          name="confirmPassword"
          label={t('Confirm Password')}
          type="password"
          validate={[string(), required(), passwordsMatch]}
        />
        <p className="login__text login__text_legal">
          <Trans
            defaults={'By clicking "Sign Up" I agree to <0>Westa Terms</0> and <1>Privacy Policy</1>.'}
            components={[
              <a href="/terms/" target="_blank" />, // eslint-disable-line jsx-a11y/anchor-has-content
              <a href="/privacy/" target="_blank" />, // eslint-disable-line jsx-a11y/anchor-has-content
            ]}
          />
        </p>
        <DefaultFormSubmit label={t('Sign Up')} />
      </DefaultForm>
      <p className="login__text login__text_tail">
        <Trans
          defaults='Already have an account? – <1>sign in</1>'
          components={[
            <br />,
            <Link to={urlPrefix + '/login/westa/'} />,
          ]}
        />
      </p>
    </div>
  );
}
