// @flow
import { reducer as formReducer } from 'redux-form';
import { createReduxStore } from '../data/reduxStore';

import * as routerRedux from '../data/router.redux';
import * as authRedux from '../../auth/auth.redux';
import * as messengerRedux from '../../messenger/messenger.redux';
import * as pollsRedux from '../../polls/polls.redux';
import * as eventsRedux from '../../events/events.redux';
import * as usersRedux from '../../users/users.redux';
import * as elementsRedux from '../../elements/elements.redux';
import * as onboardingRedux from '../../onboarding/onboarding.redux';
import * as networkRedux from '../../speednetwork/network.redux';

export function assembleRedux() {
  return createReduxStore(
    null,
    { // Libraries
      form: formReducer,
    },
    [ // Modules
      routerRedux,
      authRedux,
      elementsRedux,
      eventsRedux,
      messengerRedux,
      onboardingRedux,
      usersRedux,
      pollsRedux,
      networkRedux,
    ],
    [ // Listeners
    ]
  );
}
