// @flow
import { AnyAction } from "redux";
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import type { Session } from '../model/Session.model';
import { getLiveSessions } from '../model/Session.client';

export type SessionState = {
  sessions: null | Session | ErrorCapsule,
};

const initialState: SessionState = {
  sessions: null,
};

const sessionShape = `{
    id
    round
    name
    status
    duration
  }`;


const ACTION_SET_SESSION = 'sessions/sessionX/ACTION_SET_SESSION';

function setSessions(sessions: null | Array<Session> | ErrorCapsule) {
  return {
    type: ACTION_SET_SESSION,
    sessions,
  };
}

export function sessionXReducer(
  state: SessionState = initialState,
  action: AnyAction,
) {
  switch (action.type) {
    case ACTION_SET_SESSION:
      return {
        ...state,
        sessions: action.sessions,
      };
    default:
      return state;
  }
}


export function startTrackingSessions(dispatch): () => void {
  return getLiveSessions(sessionShape, (packet) => {
    dispatch([setSessions(packet.reset)]);
  });
}
