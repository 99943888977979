// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { firstVisitReducer } from './FirstVisitPage';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'onboarding';

export const reducer = safeCombineReducers({
  firstVisit: firstVisitReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
