// @flow
import _flatten from 'lodash/flatten';
import { staticsRoutes } from '../../statics/statics.routes';
import { authRoutes } from '../../auth/auth.routes';
import { eventsRoutes } from '../../events/events.routes';
import { messengerRoutes } from '../../messenger/messenger.routes';
import { usersRoutes } from '../../users/users.routes';
import { pollsRoutes } from '../../polls/polls.routes';
import { onboardingRoutes } from '../../onboarding/onboarding.routes';
import { error404PageCatchingRoute } from '../../statics/Error404Page';
import {networkRoutes} from '../../speednetwork/network.routes';

export const appRoutes = _flatten([
  ...staticsRoutes(),
  ...authRoutes(),
  ...eventsRoutes(),
  ...messengerRoutes(),
  ...onboardingRoutes(),
  ...usersRoutes(),
  ...pollsRoutes(),
  ...networkRoutes(),
  error404PageCatchingRoute(),
]);
