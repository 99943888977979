// @flow
import React from 'react';

export type Props = {}

export default function LoaderView({}: Props) {
  return (
    <div className="loader">
      <svg className="loader__fill" width="131" height="123" viewBox="0 0 131 123" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="loader__path" d="M15.5 22L40 108.5L63.5 22L88.5 108.5L111.5 22" stroke="#1D1D1D" strokeLinecap="round" />
        <clipPath id="clipping">
          <path
            d="M32.6198 101L12 23H27.2698L41.3135 85.0657L57.8094 23H73.525L89.575 85.0657L103.619 23H119L97.8229 101H80.8812L65.3885 43.1686L49.45 101H32.6198Z"
            fill="green"
          />
        </clipPath>
      </svg>
      <div className="loader__underlay" />
    </div>
  );
}
