// @flow
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type AuthHomePageViewProps = {
  urlPrefix?: string,
  onGoogle: () => void,
  onFacebook: () => void,
  onVk: () => void,
}

export default function AuthHomePageView({
  urlPrefix = '',
  onGoogle,
  onFacebook,
  onVk,
}: AuthHomePageViewProps) {
  const { t } = useTranslation();

  return (
    <div className="login">
      <div className="login__logo">
        <img src="/img/layout/logo.svg" width="160" height="172" alt="Logo" />
        <div className="login__logo-text">Westa</div>
      </div>
      <p className="login__text login__text_social">{t('Sign in by using your social profile')}</p>
      <button
        type="button"
        className="btn btn-default btn-block login__social login__social_facebook"
        onClick={e => onFacebook()}
      >
        Facebook
      </button>
      <button
        type="button"
        className="btn btn-default btn-block login__social login__social_google"
        onClick={e => onGoogle()}
      >
        Google
      </button>
      <button
        type="button"
        className="btn btn-default btn-block login__social login__social_vk"
        onClick={e => onVk()}
        hidden // Hide the button due to the Rus-Ukr conflict
      >
        ВКонтакте
      </button>
      <p className="login__text login__text_waccont">{t('Or use your Westa account')}</p>
      <Link
        to={urlPrefix + '/login/westa/'}
        className="btn btn-default btn-block login__social"
      >
        {t('Westa account')}
      </Link>
      <p className="login__text login__text_tail">
        <Trans
          defaults={'Don\'t have an account?<0 />– <1>register</1>'}
          components={[
            <br />,
            <Link to={urlPrefix + '/register/'} />,
          ]}
        />
      </p>
    </div>
  );
}
