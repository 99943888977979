// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { RouteAuth, RouteDef } from 'metaup/routing/routingUtils';

import Router from '../core/routing/Router';
import MainLayoutView from './views/MainLayoutView';
import { unfunc } from '../core/utils';
import { appRoutes } from '../core/assemble/appRoutes';
import LayoutContext, { fillLayoutContext } from './LayoutContext';
import {
  getNewMessageCount,
  getUnreadInEventChat,
  startTrackingUnread,
} from '../messenger/redux/Chat.redux';
import { logout } from '../auth/authState';

import { startTrackingNewPolls,getNewPollCount } from '../polls/redux/Poll.redux';

function bodyListener(setProfileMenuOpened, e) {
  // Close profile menu on click outside
  if (!e.target.closest('.profile-menu')) {
    setProfileMenuOpened(false);
  }
  // Close profile menu on nav link click, but let it work first
  if (e.target.closest('a')) {
    setTimeout(() => setProfileMenuOpened(false));
  }
}

function getWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

type RoutedLayoutProps = {
  route: RouteDef,
  match: any,
  auth: RouteAuth,
}

function RoutedLayout({ route, match, auth }: RoutedLayoutProps) {
  const { t } = useTranslation();

  // Expand params
  const { params } = match;
  const { authState, isUser } = auth;

  // Query redux
  const dispatch = useDispatch();
  const chatsNewCount = useSelector(getNewMessageCount);
  // const newPollCount = useSelector(getNewPollCount);
  const userId = useSelector(({ auth: { state } }) => state.id);

  // Subscribe to message count
  useEffect(() => (userId ? startTrackingUnread(dispatch) : undefined), [userId]);

  // Subscribe to polls count
  // useEffect(() => (userId ? startTrackingNewPolls(dispatch,userId) : undefined), [userId]);

  // Handle ProfileMenu opening
  const [profileMenuOpened, setProfileMenuOpened] = useState(false);
  useEffect(() => {
    if (!profileMenuOpened) {
      return undefined;
    }
    const listener = bodyListener.bind(null, setProfileMenuOpened);
    document.body.addEventListener('click', listener);
    return () => {
      document.body.removeEventListener('click', listener);
    };
  }, [profileMenuOpened]);

  // Handle window size
  const [{ width, height }, setWindowSize] = useState(() => getWindowSize());
  useEffect(() => {
    const resize = () => {
      setWindowSize(getWindowSize());
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  // Track page meta
  const [meta, setMeta] = useState(() => (
    route.meta
      ? unfunc(route.meta, t, auth)
      : { ...route.nav, title: unfunc(route.title, t) } // legacy
  ));
  const {
    title,
    titleLink = null,
    titleImage = null,
    showHamburger = false,
    showTitle = false,
    onBack = null,
    eventMenu = null,
  } = meta;

  // Track event menu
  const hasEventMenu = (eventMenu && isUser);
  const eventId = params.eventId || params.id;

  // Design-time injection
  let View = MainLayoutView;
  let overrideWidth = width;
  if (process.env.REACT_APP_DESIGN_LAYOUT === 'yes') {
    overrideWidth = 320;
    View = require('metaup/designLayout/DesignLayoutView') // eslint-disable-line global-require
      .wrap(MainLayoutView, appRoutes, { designWidth: overrideWidth });
  }

  // Pack view props
  const viewProps = {
    topBar: {
      onProfileMenu: unfunc(showHamburger) ? () => setProfileMenuOpened(!profileMenuOpened) : null,
      title: showTitle ? title : null,
      link: titleLink,
      avatar: titleImage,
      onBack,
      isUser,
      chatsNewCount,
    },
    eventMenu: hasEventMenu ? {
      eventId,
      newMessageCount: chatsNewCount,
      // newPollCount,
      activeBtns: new Set([eventMenu]),
    } : null,
    profileMenu: profileMenuOpened ? {
      profile: authState.user ? authState.user.user : null,
      onClose: () => setProfileMenuOpened(false),
      onLogout: () => dispatch(logout()),
      chatsNewCount,
    } : null,
    width: overrideWidth,
    route,
    auth,
  };

  // Pack context
  const context = fillLayoutContext({
    width: overrideWidth,
    height,
    topBar: viewProps.topBar,
    eventMenu: viewProps.eventMenu,
    meta,
    setMeta: delta => setMeta({ ...meta, ...delta }),
  });

  return (
    <LayoutContext.Provider value={context}>
      <View {...viewProps}>
        {route.render(params, auth)}
      </View>
    </LayoutContext.Provider>
  );
}

export default function MainLayout() {
  return (
    <Router
      handler={(route, match, auth) => (
        <RoutedLayout route={route} match={match} auth={auth} />
      )}
    />
  );
}
