// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { supportMocking } from 'metaup/dev/supportMocking';
import { UserContact } from './model/UserContact.model';
import {
  getUserContact,
  createUserContact,
  updateUserContact,
  filterUserContactInput,
} from './model/UserContact.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import ProfileEditContactWidgetView from './views/ProfileEditContactWidgetView';

type ProfileEditContactState = {
  userContact: null | UserContact | ErrorCapsule,
}

const userContactShape = `{
  id
  userId
  user {
    id
    email
    firstName
    lastName
    avatar {
      id
      cover(width: 100, height: 100) { width height url }
    }
    isOnboarded
    isOrganizer
    isAdmin
    isBanned
    position
    companyTitle
    companyId
    interests
    lookingFor
  }
  kind
  title
  content
}`;

const initialState: ProfileEditContactState = {
  userContact: null,
};

const ACTION_SET_USER_CONTACT = 'users/profileEditContact/ACTION_SET_USER_CONTACT';

function setUserContact(userContact: null | UserContact | ErrorCapsule) {
  return {
    type: ACTION_SET_USER_CONTACT,
    userContact,
  };
}

async function loadUserContact(id: string) {
  try {
    const userContact = await getUserContact(id, userContactShape);

    return setUserContact(userContact);
  } catch (err) {
    return setUserContact(new ErrorCapsule(err, () => [
      setUserContact(initialState.userContact),
      loadUserContact(id),
    ]));
  }
}

export async function saveUserContact(values) {
  const userContact = !values.id
    ? await createUserContact(filterUserContactInput(values), userContactShape)
    : await updateUserContact(filterUserContactInput(values), userContactShape);

  return [
    setUserContact(userContact),
    redirect('/users/profile-edit-contact/' + userContact.id),
  ];
}

export function profileEditContactReducer(
  state: ProfileEditContactState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_USER_CONTACT:
      return {
        ...state,
        userContact: action.userContact,
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
}

function ProfileEditContactWidget({
  id,
}: Props) {
  const dispatch = useDispatch();
  const {
    userContact,
  }: ProfileEditContactState = useSelector(({ users }) => users.profileEditContact);

  // Load UserContact
  useEffect(() => {
    dispatch([
      setUserContact(null),
      loadUserContact(id),
    ]);
    return () => dispatch(setUserContact(null));
  }, [id]);

  // Render
  return (
    <ProfileEditContactWidgetView
      userContact={userContact}
      onSubmit={(values: UserContact) => dispatch(saveUserContact(values))}
    />
  );
}

export default supportMocking(ProfileEditContactWidget, ProfileEditContactWidgetView);
