// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import type { MetaModel } from '../../core/data/rules';
import { datetime, string } from '../../core/data/rules';
import type { PollQuestion } from './PollQuestion.model';
import type { PollVote } from './PollVote.model';

export type PollChoice = {|
  id: string,
  title: string,
  questionId: ?string,
  question: ?PollQuestion,
  votes: [PollVote],
  createdAt: ?string,
|}


export const pollChoiceMetaModel: MetaModel = {
  name: 'PollChoice',
  rules: {
    title: [string(), required()],
    questionId: [string()],
    createdAt: [datetime()],
  },
};
