// @flow
import { homeRoutes } from './HomePage';
import { termsOfServiceRoutes } from './TermsOfService';
import { privacyPolicyRoutes } from './PrivacyPolicy';
import { aboutPageRoutes } from './AboutPage';
// ### GENERATED IMPORTS GO HERE ###

export function staticsRoutes() {
  return [
    homeRoutes(),
    privacyPolicyRoutes(),
    termsOfServiceRoutes(),
    aboutPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ];
}
