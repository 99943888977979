// @flow
import React from "react";
import { useTranslation } from "react-i18next";
import { required } from 'redux-form-validators';
import { icons } from "../../elements/icons";
// eslint-disable-next-line import/no-cycle
import { DefaultFormText2 } from "../../elements/DefaultForm";
import { removeChoice } from '../utils';
import { string } from '../../core/data/rules';
import type { FieldWrapperProps } from '../../core/forms/Field';


export default function PollAddChoiceView(props: FieldWrapperProps) {
  const { t } = useTranslation();
  const { fields, stretched, eventPollMetaModel, questionNo, choices } = props;
  return (
    <div
      className={
        stretched
          ? 'form-group default-form__form mb-1 default-form__stretch'
          : 'form-group default-form__form mb-1 '
      }
    >
      {fields.map((choice, index) => (
        // Display choices
        <div className="d-flex align-self-stretch mb-1" key={choice.title + index.toString()}>
          <DefaultFormText2
            hint={`${t("Option")} ${index + 1}`}
            attribute={`${choice}title`}
            type="text"
            label={null}
            stretched
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          {fields.length > 2 ? <div className="ml-2 mt-2" onClick={async () => {
            if (choices && index < choices.length) {
              const choiceToBeDeleted = choices[index];
              await removeChoice(choiceToBeDeleted);
            }
            fields.remove(index);
          }}>
            {icons.cancel}
          </div> : null}
        </div>
      ))}

      {/* Add Choice Button */}
      <div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="event-poll-add__add-title"
          onClick={() => {
            eventPollMetaModel.rules[
              `${questionNo}choices[${fields.length}]title`
              ] = [string(), required()];

            fields.push({});
          }}
          style={{ paddingTop: 0, paddingBottom: "5px", lineHeight: "inherit" }}
        >
          {`+ ${t("Add Option")}`}
        </div>
      </div>
    </div>
  );
}
