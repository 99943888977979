// @flow
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isPlainObject from "lodash/isPlainObject";
import type { Routes } from "metaup/routing/routingUtils";
import { sanitizeRouteParams } from "metaup/routing/routingUtils";
import ErrorCapsule from "../core/exceptions/ErrorCapsule";
import PollFullPageView from "./views/PollFullPageView";
import { redirect } from "../core/data/router.redux";
import { extractPollFullVotes, publishPoll, redirectPoll, resetPoll, stopPoll } from './utils';

type Props = {
  id?: string,
};

function PollFullPage({ id }: Props) {
  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const { polls }: PollState = useSelector(({ polls }) => polls.pollX);

  const [isAdmin, setIsAdmin] = useState(false);
  const currentUserId = useSelector(({ auth }) => auth.state.id);
  const [vote, setVote] = useState([]);
  const [poll, setPoll] = useState(null);
  const [isBack, setIsBack] = useState(false);
  const [isNext, setIsNext] = useState(false);

  useEffect(() => {

    if (!polls) return;

    // Extract current Poll from the List
    const tmp = polls.filter((p) => {
      return p.id === id;
    });

    // Check Admin
    if (tmp.length) {
      const tmpPoll = tmp[0];
      setPoll(tmpPoll);
      setIsAdmin(
        tmpPoll.event.organizerId === currentUserId || currentUserId === "admin",
      );

      // Filter Event Polls
      const tmp2 = polls.filter((p) => p.eventId === tmp[0].eventId);

      // Set Back and Next Button based on Polls
      tmp2.map((p, index) => {

        if (p.id === id) {
          if ((index - 1 >= 0 && tmp2[index - 1])) {
            setIsBack(true);
          } else {
            setIsBack(false);
          }
          if (index + 1 < tmp2.length && tmp2[index + 1]) {
            setIsNext(true);
          } else {
            setIsNext(false);
          }
          return true;
        }
        return true;
      });
    }


  }, [polls, id]);

  // Extract Votes from poll
  useEffect(() => {
    if (poll && !(poll instanceof ErrorCapsule)) {
      extractPollFullVotes(poll.id, poll, setVote);
    }
  }, [poll]);

  // Render
  return (
    // <div>This is full poll page screen</div>
    <PollFullPageView
      poll={_isPlainObject(poll) ? poll : poll}
      votes={vote}
      redirectPoll={async (type) => {
        await redirectPoll(id, type, dispatch, polls, poll, setPoll);
      }}
      isAdmin={isAdmin}
      publishPoll={publishPoll}
      stopPoll={stopPoll}
      resetPoll={resetPoll}
      redirect={(path) => {
        dispatch(redirect(path));
      }}
      isBack={isBack}
      isNext={isNext}
    />
  );
}

export function pollFullPageRoutes(): Routes {
  return [
    {
      title: (t) => t("Poll Full Screen"),
      path: "/full/:id/",
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: false,
        showTitle: false,
      },
      render: (params) => (
        <PollFullPage
          {...sanitizeRouteParams(params, {
            id: "id",
          })}
        />
      ),
    },
  ];
}
