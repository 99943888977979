// @flow
import React, { useContext, useEffect, useState } from "react";

import { required } from 'redux-form-validators';
import { useTranslation } from 'react-i18next';
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import './SessionAddPageView.scss';
import type { Session } from '../model/Session.model';
import { sessionMetaModel } from '../model/Session.model';
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormText,
} from '../../elements/DefaultForm';

import LayoutContext from '../../layout/LayoutContext';
import { number } from '../../core/data/rules';

export type SessionAddPageViewProps = {
  session: null | Session | ErrorCapsule,
  makeSession: ()=> {},
  startSession: ()=> {},
  endSession: ()=> {},
};

export default function SessionAddPageView(props: SessionAddPageViewProps) {
  const {
    session,
    // eslint-disable-next-line no-unused-vars
    makeSession,
    startSession,
    endSession,
  } = props;

  const { t } = useTranslation();
  const { pageBodyHeight } = useContext(LayoutContext);
  const [sessionTime, setSessionTime] = useState("");

  useEffect(() => {
    if (session && session.duration) {
      setSessionTime(msToTime(session.duration));
    }
  }, [session]);
  // const [seconds, setSeconds] = useState(0);
  // const [rounds, setRounds] = useState(3);

  return (

    <div
      className="sessionAdd__parent"
      style={{ height: pageBodyHeight + "px" }}
    >
      {
        session ? session.status === "created" ? <div>
            <p className="sessionAdd__heading">Session Created</p>
            <h5>Session Details</h5>
            <p>Session Time: {sessionTime}</p>
            <p>Session Rounds: {session.round}</p>
            <p>Session URL: <a

              href={`${window.location.origin}/network/${session.id}/0`} rel="noopener noreferrer"
              target="_blank">{window.location.origin}/network/{session.id}/0</a>
            </p>
            <button type="button" className="btn btn-primary btn-md event-poll__vote-button"
                    onClick={async () => {
                      await startSession();
                    }}>Start session
            </button>
          </div> : <div>
            <p className="sessionAdd__heading">Session Status</p>
            <p className="text-md-left">Successfully started the session </p>
            <p>Session URL: <a
              href={`${window.location.origin}/network/${session.id}/0`} rel="noopener noreferrer"
              target="_blank">{window.location.origin}/network/{session.id}/0</a>
            </p>
            {session.status === "ended" ? <div> The session has ended successfully</div> :
              <div>
                <button type="button"
                        className="btn btn-primary btn-md btn-blue-full"
                        onClick={async () => {
                          await endSession();
                        }}>End session
                </button>
              </div>}


          </div> :
          <div>
            <p className="sessionAdd__heading">Enter Session Details</p>
            <DefaultForm
              className="first-visit__form"
              form="firstVisit"
              metaModel={sessionMetaModel}
              onSubmit={async (values) => {
                const { minutes, seconds, rounds } = values;
                const milliseconds = (minutes * 60000) + (seconds * 1000);
                await makeSession(milliseconds.toString(), rounds);
              }}
              initialValues={{ minutes: "0", seconds: "30", rounds: "1" }}
           >
              <DefaultFormGlobalValidation/>
              <>
                <DefaultFormText
                  attribute="minutes"
                  label="Minutes"
                  rules={[required(), number()]}
                  type="number"
                  min="0"
                  autoFocus
                />
                <DefaultFormText
                  attribute="seconds"
                  label="Seconds "
                  rules={[required(), number()]}
                  type="number"
                  min="0"

                />
                <DefaultFormText
                  attribute="rounds"
                  label="Rounds "
                  rules={[required(), number()]}
                  type="number"
                  min="1"
                />
                <button type="submit" className="btn btn-md btn-blue-full mt-2">
                  {t('Create Session')}
                  {/* <span className="first-visit__next">{icons.back}</span> */}
                </button>
              </>
            </DefaultForm>
          </div>
      }

    </div>);
}

function msToTime(ms) {
  const seconds = (ms / 1000).toFixed(1);
  const minutes = (ms / (1000 * 60)).toFixed(1);
  const hours = (ms / (1000 * 60 * 60)).toFixed(1);
  const days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return seconds + " Seconds";
  else if (minutes < 60) return minutes + " Minutes";
  else if (hours < 24) return hours + " Hrs";
  else return days + " Days";
}