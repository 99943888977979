// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Link } from 'react-router-dom';
import { bemCompose } from 'metaup/render/visualUtils';
import type { User } from '../../users/model/User.model';
import { icons } from '../../elements/icons';

function MenuItem({ label, to, activeOnlyWhenExact, demoActive }: any) {
  return (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      // Disable eslint, because it is library's API
      // eslint-disable-next-line react/no-children-prop
      children={({ match }) => (
        <Link
          to={to}
          className={bemCompose('profile-menu', 'item', {
            active: match || demoActive,
            inactive: !match && !demoActive,
          }) + ' d-flex'}
        >

          <span className="profile-menu__item-label flex-fill">{label}</span>
        </Link>
      )}
    />
  );
}

export type ProfileMenuWidgetViewProps = {
  onClose: () => void,
  onLogout: () => void,
  profile: User,
  activeItems?: Set<string>,
  chatsNewCount?: number,
}

export default function ProfileMenuWidgetView({
  onClose,
  onLogout,
  profile,
  activeItems,
  chatsNewCount,
}: ProfileMenuWidgetViewProps) {

  const { t } = useTranslation();

  const demoActive = id => activeItems && activeItems.has(id);

  return (
    <div className="profile-menu">
      <button type="button" className="profile-menu__close" onClick={onClose}>
        {icons.close}
      </button>

      <div className="profile-menu__menu">
        {!profile && (
          <MenuItem
            to="/login/"
            label={t('Login')}
            demoActive={demoActive('Login')}
          />
        )}
        <MenuItem
          to="/events/"
          label={t('Events')}
          demoActive={demoActive('events')}
        />
        {profile && (
          <>
            <MenuItem
              to={`/people/${profile.id}/`}
              label={t('Profile')}
              demoActive={demoActive('profile')}
            />
            <MenuItem
              to="/messenger/"
              label={chatsNewCount
                ? <>{t('Chats')}<span className="profile-menu__has-msgs">{chatsNewCount}</span></>
                : t('Chats')
              }
              demoActive={demoActive('chats')}
            />
          </>
        )}
        <MenuItem
          to="/about/"
          label={t('About')}
          demoActive={demoActive('about')}
        />

        {profile && (
          <a
            href="#logout"
            onClick={e => {
              e.preventDefault();
              onLogout();
            }}
            className={bemCompose('profile-menu', 'item', {
              inactive: true,
            }) + ' d-flex'}
          >
            <span className="profile-menu__item-label flex-fill">{t('Logout')}</span>
          </a>
        )}

      </div>
    </div>
  );
}
