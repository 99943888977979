// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import { boolean } from '../../core/data/rules';
import type { MetaModel } from '../../core/data/rules';

export type RemindOptions = {|
  byEmail: boolean,
|}


export const remindOptionsMetaModel: MetaModel = {
  name: 'RemindOptions',
  rules: {
    byEmail: [boolean(), required()],
  },
};
