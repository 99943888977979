// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { icons } from '../icons';

type Props = {
  whatHappened?: string,
  message: string,
  onRetry?: () => void,
  onClose?: () => void,
}

export default function LoadFailedView({ whatHappened, message, onRetry, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <div className="layout__alert layout__alert_load-failed">
      {onClose && (
        <button
          type="button"
          className="btn layout__alert-close"
          onClick={e => onClose()}
          title={t('Close message')}
        >
          {icons.close}
        </button>
      )}
      <p className="layout__alert-load-msg">
        {whatHappened ? whatHappened + '.' : null} {message}
      </p>
      {onRetry
        ? (
          <button type="button" className="btn btn-link btn-block" onClick={onRetry}>{t('Retry')}</button>
        )
        : <div style={{ height: '20px' }} />
      }
    </div>
  );
}
