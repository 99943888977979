// @flow
import React from 'react';
import LoaderView from './views/LoaderView';

type LoaderProps = {
  inline?: boolean,
}

export default function Loader({ inline = false }: LoaderProps) {

  return inline
    ? <LoaderView />
    : <div className="layout__loader"><LoaderView /></div>;
}
