// @flow
import { ChildrenArray, Component } from 'react';
import type { FormContext } from './Form';
import { FormContextType } from './Form';

type GlobalValidationProps = {
  render: (FormContext) => ChildrenArray<React$Element<any>>,
}

export default class FormGlobalValidation extends Component<GlobalValidationProps, FormContext> {
  static contextType = FormContextType;

  render() {
    const { render } = this.props;
    return render(this.context);
  }
}
