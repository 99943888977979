// @flow
import React from 'react';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';
import AboutPageView from './views/AboutPageView';

export default function AboutPage() {
  return <AboutPageView />;
}

export function aboutPageRoutes(): Routes {
  return [
    {
      title: t => t('Privacy Policy'),
      path: '/about/',
      nav: {
        showHamburger: true,
      },
      render: (params, { isGuest }: RouteAuth) => (
        <AboutPage />
      ),
    },
  ];
}
