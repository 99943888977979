// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import type { SubscriptionListener } from '../../core/data/apolloClient';
import {
  apolloQuery,
  apolloSubmitForm,
  apolloSubscribe,
  getSubscriptionAuthorization,
} from '../../core/data/apolloClient';
import { pollMetaModel } from './Poll.model';

export function filterPollInput(values) {
  return _pickBy(values, (_, key) => key in pollMetaModel.rules || key === 'id');
}

export function listPolls(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: PollsFilter) {
        listPolls(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function getPoll(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getPoll(id: $id) ${shape}
      }
    `,
    { id },
  );
}

export function createPoll(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PollCreate!) {
        createPoll(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function updatePoll(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PollsFilter!) {
        updatePoll(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function deletePoll(id: string) {
  return apolloSubmitForm(
    gql`
      mutation ($id: String!) {
        deletePoll(id: $id)
      }
    `,
    { id },
  );
}

export function onAllLivePolls(userId: string, shape, listener: SubscriptionListener): () => void {
  return apolloSubscribe(
    gql`
      subscription ($authorization: String!, $userId: String!) {
        allLivePolls (authorization: $authorization, userId: $userId) {
          reset ${shape}
          update ${shape}
        }
      }
    `,
    { userId, authorization: getSubscriptionAuthorization() },
    listener,
  );
}
