// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import {
  apolloMutate,
  apolloQuery,
  apolloSubscribe,
  getSubscriptionAuthorization,
} from '../../core/data/apolloClient';
import { chatMetaModel } from './Chat.model';
import type { SubscriptionListener } from '../../core/data/apolloClient';

export function filterChatInput(values) {
  return _pickBy(values, (_, key) => key in chatMetaModel.rules || key === 'id');
}

export function listChats(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: ChatsFilter) {
        listChats(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getChat(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getChat(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function getChatForEvent(eventId, outputShape) {
  return apolloQuery(
    gql`
      query ($eventId: String!) {
        getChatForEvent(eventId: $eventId) ${outputShape}
      }
    `,
    { eventId }
  );
}

export function getOrCreatePmChat(toUserId, outputShape) {
  return apolloMutate(
    gql`
      mutation ($toUserId: String!) {
        getOrCreatePmChat(toUserId: $toUserId) ${outputShape}
      }
    `,
    { toUserId }
  );
}

export function markChatRead(id) {
  return apolloMutate(
    gql`
      mutation ($id: String!) {
        markChatRead(id: $id)
      }
    `,
    { id }
  );
}

export function onMyUnreadChats(listener: SubscriptionListener): () => void {
  return apolloSubscribe(
    gql`
      subscription ($authorization: String!) {
        myUnreadChats(authorization: $authorization) { 
          reset { chatId eventId unread }
          update { chatId eventId unread }
        }
      }
    `,
    { authorization: getSubscriptionAuthorization() },
    listener
  );
}
