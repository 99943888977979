// @flow
import React from 'react';
import { SubmissionError } from 'redux-form';
import { useTranslation } from 'react-i18next';
import CombinedMetaModel from 'metaup/data/CombinedMetaModel';
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
  DefaultFormTextArea,
  DefaultFormFile,
} from '../../elements/DefaultForm';
import { eventMetaModel } from '../model/Event.model';
import type { Event } from '../model/Event.model';

import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { addressMetaModel } from '../model/Address.model';

export type EventEditPageViewProps = {
  event: null | Event | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
}

const combinedMetaModel = new CombinedMetaModel();
combinedMetaModel.add(eventMetaModel);
combinedMetaModel.add(addressMetaModel, {
  title: 'addressTitle',
  address: 'addressAddress',
  notes: 'addressNotes',
});

function repackValues(values) {
  return {
    ...values,
    address: {
      title: values.addressTitle,
      address: values.addressAddress,
      notes: values.addressNotes,
    },
  };
}

function repackFieldErrors(submitPromise) {
  return submitPromise.catch(e => {
    if (e instanceof SubmissionError) {
      const { errors } = e;
      throw new SubmissionError({
        ...errors,
        addressTitle: errors.address ? errors.address.title : undefined,
        addressAddress: errors.address ? errors.address.address : undefined,
        addressNotes: errors.address ? errors.address.notes : undefined,
      });
    }
    throw e;
  });
}

export default function EventEditPageView({
  event,
  onSubmit,
}: EventEditPageViewProps) {
  const { t } = useTranslation();

  if (!event) return <Loader />;
  if (event instanceof ErrorCapsule) return <LoadFailed error={event} />;
  const isCreating = event.id == null;

  return (
    <div className="event-edit">
      <h1>{isCreating ? 'Add Event' : 'Edit Event'}</h1>
      <DefaultForm
        form="eventEdit"
        metaModel={combinedMetaModel}
        initialValues={event}
        onSubmit={values => repackFieldErrors(onSubmit(repackValues(values)))}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormText attribute="title" autoFocus />
        <DefaultFormText attribute="start" type="date" />
        <DefaultFormText attribute="startTime" type="time" />
        <DefaultFormText attribute="end" type="date" />
        <DefaultFormText attribute="endTime" type="time" />
        <DefaultFormFile attribute="cover" />
        <DefaultFormTextArea attribute="description" />
        <DefaultFormText attribute="addressTitle" label="Location Title" />
        <DefaultFormText attribute="addressAddress" label="Address" />
        <DefaultFormText attribute="addressNotes" />
        <DefaultFormSubmit label={isCreating ? t('Create') : t('Update')} />
      </DefaultForm>
    </div>
  );
}
