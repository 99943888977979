// @flow

// These keys are the subject for implicit translation. Wrapping with t() for i18next-scanner.
const t = x => x;

export default {
  alreadyJoinedTheEvent: t('Already joined the event'),
  connectionProblems: t('There are connection problems. Please try again'),
  serverProblems: t('Uh-oh... Server problems. Please reach our support or try again later'),
  internalProblems: t('An internal error occurred. We are sorry about that. Please contact support if it remains'),
  pageNotFound: t('The contents of the page are not found. We are sorry, please try another pages'),
};
