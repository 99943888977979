// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { profileEditReducer } from './ProfileEditPage';
import { profileReducer } from './ProfilePage';
import { profileEditContactReducer } from './ProfileEditContactWidget';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'users';

export const reducer = safeCombineReducers({
  profileEdit: profileEditReducer,
  profile: profileReducer,
  profileEditContact: profileEditContactReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
