// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import type { SubscriptionListener } from '../../core/data/apolloClient';
import {
  apolloQuery,
  apolloSubmitForm,
  apolloSubscribe,
  getSubscriptionAuthorization,
} from '../../core/data/apolloClient';
import { pollVoteMetaModel } from './PollVote.model';

export function filterPollVoteInput(values) {
  return _pickBy(values, (_, key) => key in pollVoteMetaModel.rules || key === 'id');
}

export function listPollVotes(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: PollVotesFilter) {
        listPollVotes(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function getPollVote(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getPollVote(id: $id) ${shape}
      }
    `,
    { id },
  );
}

export function deletePollVote(id: string) {
  return apolloSubmitForm(
    gql`
      mutation ($id: String!) {
        deletePollVote(id: $id)
      }
    `,
    { id },
  );
}

export function createPollVote(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PollVoteCreate!) {
        createPollVote(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function updatePollVote(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PollVotesFilter!) {
        updatePollVote(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function onPollVotes(pollId: string, shape, listener: SubscriptionListener) {
  return apolloSubscribe(
    gql`
      subscription ($authorization: String!, $pollId: String!) {
        pollVotes(authorization: $authorization, pollId: $pollId) { action pollId id model ${shape} }
      }
    `,
    { pollId, authorization: getSubscriptionAuthorization() },
    listener,
  );
}

