// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import { string, datetime } from '../../core/data/rules';
import type { Chat } from './Chat.model';
import type { User } from '../../users/model/User.model';
import type { MetaModel } from '../../core/data/rules';

export type Message = {|
  id: string,
  chatId: string,
  chat: Chat,
  userId: string,
  user: User,
  text: string,
  sent: string,
  read: ?string,
  delivered: ?string,
|}


export const messageMetaModel: MetaModel = {
  name: 'Message',
  rules: {
    chatId: [string(), required()],
    userId: [string(), required()],
    text: [string(), required()],
    sent: [datetime(), required()],
  },
};
