// @flow
import preval from 'babel-plugin-preval/macro';
import buildIf from 'build-if.macro';

/* eslint-disable global-require */

const devUtils = {};
export default devUtils;

buildIf(preval`module.exports = process.env.NODE_ENV` === 'development', () => {
  // Development

  // Publish metaup utils
  Object.assign(devUtils, require('metaup/dev/dev.frontendUtils'));

  devUtils.init = () => {
    // Enable Design layout and User journeys
    const { showUserJourneys } = require('metaup/designLayout/DesignLayoutView');
    const { appRoutes } = require('../core/assemble/appRoutes');
    const { default: userJourneys } = require('./journeys/journeys');
    showUserJourneys(userJourneys, appRoutes);

    // DI qaContext
    const { default: BackendAuthState } = require('../core/backend/BackendAuthState');
    const { usersSample, usersPlainSample } = require('../users/model/User.samples');
    const filteredUsersSample = {
      ...usersPlainSample,
      avatar: usersSample.avatar,
    };
    devUtils.qaContextDI({
      BackendAuthState,
      usersSample: filteredUsersSample,
      usersPlainSample: filteredUsersSample, // legacy
    });
  };

  // Enable sentry
  require('./sentry/sentry.frontend');

}, () => {
  // Production

  // Enable sentry
  require('./sentry/sentry.frontend');

  // Publish metaup stubs
  Object.assign(devUtils, require('metaup/dev/dev.frontendStubs'));

  devUtils.init = () => {};
});
