// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

type CallbackProps = {
  onClose: () => void,
};

export function OkFooter({ onClose }: CallbackProps) {
  const { t } = useTranslation();

  return (
    <>
      <button
        type="button"
        className="btn btn-gray btn-block"
        onClick={e => {
          onClose();
        }}
      >
        {t('notify#OK')}
      </button>
    </>
  );
}

type ConfirmFooterProps = CallbackProps & {
  handler: () => void,
};

export function ConfirmFooter({ onClose, handler }: ConfirmFooterProps) {
  const { t } = useTranslation();

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={e => {
          onClose();
          handler();
        }}
      >
        {t('OK')}
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={e => onClose()}
      >
        {t('Cancel')}
      </button>
    </>
  );
}

type DialogCallbackProps = {
  onClose: () => void,
}

export type DialogWidgetViewProps = {
  renderBody: DialogCallbackProps => React$Node,
  mode?: ?string,
  renderTitle?: () => React$Node,
  renderFooter?: () => React$Node,
  showClose?: boolean,
  onClose: () => void,
  onClick: DialogCallbackProps => void,
}

export default function DialogWidgetView({
  renderBody,
  mode,
  renderTitle,
  renderFooter,
  showClose,
  onClose,
  onClick,
}: DialogWidgetViewProps) {
  const { t } = useTranslation();

  const callbackProps = { onClose };

  const semiBem = el => el + (mode ? ` ${el}_${mode}` : '');

  return (
    <>
      <div // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
        className="modal fade show d-flex align-items-center"
        tabIndex="-1"
        role="dialog"
        onClick={onClick ? e => onClick(callbackProps) : null}
        onKeyDown={onClick ? e => { if (e.keyCode === 27) onClick(callbackProps); } : null}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {renderTitle && (
              <div className="modal-header">
                <h5 className="modal-title">{renderTitle(callbackProps)}</h5>
                {showClose && (
                  <button
                    type="button"
                    className="close"
                    aria-label={t('Close')}
                    onClick={e => onClose()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
              </div>
            )}
            <div className={semiBem('modal-body')}>
              {renderBody(callbackProps)}
            </div>
            {renderFooter && (
              <div className="modal-footer">
                {renderFooter(callbackProps)}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
}
