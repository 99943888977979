// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { string } from '../../core/data/rules';
import type { MetaModel } from '../../core/data/rules';

export type Address = {|
  title: ?string,
  address: ?string,
  mapPoint: any,
  notes: ?string,
|}


export const addressMetaModel: MetaModel = {
  name: 'Address',
  rules: {
    title: [string()],
    address: [string()],
    mapPoint: [],
    notes: [string()],
  },
};
