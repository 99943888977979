// @flow
import React from "react";
import type { RouteAuth, Routes } from "metaup/routing/routingUtils";

type Props = {
  type?: string,
};


function NetworkEndPage({ type }: Props) {

  // Render
  return (
    <div className="text-center" style={{ paddingTop: "100px" }}>
      {type === "0" ? <h3>The session has ended <br/> Thanks for joining</h3> :
        <h3>No Active Sessions</h3>}
    </div>
  );
}

export function networkEndPageRoutes(): Routes {
  return [
    {
      title: (t) => t('Network'),
      path: '/end',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        showTitle: true,
        onBack: null,
        eventMenu: 'Westa Networking',
      },
      render: (params, { authState }: RouteAuth) => (
        <NetworkEndPage
          type="0"
        />
      ),
    },
    {
      title: (t) => t('Network'),
      path: '/end2',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        showTitle: true,
        onBack: null,
        eventMenu: 'Westa Networking',
      },
      render: (params, { authState }: RouteAuth) => (
        <NetworkEndPage
          type="1"
        />
      ),
    },

  ];
}
