// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type AboutPageViewProps = {
}

export default function Error404PageView({
}: AboutPageViewProps) {
  const { t } = useTranslation();

  return (
    <div className="about">
      <div className="about__info-container">
        <div className="about__info-container_heading">{t('Contacts')}</div>
        <div className="about__info-container_contact">
          <a href="tel:+995 599-60-51-93">+995 599-60-51-93</a>
        </div>
        <div className="about__info-container_link">
          <a href="mailto:contact@westa.app">westa.app@gmail.com</a>
        </div>
      </div>
      <div className="about__info-container">
        <div className="about__info-container_heading2">{t('Terms and conditions')}</div>
        <div className="about__info-container_link">
          <Link to="/terms/">{t('Terms of Use')}</Link>
        </div>
        <div className="about__info-container_link">
          <Link to="/privacy/">{t('Privacy Policy')}</Link>
        </div>
      </div>
      <div className="about__info-container">
        <div className="about__info-container_heading2">{t('Socials')}</div>
        <div className="about__info-container_icons">
          <div className="icon">
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/westaapp">
              <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 1H9C7.67392 1 6.40215 1.52678 5.46447 2.46447C4.52678 3.40215 4 4.67392 4 6V9H1V13H4V21H8V13H11L12 9H8V6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5H12V1Z" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="icon">
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/westa-app/">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 9H2V21H6V9Z" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
