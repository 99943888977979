// @flow
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, createLocalTracks, LocalDataTrack } from "twilio-video";
import Timer from "react-compound-timer";
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import type { Token } from "../model/Token.model";
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import Participant from './Participant';

import './NetworkPageView.scss';
import type { User } from '../../users/model/User.model';
import type { Pair } from '../model/Pair.model';
import TwilioException from '../../core/exceptions/TwilioException';
import { CallState } from '../redux/Call.redux';
import type { Session } from '../model/Session.model';
import type { SessionRound } from '../model/SessionRound.model';
import type { SessionUser } from '../model/SessionUser.model';

import { icons } from '../../elements/icons';


export type NetworkPageViewProps = {
  token: null | Token | ErrorCapsule,
  pair: null | Pair | ErrorCapsule,
  session: null | Session | ErrorCapsule,
  sessionUser: null | SessionUser | ErrorCapsule,
  round: null | SessionRound | ErrorCapsule,
  userId: string,
  waiting: string,
  pairStatus: null | string,
  handleLogout: ()=> {},
  setParticipantId: ()=> {},
  updateStatus: ()=> {},
  user: null | User | ErrorCapsule,
};

const changeTurnArray = (turnArray) => {
  return turnArray.map((turnObj) => {
    return {
      ...turnObj,
      turn: !turnObj.turn,
    };
  });
};


export default function NetworkPageView(props: NetworkPageViewProps) {
  const {
    token,
    handleLogout,
    user,
    setParticipantId,
    userId,
    round,
    pair,
    waiting,
    session,
    pairStatus,
    updateStatus,
    sessionUser,
  } = props;
  const { t } = useTranslation();
  // console.log(session);
  // console.log({ ...round }
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [turnArray, setTurnArray] = useState([]);


  const turnArrayRef = useRef([]);

  turnArrayRef.current = turnArray;

  const [turn, setTurn] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { calls }: CallState = useSelector(({ network }) => network.callX);
  const participantRef = useRef();
  const dataTrack = new LocalDataTrack();


  useEffect(() => {
    turnArray.map((trn) => {
      if (trn.id === userId) {
        setTurn(trn.turn);
      }
      return true;
    });
  }, [turnArray]);

  // useEffect(() => {
  //
  //   // Update delay every 5 seconds if waiting
  //   if ((callWaitTime - 1) % 5000 === 0) {
  //     updatePair({ id: pair.id, roundDelay: callWaitTime }, pairShape);
  //   }
  // }, [callWaitTime]);

  // eslint-disable-next-line no-unused-vars
  const isPairExist = (cl) => {
    let exist = false;
    if (cl) {
      cl.forEach((call) => {
        if (call.pairId === pair.id) {
          exist = true;
        }
      });
      return exist;
    }
    return exist;
  };

  const disableByDefault = (r) =>{
    r.localParticipant.videoTracks.forEach(function (videoTrack) {
      videoTrack.track.disable();
    });
  };
  useEffect(() => {
    const participantConnected = (participant, myroom) => {

      setTurn(false);
      participant.on("trackSubscribed", async (track) => {
        if (track.kind === "data") {
          if (myroom.localParticipant.identity > participant.identity) {

            const tmpTurnArray = [{
              id: myroom.localParticipant.identity,
              turn: true,
            }, {
              id: participant.identity,
              turn: false,
            }];
            dataTrack.send(JSON.stringify({
              turn: tmpTurnArray,
              id: myroom.localParticipant.identity,
            }));
            setTurnArray(tmpTurnArray);
          } else {
            dataTrack.send(JSON.stringify({
              id: myroom.localParticipant.identity,
            }));
          }

          track.on("message", (data) => {
            if (data) {
              const parsedData = JSON.parse(data);
              if (parsedData.turn) {
                setTurnArray(parsedData.turn);

              }
              if (parsedData.id) {
                setParticipantId(parsedData.id);
              }
            }
          });
        }
      });
      setParticipants((prevParticipants) => [participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant),
      );
    };

    if (token && pair && !pairStatus) {
      createLocalTracks({
        audio: true,
        video: { width: 640 },

      }).then(localTracks => {
        return connect(token.token, {
          name: pair.id,
          tracks: [...localTracks, dataTrack],
        });
      }).then(async (r) => {
        // await r.localParticipant.publishTrack(dataTrack);
        setRoom(r);

        // const pairExist = isPairExist(calls);
        // if (!pairExist) {
        //   await createCall(
        //     { sessionId, pairId: pair.id, usersNo: "1", status: "waiting" },
        //     callShape,
        //   );
        // }
        // await r.localParticipant.publishTrack(dataTrack);
        // Restart the LocalVideoTrack to capture video from the back-facing camera.
        // const localVideoTrack = Array.from(r.localParticipant.videoTracks.values())[0].track;
        // localVideoTrack.stop();
        disableByDefault(r);
        r.on("participantConnected", async (prop) => {

              // const call = await listCalls({ pairId: pair.id }, callShape);
            // await updateCall(
            //   { id: call[0].id, sessionId, pairId: pair.id, usersNo: "2", status: "started" },
            //   callShape,
            // );
            return participantConnected(prop, r);
          },
        );
        r.on("participantDisconnected", participantDisconnected);

        r.participants.forEach((prop) => participantConnected(prop, r));

      })
        .catch((err) => {
          // console.log(err, '///////');
          const customErr = new TwilioException(err.message, t);
          return setRoom(new ErrorCapsule(customErr, () => [
            setRoom(null),
            null,
          ]));
        });
    }

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication,
          ) {
            if (trackPublication.track.stop) {
              trackPublication.track.stop();
            }
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [token]);

  // change turn function
  const changeTurn = () => {

    const newTurnArray = changeTurnArray(turnArrayRef.current);
    dataTrack.send(JSON.stringify(newTurnArray));
    setTurnArray(newTurnArray);
  };

  function toggleAudio() {
    const { localParticipant } = room;
    localParticipant.audioTracks.forEach(function (audioTrack) {
      if (!audioTrack.isTrackEnabled) audioTrack.track.enable();
      else audioTrack.track.disable();
    });
  }

  function toggleVideo() {
    const { localParticipant } = room;
    localParticipant.videoTracks.forEach(function (videoTrack) {
      if (!videoTrack.isTrackEnabled) videoTrack.track.enable();
      else videoTrack.track.disable();
    });
  }

  // Admin session Start waiting screen
  if (waiting) {
    return (
      <div>
        <p>You have successfully joined the networking session</p>
        <p>Waiting for the organizer to start the session</p>
      </div>
    );
  }

  // waiting , creating , ongoing , ended
  if (round) {

    if (round.status === "waiting") {
      // console.log('date1',moment.utc(round.start).diff(moment.utc()));
      // console.log('date',Math.round((new Date(round.start).getTime() - new Date().getTime()) / 1000));
      if (sessionUser && sessionUser.status === "ready") {
        return (
          <div>
            <p>Next round will be started in a few seconds </p>
            <div>
              <Timer
                initialTime={moment.utc(round.start).diff(moment.utc(), 'milliseconds')}
                direction="backward"
                startImmediately
              >
                {() => (
                  <React.Fragment>
                    <div>
                      <Timer.Minutes/>:
                      <Timer.Seconds/>
                    </div>
                  </React.Fragment>
                )}
              </Timer>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div>{icons.westaNetwork}</div>
            <div className="d-flex flex-column  align-items-center">
              <h5 className="mr-3">Waiting for start</h5>
              <div className="mt-2">
                {icons.cat}
              </div>

              <div className="network__timer-green mt-2">
                <Timer
                  initialTime={moment.utc(round.start).diff(moment.utc(), 'milliseconds')}
                  direction="backward"
                  startImmediately
                >
                  {() => (
                    <React.Fragment>
                      <div>
                        <Timer.Minutes/>:
                        <Timer.Seconds/>
                      </div>
                    </React.Fragment>
                  )}
                </Timer>
              </div>
              <div className="network__timer-sub mt-2">Press ready button to get matched</div>
              {/* eslint-disable-next-line react/button-has-type */}
              <button className="btn btn-blue mt-2" onClick={async () => {
                await updateStatus("ready");
              }}>Ready
              </button>
            </div>
          </div>
        );
      }
    } else if (round.status === "pairing") {

      if (sessionUser && sessionUser.status !== "ready") {
        return (
          <div>
            <p>You did not press the ready button, so please wait for the next round</p>
          </div>
        );
      }

    } else if (round.status === "pairing") {

      if (sessionUser && sessionUser.status !== "ready"){
        return (
          <div>
            <p>You did not press the ready button, so please wait for the next round</p>
          </div>
        );
      }
      return (
        <div>
          <p>Looking for a pair </p>

          <Timer
            initialTime={moment.utc(round.start).diff(moment.utc(), 'milliseconds')}
            direction="backward"
            startImmediately
          >
            {() => (
              <React.Fragment>
                <div>
                  <Timer.Minutes/>:
                  <Timer.Seconds/>
                </div>
              </React.Fragment>
            )}
          </Timer>
        </div>
      );
    } else if (round.status === "ongoing" && pairStatus) {

      if (pairStatus === "-1") {
        return (
          <div>
            <p>Sorry, no match is available for you. Please wait for the next round</p>
            <div>
              <Timer
                initialTime={moment.utc(round.end).diff(moment.utc(), 'milliseconds')}
                direction="backward"
                startImmediately
              >
                {() => (
                  <React.Fragment>
                    <div>
                      <Timer.Minutes/>:
                      <Timer.Seconds/>
                    </div>
                  </React.Fragment>
                )}
              </Timer>
            </div>
          </div>
        );
      }
      if (pairStatus === "-2") {
        return (
          <div>
            <p>Not enough users have joined, please wait for the next round</p>
            <div>
              <Timer
                initialTime={moment.utc(round.end).diff(moment.utc(), 'milliseconds')}
                direction="backward"
                startImmediately
              >
                {() => (
                  <React.Fragment>
                    <div>
                      <Timer.Minutes/>:
                      <Timer.Seconds/>
                    </div>
                  </React.Fragment>
                )}
              </Timer>
            </div>
          </div>
        );
      }
      if (pairStatus === "-3") {
        return (
          <div>
            <p>The next round starts in</p>
            <div className="d-flex justify-content-center">
              <Timer
                initialTime={moment.utc(round.end).diff(moment.utc(), 'milliseconds')}
                direction="backward"
                startImmediately
              >
                {() => (
                  <React.Fragment>
                    <div>
                      <Timer.Minutes/>:
                      <Timer.Seconds/>
                    </div>
                  </React.Fragment>
                )}
              </Timer>
            </div>
          </div>
        );
      }
    } else if (round.status === "ended") {
      return (
        <div>
          <p>Session has ended</p>
        </div>
      );
    }

  }

  // console.log('round ////////// ', round);
  //
  // console.log('token', token);
  // console.log('pair', pair);
  // console.log('room', room);
  // console.log(token,pair,room);

  if (!token) return <Loader/>;
  if (token instanceof ErrorCapsule) return <PageLoadFailed error={token}/>;

  if (!pair) return <Loader/>;
  if (pair instanceof ErrorCapsule) return <PageLoadFailed error={pair}/>;

  if (!room) return <Loader/>;
  if (room instanceof ErrorCapsule) return <PageLoadFailed error={room.error.message}/>;

  // Video Token Code

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      handleLogout={handleLogout}
      vidWidth="100vh"
      remote
      user={user}
      turn={turn}
      toggleVideo={toggleVideo}
      toggleAudio={toggleAudio}
      session={session}
      turnArray={turnArray}
      changeTurn={changeTurn}
      round={round}
    />
  ));

  return (
    <div className="network__room" ref={participantRef}>

      <div
        className={participants.length ? "network__local-participant" : "network__remote-participants"}>
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            vidWidth="100%"
            local={participants.length}
            user={user}
            turn={turn}
            toggleVideo={toggleVideo}
            toggleAudio={toggleAudio}
            handleLogout={handleLogout}
            session={session}
            turnArray={turnArray}
            changeTurn={changeTurn}
            round={round}
          />
        ) : (
          ""
        )}
      </div>
      <div className="network__remote-participants">
        {(
          remoteParticipants
        )}
      </div>
      {/* </div> */}
    </div>
  );
}