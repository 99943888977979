// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bemCompose } from 'metaup/render/visualUtils';
import { icons } from '../../elements/icons';

function MenuItem({ icon, label, to, isActive, badge,pollBadge }: any) {
  return (
    <Link
      to={to}
      className={bemCompose('event-menu', 'btn', {
        active: isActive,
        inactive: !isActive,
      })}
    >
      <span className="event-menu__btn-icon">{icon}</span>
      <span className="event-menu__btn-label">{label}</span>
      {pollBadge && pollBadge > 0 ? (
        <span className="event-menu__btn-badge-poll">{pollBadge}</span>
      ) : null}
      {badge > 0 ? (
        <span className="event-menu__btn-badge">{badge}</span>
      ) : null}
    </Link>
  );
}

export type EventMenuWidgetViewProps = {
  eventId: ?string,
  newPollCount?: number,
  newMessageCount?: number,
  activeBtns?: Set<string>,
}

export default function EventMenuWidgetView({
  eventId,
  newMessageCount,
  newPollCount,
  activeBtns,
}: EventMenuWidgetViewProps) {
  const { t } = useTranslation();
  const isBtnActive = id => activeBtns && activeBtns.has(id);
  return (
    <div className="event-menu">
      {eventId && (
        <MenuItem
          to={`/events/${eventId}/`}
          icon={icons.home}
          label={t('Event')}
          isActive={isBtnActive('event')}
        />
      )}

      {eventId && (
        <MenuItem
          to={'/events/' + eventId + '/attendees/'}
          icon={icons.attendees}
          label={t('Attendees')}
          isActive={isBtnActive('attendees')}
        />
      )}

      <MenuItem
        to="/messenger/"
        icon={icons.chats}
        label={t('Messenger')}
        isActive={isBtnActive('messenger')}
        badge={newMessageCount || 0}
        pollBadge={newPollCount || 0}
      />
    </div>
  );
}
