// @flow
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './core/i18n/i18n';
import App from './core/App';
import Loader from './elements/Loader';
import UnexpectedCaseException from './core/exceptions/UnexpectedCaseException';
import registerServiceWorker from './registerServiceWorker';

import './core/data/apolloClient';
import { assembleRedux } from './core/assemble/assembleRedux';

// Development tools (or mocks in production)
import devUtils from './dev/dev.frontend';

// Init dev utils when everything is imported
devUtils.init();

// Setup UI
const root = document.getElementById('root');
if (!root) {
  throw new UnexpectedCaseException();
}

const store = assembleRedux();
ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  root
);

// Enable PWA
registerServiceWorker();
