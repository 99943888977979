// @flow
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { required } from "redux-form-validators";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import moment from 'moment';
import LayoutContext from "../../layout/LayoutContext";
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit2,
} from "../../elements/DefaultForm";
import type { MetaModel } from "../../core/data/rules";
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import Loader from "../../elements/Loader";
import PageLoadFailed from "../../elements/PageLoadFailed";
import PollQuestionView from "./PollQuestionView";
import { icons } from "../../elements/icons";
import type { Poll } from "../model/Poll.model";
import type { PollVote } from "../model/PollVote.model";
import {
  checkNoPolls,
  countChoiceVotes,
  countUserResponded,
  getItemStyle,
  getListStyle,
  isPublished,
  isQuestionVoted,
  onDragEnd,
} from '../utils';

export const eventPollMetaModel: MetaModel = {
  rules: {
    choice: [required()],
  },
};

export type PollPageViewProps = {
  polls: null | Array<Poll> | ErrorCapsule,
  currentUserId: string,
  userVotes: null | Array<PollVote> | ErrorCapsule,
  onSubmit: () => void,
  deleteVote: () => void,
  allVoted: () => void,
  updatePoll: () => void,
  deletePoll: () => void,
  redirectCreatePoll: () => void,
  redirectEditPoll: () => void,
  redirectPollFull: () => void,
  isAdmin: boolean,
};

export default function PollPageView(props: PollPageViewProps) {
  const { t } = useTranslation();
  const {
    currentUserId,
    polls,
    userVotes,
    onSubmit,
    deleteVote,
    allVoted,
    updatePoll,
    deletePoll,
    redirectCreatePoll,
    redirectEditPoll,
    isAdmin,
    redirectPollFull,
  } = props;
  const { pageBodyHeight } = useContext(LayoutContext);
  const [defaultExpanded, setDefaultExpanded] = useState(null);

  if (!polls || !userVotes) return <Loader/>;

  if (polls instanceof ErrorCapsule) return <PageLoadFailed error={polls}/>;

  if (userVotes instanceof ErrorCapsule) return <PageLoadFailed error={userVotes}/>;

  return (
    // drag and drop context for react-beautiful-dnd 
    // for more info https://github.com/atlassian/react-beautiful-dnd
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, polls, updatePoll)}
      onDragStart={() => setDefaultExpanded(false)}
      // autoScroll={false}
    >
      <div
        className="event-poll layout__revert-bottom-padding d-flex flex-column pt-3"
        style={{ height: pageBodyHeight + "px" }}
      >

        <div className="layout__nested-body mt-5">

          {/* Droppable Component of react-beautiful-dnd */}
          <Droppable droppableId="My Polls" isDropDisabled={!isAdmin}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {/* Check if there are any Polls */}
                {polls.length ? (
                  checkNoPolls(polls) && !isAdmin ? (
                    <div className="event-poll__noPolls">{icons.noPollsUser}
                      <div>{t("No polls yet")}</div>
                    </div>
                  ) : (
                    // display Polls
                    polls.map((poll, index) => {

                      const isPub = isPublished(poll.publishAt);
                      // check if the poll is published or user is Admin
                      return isPub || isAdmin ? (
                        <Draggable
                          key={poll.id}
                          draggableId={poll.id}
                          index={index}
                          isDragDisabled={!isAdmin}
                          style={{ padding: 0, backgroundColor: "transparent" }}
                        >
                          {(provided2, snapshot2) => (
                            <div
                              ref={provided2.innerRef}
                              {...provided2.draggableProps}
                              {...provided2.dragHandleProps}
                              style={getItemStyle(
                                snapshot2.isDragging,
                                provided2.draggableProps.style,
                              )}
                            >
                              <div
                                className="event-poll__question-container"
                                key={poll.title + " " + index.toString()}
                              >
                                <DefaultForm
                                  form={"event-poll-question" + index}
                                  metaModel={eventPollMetaModel}
                                  onSubmit={(values) => {
                                    onSubmit(values, poll.id);
                                  }}
                                >
                                  <DefaultFormGlobalValidation/>
                                  {/* display title , anonymous poll and number of the Poll */}

                                  <div className="d-flex flex-row">

                                    {
                                      isAdmin ?
                                        <div>
                                          <div
                                            className="event-poll__poll-number"
                                            style={{ paddingTop: "10px" }}
                                          >
                                            {`${index + 1}`}
                                          </div>
                                          <div
                                            className="event-poll__question-multiDots">{icons.multiDots}</div>
                                        </div>
                                        : null
                                    }
                                    <div
                                      style={{ minWidth: 0, width: "100%", paddingLeft: "12px" }}>
                                      {poll.title ? <div className="event-poll__title">
                                        <div className="d-flex justify-content-between">
                                          <div>{poll.title}</div>
                                          <div
                                            className="event-poll__title-time">{moment(poll.publishAt + "Z").format("H:mm")}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <div
                                            className="event-poll__question-type">{t("Anonymous Poll")}</div>
                                          {poll.questions[0] ?
                                            <div className="event-poll__question-user-responded">
                                              {`${countUserResponded(poll.questions[0].choices)} ${t("votes")}`}
                                            </div> : null}
                                        </div>
                                      </div> : null}

                                      {poll.questions.map((question, i) => {

                                        // get Voted Choice
                                        const choiceVoted =
                                          isQuestionVoted(question.id, userVotes, currentUserId) ||
                                          (isAdmin && isPub);

                                        // Add MetaModel Rule For Choice
                                        const questionChoiceName = `choice${i}`;
                                        eventPollMetaModel.rules[
                                          `${questionChoiceName}`
                                          ] = [required()];

                                        return (
                                          // Question View for the Poll Questions
                                          <PollQuestionView
                                            isAdmin={isAdmin}
                                            choiceVoted={choiceVoted}
                                            publishAt={poll.publishAt}
                                            question={question}
                                            choiceName={questionChoiceName}
                                            deleteVote={deleteVote}
                                            questionNo={i + 1}
                                            totalQuestionsNo={poll.questions.length}
                                            key={question.title + i.toString()}
                                            formName={"event-poll-question" + index}
                                            defaultExpanded={defaultExpanded}
                                            countChoiceVotes={(choiceId) => countChoiceVotes(choiceId, userVotes)}
                                          />
                                        );
                                      })}

                                      {/* if Admin then show Open , Edit and Delete Buttons */}
                                      {isAdmin ? (
                                          <div>
                                            <div className="d-flex mt-2 justify-content-between">
                                              {/* Open Polls on Big Screen Button */}
                                              <button
                                                type="button"
                                                className="event-poll__publish-button"
                                                onClick={() => {
                                                  redirectPollFull(poll.id);
                                                }}
                                              >
                                                {icons.blueMoniter}
                                                <div style={{ marginLeft: 10 }}>
                                                  {t("Open")}
                                                </div>
                                              </button>
                                              {/* Edit Polls Button */}
                                              <button
                                                type="button"
                                                className="event-poll__edit-button"
                                                onClick={() => {
                                                  redirectEditPoll(poll.id);
                                                }}
                                              >
                                                {icons.edit}
                                              </button>
                                              {/* Delete Poll Button */}
                                              <button
                                                type="button"
                                                className="event-poll__delete-button"
                                                onClick={() => {
                                                  // Confirm from user to delete Poll
                                                  if (window.confirm(
                                                    t("Are you sure you wish to delete this poll?"),
                                                  )) deletePoll(poll);
                                                }}
                                              >
                                                {icons.delete}
                                              </button>
                                            </div>
                                          </div>
                                        ) :
                                        // if User and Poll is not Voted then show Poll Vote Button
                                        !allVoted(poll) ? (
                                          <div className="d-flex justify-content-between mt-2">
                                            <DefaultFormSubmit2
                                              label={t("Vote")}
                                              styleClass="event-poll__vote-button ml-2"
                                            />
                                          </div>
                                        ) : null}
                                    </div>

                                  </div>

                                </DefaultForm>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ) : null;
                    })
                  )
                ) : (
                  // If there are no Polls show no Polls Screen
                  <div className="event-poll__noPolls">{icons.noPollsUser}
                    <div> {t("No polls yet")}</div>
                    {/* if admin show New Poll creation instruction */}
                    {isAdmin ? <div>{t("Push on new poll button to create new polls")}</div> : null}
                  </div>

                )}
              </div>
            )}
          </Droppable>
          {/* if Admin show new Poll button */}
          {isAdmin ? (
            <button
              type="button"
              className="btn btn-md event-poll__vote-button mt-2"
              onClick={redirectCreatePoll}
            >
              {t("New Poll")}
            </button>
          ) : null}

        </div>
      </div>
    </DragDropContext>
  );
}
