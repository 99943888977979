// @flow
import moment from 'moment';
import { createPoll, deletePoll, filterPollInput, updatePoll } from './model/Poll.client';
import { showGlobalError } from '../elements/GlobalErrors';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import {
  createPollQuestion,
  deletePollQuestion,
  filterQuestionInput,
  updatePollQuestion,
} from './model/PollQuestion.client';
import {
  createPollVote,
  deletePollVote,
  filterPollVoteInput,
  listPollVotes,
} from './model/PollVote.client';
import {
  createPollChoice,
  deletePollChoice,
  filterPollChoiceInput,
  updatePollChoice,
} from './model/PollChoice.client';
import type { PollVote } from './model/PollVote.samples';
import { redirect } from '../core/data/router.redux';

// Shape of the Poll
const pollShape = `{
    id
    title
    event {
    id
    title
    cover {
      id
      cover(width: 40, height: 40) { width height url }
    }
    organizerId
  }
  eventId
   questions {
      id
      title
      questionType
      choices {
        id
        title
        questionId
        votes {
          id
          choiceId
          questionId
          userId
        }
      }
    }
    publishAt
}`;

// Vote Shape
const voteShape = `{
  id
  choiceId
  questionId
  userId
}`;

// Question Shape
const pollQuestionShape = `{
  id
  title
}`;

// Choice Shape
const pollChoiceShape = `{
  id
  title
}`;

// Remove poll Choice Function
export const removeChoice = async (choice) => {
  try {

    // Delete all Questions with choices and Votes
    const deletedVotes = choice.votes.map(async (v) => {
      await deletePollVote(v.id);
    });
    await Promise.all(deletedVotes);
    await deletePollChoice(choice.id);


  } catch (err) {
    return showGlobalError(
      new ErrorCapsule(err, () => [removeChoice(choice)]),
    );
  }
  return true;
};

// Remove Question Function
export const removeQuestion = async (question) => {
  try {

    // Delete all Questions with choices and Votes
    const deletedChoices = question.choices.map(async (choice) => {
      await removeChoice(choice);
    });
    await Promise.all(deletedChoices);
    await deletePollQuestion(question.id);

  } catch (err) {
    return showGlobalError(
      new ErrorCapsule(err, () => [removeQuestion(question)]),
    );
  }
  return true;
};

// Remove Poll Function
export const removePoll = async (poll) => {
  try {

    // Delete all Questions with choices and Votes
    const deletedQuestions = poll.questions.map(async (question) => {
      await removeQuestion(question);
    });
    await Promise.all(deletedQuestions);
    await deletePoll(poll.id);

  } catch (err) {
    return showGlobalError(
      new ErrorCapsule(err, () => [removePoll(poll)]),
    );
  }
  return true;
};

// Save the Poll Vote function
export const saveVote = async (values, currentUserId, pollId) => {
  try {
    const alreadyVotedVotes = await listPollVotes({ userId: currentUserId, pollId }, voteShape);
    //  eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(values)) {
      const userVote = JSON.parse(values[key]);

      const isAlreadyVoted = alreadyVotedVotes.filter((p) => p.questionId === userVote.questionId);
      if (!isAlreadyVoted.length) {
        // eslint-disable-next-line no-await-in-loop
        await createPollVote(
          filterPollVoteInput({
            ...userVote,
            userId: currentUserId,
            pollId,
          }),
          voteShape,
        );
      }
    }
    return true;
  } catch (err) {
    return showGlobalError(
      new ErrorCapsule(err, () => [saveVote(values, currentUserId, pollId)]),
    );
  }

};

// Edit poll function
export const editPoll = async (values) => {
  try {
    await updatePoll(
      filterPollInput({
        ...values,
      }),
      pollShape,
    );

  } catch (err) {
    return showGlobalError(
      new ErrorCapsule(err, () => [editPoll(values)]),
    );
  }
  return true;
};

// Publish Poll the unpublished poll Function
export async function publishPoll(id) {
  try {
    if (id) {
      await updatePoll(
        {
          id,
          publishAt: moment.utc().subtract('7', "days").format("YYYY-MM-DD HH:mm:ss"),
        },
        pollShape,
      );
    }
    return true;
  } catch (err) {
    return publishPoll(new ErrorCapsule(err, () => [publishPoll(id)]));
  }
}

// Stop a started Poll Function
export async function stopPoll(id) {
  try {
    if (id) {
      await updatePoll(
        {
          id,
          publishAt: moment
            .utc()
            .add(1, "month")
            .format("YYYY-MM-DD HH:mm:ss"),
        },
        pollShape,
      );
    }
    return true;

  } catch (err) {
    return publishPoll(new ErrorCapsule(err, () => [publishPoll(id)]));
  }
}

// Reset a stopped poll function
export async function resetPoll(id) {
  try {
    let tmpVotes: PollVote;
    if (id) {
      tmpVotes = await listPollVotes(
        {
          pollId: id,
        },
        voteShape,
      );
      const tmp = tmpVotes.map((v) => {
        return deletePollVote(v.id);
      });
      await Promise.all(tmp);
    }
  } catch (err) {
    return resetPoll(new ErrorCapsule(err, () => [resetPoll(id)]));
  }
  return true;
}

// Extract votes function for poll Full Page
export function extractPollFullVotes(pollId, poll, setVote): () => void {
  const extractedVotes = [];
  poll.questions.map((q) => {
    return q.choices.map((c) => {
      c.votes.map((v) => {
        if (v.pollId === pollId) extractedVotes.push(v);
        return true;
      });
      return true;
    });
  });

  setVote(extractedVotes);
}

// Redirect to next and previous poll
export async function redirectPoll(id, type, dispatch, polls, poll, setPoll) {
  try {
    if (id) {
      const tmpPolls = polls.filter((p) => {
        return p.eventId === poll.eventId;
      });
      let redirected = false;
      for (let i = 0; i < tmpPolls.length; i++) {
        if (tmpPolls[i].id === id) {
          if (i + 1 < tmpPolls.length && type === "next") {
            dispatch(redirect("/polls/full/" + tmpPolls[i + 1].id));
            redirected = true;
          } else if (i - 1 >= 0 && type === "previous") {
            dispatch(redirect("/polls/full/" + tmpPolls[i - 1].id));
            redirected = true;
          }
        }
      }
      if (!redirected) alert(`No ${type} polls`);
    }
    return true;
    // return [setPoll(currentPoll)];
  } catch (err) {
    return setPoll(
      new ErrorCapsule(err, () => [setPoll(null), redirectPoll(id, type)]),
    );
  }
}

// To check weather a poll is published or not 
export function isPublished(publishAt) {
  return moment(publishAt + "Z") < moment();
}

// Extract poll Votes for the Poll Page
export function extractPollVotes(poll, setVote): () => void {

  const extractedVotes = [];
  poll.map((p) => {
    return p.questions.map((q) => {
      return q.choices.map((c) => {
        if (c.votes && c.votes.length) extractedVotes.push(...c.votes);
        return true;
      });
    });
  });
  setVote(extractedVotes);
}

// Check if a Question is Voted or Not
export const isQuestionVoted = (questionId, userVotes, currentUserId) => {
  let i;
  for (i = 0; i < userVotes.length; i++) {
    if (
      userVotes[i].questionId === questionId &&
      userVotes[i].userId === currentUserId
    ) {
      return userVotes[i].choiceId;
    }
  }
  return false;
};

// Count the votes of a particular choice 
export const countChoiceVotes = (choiceId, votes) => {
  let totalChoiceVotes = 0;
  votes.map((vote) => {
    if (vote.choiceId === choiceId) {
      totalChoiceVotes += 1;
    }
    return true;
  });
  return totalChoiceVotes;
};

// Drag end function for Poll page
export const onDragEnd = async (result, polls, changePoll) => {

  const source = result.source.index;
  const destination = result.destination.index;
  if (
    source >= 0 &&
    source < polls.length &&
    destination >= 0 &&
    destination < polls.length
  ) {
    const poll1 = polls[source];
    const poll2 = polls[destination];
    const createdAt = destination < source ? moment
      (poll2.createdAt)
        .add(1, "second")
        .format("YYYY-MM-DD HH:mm:ss") :
      moment
      (poll2.createdAt)
        .subtract(1, "second")
        .format("YYYY-MM-DD HH:mm:ss");

    await changePoll({
      id: poll1.id,
      createdAt,
    });
  }
};

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${16}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : null,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : null,
});

// Check no polls function
export const checkNoPolls = (polls) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const poll of polls) {
    if (isPublished(poll.publishAt) && poll.questions.length) {
      return false;
    }

  }
  return true;
};


// count the total number of user's responded
export const countUserResponded = (choices) => {
  let totalUserResponded = 0;
  choices.map((choice) => {
    totalUserResponded += choice.votes.length;
    return true;
  });
  return totalUserResponded;
};

// Count percentage of votes on a particular choice
export const countChoicePercentage = (choices, index) => {
  const totalUserResponded = countUserResponded(choices);
  if (totalUserResponded !== 0) {
    return Math.floor((choices[index].votes.length / totalUserResponded) * 100);
  }
  return 0;
};

// Count user responded for full screen 
export const countPollFullUserResponded = (questionId, votes) => {
  let totalUserResponded = 0;
  votes.map((vote) => {
    if (vote.questionId === questionId) {
      totalUserResponded += 1;
    }
    return true;
  });
  return totalUserResponded;
};

// Count choice percentage for full screen
export const countPollFullChoicePercentage = (choices, index, votes) => {
  const totalUserResponded = countPollFullUserResponded(choices[index].questionId, votes);
  if (totalUserResponded !== 0) {
    return Math.floor(
      (countChoiceVotes(choices[index].id, votes) / totalUserResponded) * 100,
    );
  }
  return 0;
};

export const getErrorFieldNames = (obj, name = "") => {
  const errorArr = [];
  if (!obj) return errorArr;
  errorArr.push(
    Object.keys(obj)
      .map((key) => {
        const next = obj[key];
        if (next) {
          if (typeof next === "string") {
            return name + key;
          }
          // Keep looking
          if (next.map) {
            errorArr.push(
              next
                .map((item, index) =>
                  getErrorFieldNames(item, `${name}${key}[${index}]`),
                )
                .filter((o) => o),
            );
          }
        }
        return null;
      })
      .filter((o) => o),
  );
  return flatten(errorArr);
};

export const flatten = (arr) => {
  return arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    [],
  );
};

export const focusOnFirstError = (errors) => {
  const errorFields = getErrorFieldNames(errors);
  // Using breakable for loop
  for (let i = 0; i < errorFields.length; i++) {
    const fieldName = `${errorFields[i]}`;
    // Checking if the marker exists in DOM
    const elements = document.querySelectorAll(`[name="${fieldName}"]`);
    if (elements.length) {
      elements[0].focus();
      break;
    }
  }
};

// Create new Poll Function for poll Add Screen
export async function createNewPoll(values, event) {
  try {
    // create Poll
    const createdPoll = await createPoll(
      filterPollInput({
        publishAt: values.publishAt,
        createdAt: values.createdAt,
        eventId: event.id,
        title: values.pollTitle ? values.pollTitle : null,
      }),
      pollShape,
    );

    // Create Poll Questions
    const questions = values.questions.map(async (q, index) => {
      const question = await createPollQuestion(
        filterQuestionInput({
          ...q,
          pollId: createdPoll.id,
          createdAt: moment.utc().add(index, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
        }),
        pollQuestionShape,
      );

      // Create Poll Question Choices
      const choices = q.choices.map((choice, i) => {
        return createPollChoice(
          filterPollChoiceInput({
            title: choice.title,
            questionId: question.id,
            createdAt: moment.utc().add(i, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
          }),
          pollChoiceShape,
        );
      });
      await Promise.all(choices);
    });
    await Promise.all(questions);

    // Redirect to Poll after creation
    return [redirect('/polls/' + event.id + '/')];

  } catch (err) {
    return showGlobalError(new ErrorCapsule(err, () => [createNewPoll(values)]));

  }
}

// Edit poll function for poll edit screen
export async function editWholePoll(values, event, pollId) {
  try {

    const publishAt = moment.utc(new Date(values.publishAt)).format('YYYY-MM-DD HH:mm:ss');
    await updatePoll(
      filterPollInput({
        ...values,
        id: pollId,
        publishAt,
        title: values.pollTitle,
      }),
      pollShape,
    );
    const questions = values.questions.map(async (q, index) => {
      const question = q.id ? await updatePollQuestion(
        filterQuestionInput({ ...q }),
        pollQuestionShape,
      ) : await createPollQuestion(
        filterQuestionInput({
          ...q,
          pollId,
          createdAt: moment.utc().add(index, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
        }),
        pollQuestionShape,
      );

      const choices = q.choices.map(async (choice, i) => {
        // eslint-disable-next-line no-unused-vars
        const changedChoice = choice.id ? await updatePollChoice(
          filterPollChoiceInput({ ...choice }),
          pollChoiceShape,
        ) : await createPollChoice(
          filterPollChoiceInput({
            title: choice.title,
            questionId: question.id,
            createdAt: moment.utc().add(i, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
          }),
          pollChoiceShape,
        );
      });
      await Promise.all(choices);
    });
    await Promise.all(questions);
    return [redirect('/polls/' + event.id + '/')];

  } catch (err) {
    return showGlobalError(new ErrorCapsule(err, () => [editWholePoll(values)]));
  }
}