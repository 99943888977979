// @flow
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bemCompose, renderImageMeta } from 'metaup/render/visualUtils';
import type { ImageMeta } from '../../core/data/rules';
import { icons } from '../../elements/icons';

export type TopBarWidgetViewProps = {
  onBack?: ?(history: any) => void,
  backTitle?: string,
  onProfileMenu?: ?() => void,
  title?: string,
  onSearch?: ?() => void,
  onNotifications?: ?() => void,
  hasNotifications?: boolean,
  onChats?: ?() => void,
  chatsNewCount?: number,
  isUser?: ?boolean,
  avatar?: ?ImageMeta,
  link?: string
};

function renderTitleContents(t, title, avatar, onBack) {
  return (
    <>
      {avatar && (
        <span className="top-bar__profile-ava">
          {renderImageMeta(avatar, t('Avatar'))}
        </span>
      )}
      <span className={bemCompose('top-bar', 'profile-name', {
        'no-ava': !avatar,
        'no-back': !onBack,
      })}
      >{title}
      </span>
    </>
  );
}

export default function TopBarWidgetView({
  onBack,
  backTitle,
  onProfileMenu,
  title,
  onSearch,
  onNotifications,
  hasNotifications,
  onChats,
  chatsNewCount,
  isUser,
  avatar,
  link,
}: TopBarWidgetViewProps) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="top-bar">
      <div
        className={
          bemCompose('top-bar', 'inner', {
            collapse: !title,
            'has-back': !!onBack,
            'has-hb': !!onProfileMenu,
          })
          + ' d-flex align-items-center justify-content-between'
        }
      >
        {onBack && (
          <button
            type="button"
            className={bemCompose('top-bar', 'btn', { back: true })}
            onClick={e => onBack(history)}
          >
            {icons.back}
          </button>
        )}
        {title && (
          link
            ? (
              <Link to={link} className="top-bar__profile">
                {renderTitleContents(t, title, avatar, onBack)}
              </Link>
            )
            : (
              <div className="top-bar__profile">
                {renderTitleContents(t, title, avatar, onBack)}
              </div>
            )
        )}
        {onProfileMenu && (
          <button
            type="button"
            className={bemCompose('top-bar', 'btn', { menu: true })}
            onClick={onProfileMenu}
          >
            {icons.menu}
            {chatsNewCount > 0 && <span className={bemCompose('top-bar', 'has-msgs')} />}
          </button>
        )}
      </div>
    </div>
  );
}
