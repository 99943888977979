// @flow
import React, { useEffect, useRef, useState } from "react";
import Timer from 'react-compound-timer';
import * as moment from 'moment';
import { Line } from 'rc-progress';
import { useSelector } from 'react-redux';
import type { User } from '../../users/model/User.model';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { icons } from '../../elements/icons';
import type { Session } from '../model/Session.model';
import type { SessionRound } from '../model/SessionRound.model';
import type { PollVote } from '../../polls/model/PollVote.model';
import { getAuthUser } from '../../auth/authState';

export type pariticipantProps = {
  participant: any,
  local: boolean,
  turn: boolean,
  remote: boolean,
  turnArray: Array<PollVote>,
  handleLogout: ()=> {},
  toggleVideo: ()=> {},
  toggleAudio: ()=> {},
  changeTurn: ()=> {},
  user: null | User | ErrorCapsule,
  session: null | Session | ErrorCapsule,
  round: null | SessionRound | ErrorCapsule,
};

const Participant = ({
  participant,
  handleLogout,
  local,
  user,
  turn,
  toggleVideo,
  remote,
  toggleAudio,
  turnArray,
  changeTurn,
  session,
  round,
}: pariticipantProps) => {

  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [camera, setCamera] = useState(false);
  const [sound, setSound] = useState(true);
  const currentUser = useSelector(getAuthUser);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => {
        return (track !== null);
      });

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((vidTracks) => [...vidTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audTracks) => [...audTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((vidTracks) => vidTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audTracks) => audTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
    return () => {
    };
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
    return () => {
    };
  }, [audioTracks]);

  return (
    <div className="network__participant">
      {/* <h3>{participant.identity}</h3> */}

      {
        !local && round && !remote ? <div className="network__timer timer-media-class">
          <Timer
            /* eslint-disable-next-line radix */
            initialTime={moment.utc(round.end).diff(moment.utc(), 'milliseconds')}
            // initialTime={240000}
            direction="backward"
            startImmediately
          >
            {() => (
              <React.Fragment>
                <div>
                  {/* eslint-disable-next-line radix */}
                  Waiting for participant for <Timer.Minutes/>:
                  <Timer.Seconds/>
                </div>
              </React.Fragment>
            )}
          </Timer>

        </div> : null
      }

      {user && !local ? <div className="network__video_overlays network__user-profile media-class">
        <div className="d-flex flex-row justify-content-center">
          {/* <div */}
          {/*  className="network__user-profile-photo">{user.avatar ? */}
          {/*  <img src={user.avatar.cover.url} width={72} height={72} alt="participantImage" */}
          {/*       style={{ borderRadius: "8px" }}/> : null} */}
          {/* </div> */}
          <div className="ml-2 text-center ">
            <div className="network__user-info justify-content-center "
                 style={{ fontWeight: "bold" }}>{user.firstName} {user.lastName}</div>
            <div
              className="d-flex justify-content-center">
              <div>{user.position}</div>
              <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div className="font-weight-bold">{user.companyTitle}</div>
            </div>
            <div className="d-flex mt-2 justify-content-center">
              {
                user.interests.split(",").map((str, i) => {
                  return (
                    <div className="network__interests-card"
                         style={currentUser.interests.includes(str) ? { backgroundColor: "#06A77D" } : null}>
                      {str}
                    </div>
                  );
                })
              }
            </div>
          </div>

        </div>

      </div> : null}

      {!local ? (
        <div className="network__hints p-3">
          <div className="network__timer-title mb-2">Networking Tips</div>

          {
            session && round ?
              <div>
                {/* <div className="network__timer timer-media-class"> */}
                <Timer
                  /* eslint-disable-next-line radix */
                  initialTime={moment.utc(round.end).diff(moment.utc(), 'milliseconds')}
                  // initialTime={240000}
                  direction="backward"
                  startImmediately
                  checkpoints={[
                    {
                      // eslint-disable-next-line radix
                      time: parseInt(session.duration / 2),
                      // time: 120000,
                      callback: changeTurn,
                    },
                  ]}
                >
                  {() => (
                    <React.Fragment>
                      <div className="d-flex justify-content-between">
                        <div className="network__timer-mid-title">{!turn ? "Tell about yourself": "Listen to your partner"}</div>
                        <div className="network__timer-mid-title ml-2" style={{width:"25px"}}>

                          <Timer.Minutes/>:
                          <Timer.Seconds/>
                        </div>
                      </div>
                      <div>
                        <Line
                          percent={Math.floor(((1 - (moment.utc().diff(moment.utc(round.start), 'milliseconds') / moment.utc(round.end).diff(moment.utc(round.start), 'milliseconds'))) * 100)).toString()}
                          strokeWidth="4" strokeColor="#06A77D"/>
                      </div>
                    </React.Fragment>
                  )}
                </Timer>

              </div> : null
          }
          <div className="mt-2">
            {!turn ? (
              <div>

                <div className="network__timer-info">
                  <div>Company</div>
                  <div>Job title or role</div>
                  <div>Job responsibilities</div>
                  <div>Why you are<br/>attending the event</div>
                  <div>Your hobbies</div>
                </div>
              </div>) :null}
          </div>
        </div>) : null}

      {/* eslint-disable-next-line no-self-compare */}
      {!local ? (
        <div className="network__controls">
          <button
            type="button"
            style={{ backgroundColor: sound ? "#FFFFFF" : "#EB5757" }}
            onClick={() => {
              toggleAudio();
              setSound(!sound);
            }}
            className="network__controls_btn"
          >
            {sound ? icons.micOn : icons.micOff}
          </button>
          <button
            type="button"
            style={{ backgroundColor: camera ? "#FFFFFF" : "#EB5757" }}
            onClick={() => {
              toggleVideo();
              setCamera(!camera);
            }}
            className="network__controls_btn"
          >
            {camera ? icons.videoOn : icons.videoOff}
          </button>
          {/* eslint-disable-next-line react/button-has-type */}
          <button type="button" onClick={handleLogout} className="network__controls_btn"
                  style={{ backgroundColor: "#FC2323" }}
          >
            {
              icons.endCall
            }
          </button>
        </div>
      ) : null}

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        autoPlay
        style={!local ? { height: "100vh" } : {}}
        id="videoElement"
      />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} autoPlay/>
    </div>
  );
};


export default Participant;