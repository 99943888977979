// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { attendeesPageRoutes } from './AttendeesPage';
import { eventPageRoutes } from './EventPage';
import { eventsListPageRoutes } from './EventsListPage';
import { eventEditPageRoutes } from './EventEditPage';

// ### GENERATED IMPORTS GO HERE ###

export function eventsRoutes() {
  return prefixRoutes('/events/', [
    attendeesPageRoutes(),
    eventPageRoutes(),
    eventsListPageRoutes(),
    eventEditPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
