// @flow
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { Props as ReduxFormFieldProps } from 'redux-form/es/FieldProps.types.js.flow';
import {
  Field as ReduxFormField,
  FieldArray as ReduxFormFieldArray,
} from 'redux-form';
import _has from 'lodash/has';
import _startCase from 'lodash/startCase';
import type { FormContext } from './Form';
import { FormContextType } from './Form';
import UnexpectedCaseException from '../exceptions/UnexpectedCaseException';
import { unfunc } from '../utils';

export type FieldProps = {
  attribute?: string, // Model attribute
  name?: string, // Default to attribute
  autoFocus?: boolean,

  // Meta-model overrides
  rules?: RulesList,
  label?: string,
  hint?: string,
};

export type FieldWrapperProps = FieldProps & {
  renderControl: (Object) => React$Element<any>,
};

export function parseFormFieldProps(
  t,
  props: FieldProps,
  context: FormContext
) {
  const { attribute } = props;
  const { metaModel } = context;
  let { rules, label, hint } = props;

  // Dive into nested, if required
  if (attribute) {
    const [root, rest] = attribute.split('.', 2);
    if (rest) {
      const nestedMetaModel = metaModel.nested[root];
      if (!nestedMetaModel) {
        throw new UnexpectedCaseException();
      }
      return parseFormFieldProps(
        t,
        { attribute: rest, rules, label, hint },
        { metaModel: nestedMetaModel }
      );
    }
  }

  // Extract defaults from meta model
  if (rules === undefined) {
    // Rules are required in subsequent implementation
    rules = metaModel && attribute ? metaModel.rules[attribute] : [];
  }
  if (metaModel && attribute) {
    if (label === undefined) {
      if (metaModel.labels && _has(metaModel.labels, attribute)) {
        label = unfunc(metaModel.labels[attribute], t);
      } else {
        // Attribute is a good candidate for a name itself
        label = _startCase(attribute);
      }
    }
    if (hint === undefined && metaModel.hints) {
      hint = unfunc(metaModel.hints[attribute], t);
    }
  }

  return { rules, label, hint };
}

export function getBootstrapValidationClassName(touched, error, warning) {
  if (!touched) {
    return null;
  }
  if (error) {
    return 'is-invalid';
  }
  // TODO: if (warning) {}
  return 'is-valid';
}

export default function Field(props: ReduxFormFieldProps & FieldProps) {
  const { attribute, name, validate, warn } = props;
  const context = useContext(FormContextType);
  const { t } = useTranslation();
  const { rules, label, hint } = parseFormFieldProps(t, props, context);
  return (
    // Flow generates nonsense here
    // that's because of redux-form typings, incompatible with its effective implementation
    // $FlowDisable
    <ReduxFormField
      {...props}
      name={name || attribute}
      validate={validate || extractValidate(rules)}
      warn={warn || extractWarn(rules)}
      label={label}
      hint={hint}
    />
  );
}

export function FieldArray(props: ReduxFormFieldProps & FieldProps) {
  const { attribute, name, validate, warn } = props;
  const context = useContext(FormContextType);
  const { t } = useTranslation();
  const { rules, label, hint } = parseFormFieldProps(t, props, context);
  return (
    // Flow generates nonsense here
    // that's because of redux-form typings, incompatible with its effective implementation
    // $FlowDisable
    <ReduxFormFieldArray
      {...props}
      name={name || attribute}
      validate={validate || extractValidate(rules)}
      warn={warn || extractWarn(rules)}
      label={label}
      hint={hint}
    />
  );
}

function extractValidate(fieldRules: FieldRules): RulesList {
  if (Array.isArray(fieldRules)) {
    return fieldRules;
  }
  if (!fieldRules) {
    throw new UnexpectedCaseException();
  }

  let result = [];

  if (fieldRules.must) {
    result = fieldRules.must;
  }
  if (fieldRules.clientOnly) {
    result = [...result, ...fieldRules.clientOnly];
  }

  return result;
}

function extractWarn(fieldRules: FieldRules): ?RulesList {
  return Array.isArray(fieldRules) ? undefined : fieldRules.warn;
}
