// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import type { MetaModel } from '../../core/data/rules';
import { number, string } from '../../core/data/rules';
import type { User } from '../../users/model/User.model';
import type { PollQuestion } from './PollQuestion.model';
import type { PollChoice } from './PollChoice.model';
import type { Poll } from './Poll.model';

export type PollVote = {|
  id: string,
  text: ?string,
  number: ?number,
  userId: ?string,
  user: ?User,
  questionId: ?string,
  question: ?PollQuestion,
  choiceId: ?string,
  Choice: ?PollChoice,
  pollId: ?string,
  poll: ?Poll,
|}


export const pollVoteMetaModel: MetaModel = {
  name: 'PollVote',
  rules: {
    text: [string()],
    number: [number()],
    userId: [string()],
    questionId: [string()],
    choiceId: [string()],
    pollId: [string()],
  },
};
