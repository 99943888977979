// @flow
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';


export function getSessionUser(id: string, shape: string) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getSessionUser(id: $id) ${shape}
      }
    `,
    { id },
  );
}

export function listSessionUsers(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: SessionUsersFilter) {
        listSessionUsers(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function updateSessionUser(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: SessionUsersFilter!) {
        updateSessionUser(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function createSessionUser(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: SessionUserCreate!) {
        createSessionUser(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}


