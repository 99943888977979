// @flow
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ru';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { localizeValidatorMessages } from '../data/rules';

let isInitComplete = false;
const initPromise = i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,

    // Gettext-like behavior
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,

    returnNull: false,
    whitelist: ['ru', 'en', 'uk'],
    load: 'currentOnly',
    // lng: process.env.REACT_APP_LOCALE || 'ru', // Remove after MVP stage

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format(value, format, lng) {
        if (moment.isMoment(value)) return value.format(format);
        return value;
      },
    },
  })
  .then(t => {
    isInitComplete = true;
    // Set default language for the libraries
    moment.locale(i18n.language);
    localizeValidatorMessages(t);
  });

export default i18n;

export function suspendWhileLoadingI18n() {
  if (!isInitComplete) {
    throw initPromise;
  }
}
