// @flow
import React, { useContext } from 'react';
import { bemCompose } from 'metaup/render/visualUtils';
import TopBarWidgetView from './TopBarWidgetView';
import EventMenuWidgetView from './EventMenuWidgetView';
import type { TopBarWidgetViewProps } from './TopBarWidgetView';
import type { EventMenuWidgetViewProps } from './EventMenuWidgetView';
import type { ProfileMenuWidgetViewProps } from './ProfileMenuWidgetView';
import ProfileMenuWidgetView from './ProfileMenuWidgetView';
import LayoutContext from '../LayoutContext';

export type LayoutProps = {
  topBar?: ?TopBarWidgetViewProps,
  eventMenu?: ?EventMenuWidgetViewProps,
  profileMenu?: ?ProfileMenuWidgetViewProps,
  children: React$Node,
}

export default function MainLayoutView({
  topBar,
  eventMenu,
  profileMenu,
  children,
}: LayoutProps) {

  const { width, height, screen } = useContext(LayoutContext);

  return (
    <div
      className={bemCompose('layout', {
        'with-top-bar': topBar,
        'with-event-menu': eventMenu,
        'with-profile-menu': profileMenu,
        'more-than-phone': width >= 400,
      }) + ' screen_' + screen}
      style={{
        // Override 100vh, because mobile browsers fake vh https://stackoverflow.com/a/37113430/698843
        minHeight: height,
      }}
    >
      {profileMenu && (
        <div className="layout__profile-menu">
          <div className="layout__profile-menu-inner">
            <ProfileMenuWidgetView {...profileMenu} />
          </div>
        </div>
      )}
      <div className={bemCompose('layout', 'main', { stretched: (width > 320 && width <= 400), nonstretched: (width <= 320 || width > 400) })}>
        {topBar && (
          <header className={bemCompose('layout', 'top-bar', { stretched: (width > 320 && width <= 400), nonstretched: (width <= 320 || width > 400) })}>
            <TopBarWidgetView {...topBar} />
          </header>
        )}
        <main className={bemCompose('layout', 'content', { 'with-event-menu': eventMenu })} role="main">
          {children}
        </main>
        {eventMenu && (
          <div className="layout__event-menu">
            <EventMenuWidgetView {...eventMenu} />
          </div>
        )}
      </div>
    </div>
  );
}
