// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import type { MetaModel } from '../../core/data/rules';
import { datetime, string } from '../../core/data/rules';
import type { Event } from '../../events/model/Event.model';
import type { PollQuestion } from './PollQuestion.model';

export type Poll = {|
  id: string,
  title: ?string,
  publishAt: ?string,
  createdAt: ?string,
  eventId: ?string,
  event: ?Event,
  questions: [PollQuestion]
|}


export const pollMetaModel: MetaModel = {
  name: 'Poll',
  rules: {
    title: [string()],
    publishAt: [datetime()],
    createdAt: [datetime()],
    eventId: [string()],
  },
};
