// @flow
import React from 'react';
import type { ChildrenArray } from 'react';
import { reduxForm } from 'redux-form';

import type { MetaModel, Model } from '../data/rules';

export type FormProps = {
  form: string, // redux-form id
  initialValues?: Model,
  onSubmit: (values: { [string]: any }, dispatch: Function, props: FormProps) => ?Promise<void>,
  className?: string,
  style?: Object,
  children: ChildrenArray<React$Element<any>>,
  metaModel?: MetaModel,
}

export type FormContext = {
  metaModel: ?MetaModel,
  error: ?string,
  warning: ?string,
}

export const FormContextType = React.createContext<?FormContext>(null);

type FormViewProps = {
  handleSubmit: Function,
  className: string,
  style: Object,
  children: ChildrenArray<React$Element<any>>,
  metaModel?: MetaModel,
  error: ?string,
  warning: ?string,
};

function Form(props: FormViewProps) {
  const {
    handleSubmit, className, style, children, metaModel = null, error, warning,
  } = props;
  const context: FormContext = { metaModel, error, warning };
  return (
    <FormContextType.Provider value={context}>
      <form
        onSubmit={handleSubmit}
        className={className}
        style={style}
        noValidate
      >
        {children}
      </form>
    </FormContextType.Provider>
  );
}
export default reduxForm()(Form);
