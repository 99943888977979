// @flow
import React from "react";
import { useTranslation } from "react-i18next";
import { required } from 'redux-form-validators';
import { icons } from "../../elements/icons";
// eslint-disable-next-line import/no-cycle
import { DefaultFormArrayField, DefaultFormText2 } from "../../elements/DefaultForm";
import { removeQuestion } from '../utils';
import { string } from '../../core/data/rules';
import type { FieldWrapperProps } from '../../core/forms/Field';


export default function PollAddQuestionView(props: FieldWrapperProps) {
  const { t } = useTranslation();
  const { fields, eventPollMetaModel, isMultiQuestion, poll } = props;

  // Type of Questions
  // const questionTypes = [
  //   {
  //     text: t('Single Choice'),
  //     value: 'Single Choice Question',
  //     selected: true,
  //   },
  //   {
  //     text: t('Rating'),
  //     value: 'Rating Question',
  //     selected: false,
  //   },
  //   {
  //     text: t('Text'),
  //     value: 'Text Question',
  //     selected: false,
  //   },
  // ];

  // Add new Meta rules when new question is added
  const addMetaRules = () => {
    eventPollMetaModel.rules[
    'questions[' + fields.length + ']title'
      ] = [required()];
    eventPollMetaModel.rules[
    'questions[' + fields.length + ']questionType'
      ] = [];
    const questionNo = `questions[${fields.length}]`;
    eventPollMetaModel.rules[`${questionNo}choices[0]title`] = [
      string(),
      required(),
    ];
    eventPollMetaModel.rules[`${questionNo}choices[1]title`] = [
      string(),
      required(),
    ];
    fields.push({
      choices: [{}, {}],
      questionType: 'Single Choice Question',
    });
  };

  return (
    <div>
      {fields.map((question, index) => (
        <div className="form-group mt-3 mb-0" key={index.toString()}>
          <div
            className="d-flex justify-content-between align-items-center"
            key={index.toString()}
          >
            {/* display Question Number */}
            {
              fields.length !== 1 ? (
                <div className="event-poll__question-title">
                  {`${t("Question No")} ${index + 1}`}
                </div>
              ) : null
            }
            {/* display remove Poll icon */}
            {
              fields.length !== 1 ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div className="ml-2 mt-2 mb-1" onClick={async () => {
                  if (poll) {
                    const questionToBeDeleted = poll.questions[index];
                    await removeQuestion(questionToBeDeleted);
                  }
                  fields.remove(index);
                }}>

                  {icons.cancel}
                </div>
              ) : null
            }
          </div>

          {/* question title field */}
          <DefaultFormText2
            hint={t("Question")}
            attribute={`${question}title`}
            type="text"
            autoFocus
            label={null}
          />
          <div className="p-2"/>

          {/* question Type Field */}
          {/* <DefaultFormSelect */}
          {/*  attribute={`${question}questionType`} */}
          {/*  options={questionTypes} */}
          {/*  label={null} */}
          {/*  disabled */}
          {/* /> */}
          {/* <div className="p-2"/> */}
          {/* Question Choices Component */}
          <DefaultFormArrayField
            name={`${question}choices`}
            eventPollMetaModel={eventPollMetaModel}
            questionNo={`${question}`}
            choices={poll && poll.questions[index] ? poll.questions[index].choices : null}
          />

        </div>
      ))}

      {/* if multi question then add button to add more questions */}
      {isMultiQuestion ? (
        <div className="mb-4 mt-4">
          <button
            type="button"
            className="event-poll-add__add-question-btn mb-1"
            onClick={addMetaRules}
            style={{ padding: "5px" }}
          >
            {`+ ${t("Add Question")}`}
          </button>
        </div>
      ) : null}
    </div>
  );
}
