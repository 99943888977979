// @flow
import React, { useEffect, useState } from "react";
import type { RouteAuth, Routes } from "metaup/routing/routingUtils";
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import { useDispatch, useSelector } from 'react-redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import { getToken } from './model/Token.client';
import NetworkPageView from './views/NetworkPageView';
import { getUser } from '../users/model/User.client';
import { redirect } from '../core/data/router.redux';
import { listPairs, updatePair } from './model/Pair.client';
import { createSessionUser, listSessionUsers, updateSessionUser } from './model/SessionUser.client';
import type { SessionState } from './redux/Session.redux';
import { startTrackingSessions } from './redux/Session.redux';
import { startTrackingSessionRounds } from './redux/SessionRound.redux';

const tokenShape = `{
  token
}`;

const pairShape = `{
  id
  userId1
  userId2
  sessionId
  round
  roundDelay
  status
}`;

const sessionUserShape = `{
  id
  userId
  sessionId
  status
}`;

const userShape = `{
  id
  isBanned
  firstName
  lastName
  avatar {
    id
    cover(width: 320, height: 335) { width height url }
  }
  position
  companyTitle
  interests
  lookingFor
  publicContacts {
    id
    kind
    title
    content
  }
}`;

type Props = {
  userId?: string,
  id?: string,
  paramRound?: string
};


function NetworkPage({ userId, id, paramRound }: Props) {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [participantID, setParticipantId] = useState(null);
  const [pair, setPair] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [sessionUser, setSessionUser] = useState(null);
  const [waiting, setWaiting] = useState(null);
  const [pairStatus, setPairStatus] = useState(null);
  const { sessions }: SessionState = useSelector(({ network }) => network.sessionX);
  const { sessionRounds }: SessionState = useSelector(({ network }) => network.sessionRoundX);
  const [session, setSession] = useState(null);
  const [round, setRound] = useState(null);

  async function loadToken(participantId: string, room: string) {
    try {
      if (participantId) {
        const t = await getToken(participantId, room, tokenShape);
        return setToken(t);
      }
      return true;
    } catch (err) {
      return setToken(new ErrorCapsule(err, () => [
        setToken(null),
        loadToken(participantId, room),
      ]));
    }
  }


  // eslint-disable-next-line consistent-return
  async function updateStatus(status: string) {
    try {
      if (userId) {
        const t = await listSessionUsers({ sessionId: id, userId }, sessionUserShape);
        if (t.length) {
          const r =  await updateSessionUser({ id: t[0].id, status }, sessionUserShape);
          setSessionUser(r);
        }
      }
    } catch (err) {
      return updateSessionUser(new ErrorCapsule(err, () => [
        // eslint-disable-next-line no-undef
        updateSessionUser({ id: t[0].id, status }, sessionUserShape),
      ]));
    }
  }

  // console.log(session);

  async function joinSession() {
    try {
      const sessionUsers = await listSessionUsers({
        userId,
        sessionId: id,
      }, sessionUserShape);
      if (!sessionUsers.length) {
        const s = await createSessionUser({ userId, sessionId: id }, sessionUserShape);
        setSessionUser(s);
      } else {
        setSessionUser(sessionUsers[0]);
      }
    } catch (err) {
      return setSessionUser(new ErrorCapsule(err, () => [
        setSessionUser(null),
        createSessionUser({ userId, sessionId: id }, sessionUserShape),
      ]));
    }
    return false;
  }

  async function checkSessionStart() {
    return sessions.filter((s) => {
      return s.id === id;

    });
  }

  const checkSession = async () => {
    const localSession = await checkSessionStart();
    if (localSession.length) {
      setSession(localSession[0]);
      if (localSession[0].status === "ended") {
        dispatch(redirect('/network/end'));
      }
      if (localSession[0].status === "created") {
        await joinSession();
        setWaiting(true);
        // return;
      }
      if (localSession[0].status === "started") {
        await joinSession();
        setWaiting(false);
      }
    }
  };


  async function loadPair(participantId: string) {
    try {
      if (participantId && sessions && !pair) {
        const prs = await listPairs({
          sessionId: round.sessionId,
          status: true,
          round: round.round_no,
        }, pairShape);
        console.log('fetched Pairs', prs);
        const pr = prs.filter((p) => {
          return (p.userId2 === participantId || p.userId1 === participantId);
        });
        if (!pr.length) {
          // await updateSession({ id: localSession[0].id, status: "ended" }, sessionShape);
          // dispatch(redirect('/network/end'));
          console.log('No Pair Found');
          // window.location.reload();

          // eslint-disable-next-line no-throw-literal
          throw "no pairs found";
        }
        const t = pr[0];
        if (t.userId1 === "-1" || t.userId2 === "-1") {
          setPairStatus("-1");
        }
        if (t.userId1 === "-2" || t.userId2 === "-2") {
          setPairStatus("-2");
        }
        if (t.userId1 === "-3" || t.userId2 === "-3") {
          setPairStatus("-3");
        }
        setPair(t);
        await updateStatus("busy");
      }
    } catch (err) {
      console.log(err);
      setPair(new ErrorCapsule(err, () => [
        setPair(null),
        loadPair(participantId),
      ]));
    }
  }

  async function loadUser(usrID: string) {
    try {
      const usr = await getUser(usrID, userShape);
      return setUser(usr);
    } catch (err) {
      return setUser(new ErrorCapsule(err, () => [
        setUser(null),
        loadUser(usrID),
      ]));
    }
  }

  // console.log(pair,token);
  // useEffect(() => (sessionId ? startTrackingNewCalls(dispatch, sessionId) : undefined), [sessionId]);

  useEffect(() => (startTrackingSessions(dispatch)), []);
  useEffect(() => (id ? startTrackingSessionRounds(dispatch, id) : undefined), [id]);

// update online status
  // useEffect(() => {
  //   updateStatus("online");
  // }, []);

  useEffect(() => {

    if (sessions) {
      // check and join sessions
      checkSession();
    }
  }, [sessions]);

  useEffect(() => {

    if (round) {
      if (round.status === "ongoing" && userId) {
        loadPair(userId);
      }

      if (round.status === "ended") {
        // console.log('********************************');
        if (pair) {
          // console.log('pair',pair);
          updatePair({ id: pair.id, status: false }, pairShape);
          setPair(null);
          setParticipantId(null);
        }
        if (token) {
          setToken(null);
        }
        if (pairStatus) {
          // console.log('pairStatus flushed');
          setPairStatus(null);
        }
        // updateRound();
        const tmpRound = getNextValidRound();
        if (tmpRound) {
          setRound(tmpRound);
        }
      }
    }
  }, [round]);

  const getNextValidRound = () => {
    const filteredRounds = sessionRounds.filter((r) => r.status !== "ended").sort((a,b) => a.round_no - b.round_no);

    if (filteredRounds.length) {
      return filteredRounds[0];
    }
    return null;
  };

  // checks and updates rounds
  useEffect(() => {

    if (sessionRounds && sessionRounds.length) {
      if (sessionRounds.length) {
        setRound(sessionRounds[0]);
      } else {
        setRound({ ...round, status: "ended" });
        console.log('session Ended, No Rounds left');
      }
    }
  }, [sessionRounds]);

  // useEffect(() => {
  //   // If user exist load pair for the call
  //   if (userId && !waiting) {
  //     loadPair(userId);
  //   }
  // }, [userId,waiting]);

  useEffect(() => {


    // If pair exist then generate pair for the call
    if (pair && !(pair instanceof ErrorCapsule) && sessions) {
      loadToken(userId, pair.id);
    }

  }, [pair]);

  useEffect(() => {

    // Get participant's info
    if (participantID) {
      loadUser(participantID);
    }
  }, [participantID]);

  // Render
  return (
    <NetworkPageView
      token={token}
      handleLogout={() => {
        setToken(null);
        dispatch(redirect('/events/'));
      }}
      user={user}
      pair={pair}
      session={session}
      setParticipantId={setParticipantId}
      userId={userId}
      pairStatus={pairStatus}
      waiting={waiting}
      updateStatus={updateStatus}
      sessionUser={sessionUser}
      round={round}
    />
  );
}

export function networkPageRoutes(): Routes {
  return [
    {
      title: (t) => t('Network'),
      path: ':id/:round/',
      isEnabled: ({ isUser }) => {
        if (!isUser) {
          const path = window.location.pathname.toString();
          if (path.includes("/network/")) {
            localStorage.prevPath = window.location.pathname;
          }
        }
        return isUser;
      },
      nav: null,
      render: (params, { authState }: RouteAuth) => (
        <NetworkPage
          userId={authState.user ? authState.user.id : null}
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
          paramRound={params.round}
        />
      ),
    },

  ];
}

// eslint-disable-next-line no-unused-vars
function timeout(delay: number) {
  return new Promise(res => setTimeout(res, delay));
}