// @flow
import { email, required } from 'redux-form-validators';
import { string } from '../../core/data/rules';
import type { MetaModel } from '../../core/data/rules';

export type AuthRegister = {|
  email: string,
  password: string,
|}


export const authRegisterMetaModel: MetaModel = {
  name: 'AuthRegister',
  rules: {
    email: [email(), required()],
    password: [string(), required()],
  },
  labels: {
    email: t => t('Email'),
    password: t => t('Password'),
  },
};
