// @flow
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { globalErrorsReducer } from './GlobalErrors';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'elements';

export const reducer = safeCombineReducers({
  globalErrors: globalErrorsReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
