// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import gql from 'graphql-tag';
import type { Routes } from 'metaup/routing/routingUtils';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import LoginPageView from './views/LoginPageView';
import { authShape } from './authState';
import type { AsyncAction } from '../core/data/reduxStore';
import { apolloSubmitForm } from '../core/data/apolloClient';
import { finishAuth } from './AuthHomePage';

async function login(email: string, password: string, joinToEventId: ?string): ?AsyncAction {
  const result = await apolloSubmitForm(
    gql`
      mutation ($email: String!, $password: String!) {
        authLogin(email: $email, password: $password) ${authShape}
      }
    `,
    { email, password },
  );

  return finishAuth(result, joinToEventId);
}

type Props = {
  joinToEventId: ?string,
}

function LoginPage({
  joinToEventId,
}: Props) {
  const dispatch = useDispatch();
  return (
    <LoginPageView
      urlPrefix={joinToEventId ? `/join/${joinToEventId}` : ''}
      onSubmit={({ email, password }) => dispatch(login(email, password, joinToEventId))}
    />
  );
}

export function loginPageRoutes(): Routes {
  return [
    {
      title: t => t('Login'),
      path: [
        '/login/westa/',
        '/join/:joinToEventId/login/westa/',
      ],
      render: params => (
        <LoginPage
          {...sanitizeRouteParams(params, {
            joinToEventId: '?id',
          }, {
            joinToEventId: null,
          })}
        />
      ),
    },
  ];
}
