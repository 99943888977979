// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { renderImageMeta } from 'metaup/render/visualUtils';

import { formatEventStart } from '../../core/render/customVisualUtils';
import type { Event } from '../model/Event.model';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { icons } from '../../elements/icons';
import { useDialog } from '../../elements/Dialog';

type EventsListPageViewProps = {
  events: null | Array<Event> | ErrorCapsule,
  hideFilter?: boolean,
  isAdmin?: boolean,
  onDeleteEvent: () => {},
}

export default function EventsListPageView({
  events,
  hideFilter,
  isAdmin,
  onDeleteEvent,
}: EventsListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!events) return <Loader />;
  if (events instanceof ErrorCapsule) return <PageLoadFailed error={events} />;

  return (
    <div className="events-list">
      {!hideFilter && (
        <div className="events-list__buttons">
          <button type="button" className="btn btn-default btn-sm events-list__btn events-list__btn_inactive">Ongoing</button>
          <button type="button" className="btn btn-default btn-sm events-list__btn events-list__btn_active">My events</button>
        </div>
      )}
      {isAdmin && (
        <div className="text-center mb-3">
          <Link to="/events/edit/new/" className="btn btn-primary btn-sm">add event</Link>
        </div>
      )}
      {events.map(({
        id,
        title,
        cover,
        start,
        startTime,
      }) => (
        <Link key={id} className="events-list__event" to={`/events/${id}/`}>
          <span className="events-list__event-body">
            <span className="events-list__event-title">{title}</span>
            <span className="events-list__event-date">
              <span className="events-list__event-date-icon">{icons.calendar}</span>
              <span className="events-list__event-date-start">
                {formatEventStart(t, start, startTime)}
              </span>
            </span>
            {isAdmin && (
              <span className="events-list__admin-block">
                <Link to={`/events/edit/${id}/`} className="btn btn-primary btn-sm" title="edit">=</Link>
                <button
                  type="button"
                  className="btn btn-danger btn-sm ml-1"
                  title="delete"
                  onClick={e => {
                    e.preventDefault(); // This is for parent link
                    confirm({
                      renderBody: () => `Delete ${title}?`,
                      handler: () => onDeleteEvent(id),
                    });
                  }}
                >
                  ×
                </button>
              </span>
            )}
          </span>
          <span className="events-list__picture">
            {cover ? renderImageMeta(cover.cover, t('Event picture'),"layout__obj-fit") : null}
          </span>
        </Link>
      ))}
    </div>
  );
}
