// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { Routes } from 'metaup/routing/routingUtils';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import AuthHomePageView from './views/AuthHomePageView';
import { showGlobalError } from '../elements/GlobalErrors';
import { setUser } from './authState';
import { createUserEvent } from '../events/model/UserEvent.client';
import errorMessages from '../core/exceptions/errorMessages';
import { setRedirectTo } from '../onboarding/FirstVisitPage';
import { redirect } from '../core/data/router.redux';

async function joinEventOnce(eventId: string, userId: string) {
  try {
    await createUserEvent(
      {
        eventId,
        userId,
        status: 'joined',
        remindOptions: { byEmail: true },
      },
      '{ id }',
    );
  } catch (err) {
    if (err.errors._error === errorMessages.alreadyJoinedTheEvent) {
      return; // Swallow this case
    }
    throw err;
  }
}

export async function finishAuth(user, joinToEventId = null) {
  // Enable auth in Apollo and other
  const setUserAction = setUser(user);

  // Join the event, if requested
  if (joinToEventId) {
    await joinEventOnce(joinToEventId, user.id);
  }

  // Complete auth and redirect
  let redirectTo = joinToEventId ? `/events/${joinToEventId}/` : '/';

  // Implementing temporary redirection
  try {
    const { prevPath } = localStorage;
    if (prevPath && prevPath !== "") {
      redirectTo = prevPath;
      localStorage.prevPath = "";
    }
  } catch (e) {
    console.log(e);
  }

  return user.user.isOnboarded
    ? [
      // Complete auth
      setUserAction,
      // Go event
      redirect(redirectTo),
    ]
    : [
      // Complete auth
      setUserAction,
      // Go onboarding and then event
      setRedirectTo(redirectTo),
      redirect('/onboarding/'),
    ];
}

function signSocial(t, id, joinToEventId) {
  return new Promise(resolve => {
    const signWindow = window.open(
      `${process.env.REACT_APP_BACKEND}/auth/${id}`,
      'Westa_SocialLogin',
      'toolbar=no,height=570,width=520',
    );
    const listener = e => {
      // Implement relevance
      if (e.source !== signWindow) {
        return;
      }
      window.removeEventListener('message', listener);

      // Finish UI
      signWindow.close();

      const [err, user] = e.data;

      // Handle error
      if (err) {
        const matches = err.match(/TODO_FIX_ARCHITECTURE#emailIsAlreadyRegistered#(.+)/);
        if (matches) {
          resolve(showGlobalError(t('The user with {{email}} email has already registered in the app. Please use the same way of authentication as before.', {
            email: matches[1],
          })));
        } else {
          resolve(showGlobalError(err));
        }
        return;
      }

      // Handle success
      resolve(finishAuth(user, joinToEventId));
    };
    window.addEventListener('message', listener);
  });
}

type Props = {
  joinToEventId: ?string,
}

function AuthHomePage({
  joinToEventId,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Render
  return (
    <AuthHomePageView
      urlPrefix={joinToEventId ? `/join/${joinToEventId}` : ''}
      onGoogle={() => dispatch(signSocial(t, 'google', joinToEventId))}
      onFacebook={() => dispatch(signSocial(t, 'facebook', joinToEventId))}
      onVk={() => dispatch(signSocial(t, 'vkontakte', joinToEventId))}
    />
  );
}

export function authHomePageRoutes(): Routes {
  return [
    {
      title: t => t('Login'),
      path: [
        '/login/',
        '/join/:joinToEventId/login/',
      ],
      render: params => (
        <AuthHomePage
          {...sanitizeRouteParams(params, {
            joinToEventId: '?id',
          }, {
            joinToEventId: null,
          })}
        />
      ),
      design: require('./views/AuthHomePageView.design.png'), // eslint-disable-line global-require
    },
  ];
}
