// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import LoadFailed from './LoadFailed';

type GlobalErrorsState = {
  currentError: null | string | Error | ErrorCapsule,
}

const initialState: GlobalErrorsState = {
  currentError: null,
};

const ACTION_SET_CURRENT_ERROR = 'elements/currentError/ACTION_SET_CURRENT_ERROR';

export function showGlobalError(error: null | string | Error | ErrorCapsule): AnyAction {
  return {
    type: ACTION_SET_CURRENT_ERROR,
    error,
  };
}

export function resetGlobalError(): AnyAction {
  return showGlobalError(null);
}

export function globalErrorsReducer(
  state: GlobalErrorsState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_CURRENT_ERROR:
      return {
        ...state,
        currentError: action.error,
      };

    default:
      return state;
  }
}

export default function GlobalErrors() {
  const dispatch = useDispatch();
  const {
    currentError,
  }: GlobalErrorsState = useSelector(({ elements }) => elements.globalErrors);
  const history = useHistory();

  // Watch URL and reset errors on URL change
  useEffect(() => {
    dispatch(resetGlobalError());
  }, [history.location.pathname]);

  // Render
  return currentError
    ? (
      <LoadFailed
        error={currentError}
        onClose={() => dispatch(resetGlobalError())}
      />
    )
    : null;
}
