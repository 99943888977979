// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';

import { networkPageRoutes } from './NetworkPage';
import { networkEndPageRoutes } from './NetworkEndPage';
import { sessionAddPageRoutes } from './SessionAddPage';
import { sessionLogsPageRoutes } from './SessionLogsPage';


// ### GENERATED IMPORTS GO HERE ###

export function networkRoutes() {
  return prefixRoutes('/network/', [
    sessionLogsPageRoutes(),
    sessionAddPageRoutes(),
    networkEndPageRoutes(),
    networkPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
