// @flow
import React from 'react';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';

/* eslint-disable max-len */

export default function TermsOfService() {
  return (
    <div className="text-page">
      <h1>WESTA Terms of Service</h1>
      <p>25 July 2022</p>
      <h3>WESTA responsibility</h3>
      <p>Events are organized and conducted by the Organizer, not WESTA. We are a third party providing the Services to facilitate an Event.
      We are not responsible and can not be held liable for any matters described in the Organizer Responsibility section (see below).</p>
      <h3>Organizer’s responsibilities</h3>
      <p>The Organizer has full control over the Event</p>
      <p>The Organizer is responsible for:</p>
      <p>Event cancellation</p>
      <p>Event duration</p>
      <p>Merchandise sales and donations</p>
      <p>Marketing activities connected to the Event</p>
      <p>Content provided or created</p>
      <p>Overseeing the conduct of Users</p>
      <p>Please contact the Organizer if you have any questions about the Event</p>
    </div>
  );
}

export function termsOfServiceRoutes(): Routes {
  return [
    {
      title: t => t('Terms of Service'),
      path: '/terms/',
      nav: {
        showHamburger: true,
        showTitle: true,
      },
      render: (params, { isGuest }: RouteAuth) => (
        <TermsOfService />
      ),
    },
  ];
}
