// @flow
import React, { useEffect, useState } from "react";
import type { RouteAuth, Routes } from "metaup/routing/routingUtils";
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import { getSession } from './model/Session.client';
import { listAllPairs } from './model/Pair.client';
import { listSessionUsers } from './model/SessionUser.client';

const sessionShape = `{
  id
  round
  status
}`;

const pairShape = `{
  id
  userId1
  userId2
  sessionId
  round
  roundDelay
  status
}`;

const sessionUserShape = `{
  id
  userId
  sessionId
  status
}`;

type Props = {
  id?: string,

};

function SessionLogsPage({ id }: Props) {

  // eslint-disable-next-line no-unused-vars
  const [pairs, setPairs] = useState(null);
  const [status, setStatus] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [sessionUsers, setSessionUsers] = useState(null);

  async function loadPairs() {
    console.log(id);
    let p = await listAllPairs({ sessionId: id }, pairShape);
    console.log('..', p);

    const su = await listSessionUsers({ sessionId: id }, sessionUserShape);
    const session = await getSession(id, sessionShape);
    // eslint-disable-next-line prefer-spread
    const max = Math.max.apply(Math, p.map(function (o) {
      return o.round;
    }));
    p = p.filter((o) => o.round === max.toString());
    let s = `Session Status: ${session.status}\nRound: ${max || 0}\nUsers Joined:\n`;
    // eslint-disable-next-line array-callback-return
    su.map((tmp) => {
      s += ` User: ${tmp.userId} ---- Status: ${tmp.status === "online" ? "Not Ready" : tmp.status}\n`;
    });

    s += `Pairs:${p.length}\n`;
    // eslint-disable-next-line array-callback-return
    p.map((tmp) => {
      s += ` User1: ${tmp.userId1} --> User2: ${tmp.userId2}\n`;
    });

    // eslint-disable-next-line no-control-regex
    setStatus(s);
    console.log(status);

    if (p.length)
      setPairs(p);
    if (su.length)
      setSessionUsers(su);
    console.log("pairs", p);
    console.log("su", su);
    // console.log(p);
    // console.log(su);
  };
  useEffect(() => {

    fetchLogs();
  }, []);
  const fetchLogs = async () => {
    await loadPairs();
  };


  // if (session instanceof ErrorCapsule) return <PageLoadFailed error={session}/>;
  // Render
  return (
    <div style={{ paddingTop: "50px" }}>
      <h1>The logs are:</h1>
      <pre>
        {status}
      </pre>
      {id}

    </div>
  );
}

export function sessionLogsPageRoutes(): Routes {
  return [
    {
      title: (t) => t('Add Session'),
      path: '/sessionLogs/:id',
      isEnabled: ({ authState }: RouteAuth) => authState.user && authState.user.user.isAdmin,
      nav: {
        showHamburger: true,
        showTitle: true,
        onBack: null,
        eventMenu: 'Westa Networking',
      },
      render: (params, { authState }: RouteAuth) => (
        <SessionLogsPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
    },

  ];
}
