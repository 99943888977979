// @flow
import React from 'react';
import { Switch } from 'react-router-dom';
import type { RoutedProps } from 'metaup/routing/routingUtils';
import { renderRoutes, connectRouter } from 'metaup/routing/routingUtils';

import { appRoutes } from '../assemble/appRoutes';

function Router(props: RoutedProps) {
  return (
    <Switch>
      {renderRoutes(props, appRoutes)}
    </Switch>
  );
}

export default connectRouter()(Router);
