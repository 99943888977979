// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import { string } from '../../core/data/rules';
import type { User } from './User.model';
import type { MetaModel } from '../../core/data/rules';

export type UserContact = {|
  id: string,
  userId: string,
  user: User,
  kind: string,
  title: ?string,
  content: string,
|}


export const userContactMetaModel: MetaModel = {
  name: 'UserContact',
  rules: {
    userId: [string(), required()],
    kind: [string(), required()],
    title: [string()],
    content: [string(), required()],
  },
};
