// @flow
import { AnyAction } from "redux";
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import type { SessionRound } from '../model/SessionRound.model';
import { getLiveSessionRounds } from '../model/SessionRound.client';

export type SessionRoundState = {
  sessionRounds: null | SessionRound | ErrorCapsule,
};

const initialState: SessionRoundState = {
  sessionRounds: null,
};

const sessionRoundShape = `{
    id
    sessionId
    status
    round_no
    start
    end
  }`;


const ACTION_SET_SESSIONROUNDS = 'sessionRounds/sessionX/ACTION_SET_SESSIONROUNDS';

function setSessionRounds(sessionRounds: null | Array<SessionRound> | ErrorCapsule) {
  return {
    type: ACTION_SET_SESSIONROUNDS,
    sessionRounds,
  };
}

export function sessionRoundXReducer(
  state: SessionRoundState = initialState,
  action: AnyAction,
) {
  switch (action.type) {
    case ACTION_SET_SESSIONROUNDS:
      return {
        ...state,
        sessionRounds: action.sessionRounds,
      };
    default:
      return state;
  }
}


export function startTrackingSessionRounds(dispatch, sessionId): () => void {
  return getLiveSessionRounds(sessionId, sessionRoundShape, (packet) => {
    dispatch([setSessionRounds(packet.reset)]);
  });
}
