// @flow
import { AnyAction } from "redux";
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import { onAllLivePolls } from "../model/Poll.client";
import { listPollVotes } from "../model/PollVote.client";
import { isVoted } from "../../messenger/ChatsListPage";
import type { Poll } from '../model/Poll.model';
import { isPublished } from '../utils';

type PollState = {
  polls: [] | Poll | ErrorCapsule,
  newMessageCount: ?number,
};

const initialState: PollState = {
  newPollsCount: null,
  polls: [],
};

const pollShape = `{
    id
    title
    event {
    id
    title
    cover {
      id
      cover(width: 40, height: 40) { width height url }
    }
    organizerId
  }
  eventId
   questions(filter:{
      _sort:"createdAt"
    }) 
    {
      id
      title
      questionType
      createdAt
      choices(filter:{
      _sort:"createdAt"
    }) 
    {
        id
        title
        questionId
        createdAt
        votes {
          id
          choiceId
          questionId
          userId
          pollId
        }
      }
    }
    publishAt
    createdAt
}`;

const voteShape = `{
  id
  choiceId
  questionId
  userId
}`;


const ACTION_UPDATE_POLLCOUNT = "polls/pollX/ACTION_UPDATE_POLLCOUNT";
const ACTION_SET_POLL = 'polls/pollX/ACTION_SET_POLL';

function setPollCount(pollCount: null | number | ErrorCapsule) {
  return {
    type: ACTION_UPDATE_POLLCOUNT,
    newPollsCount: pollCount,
  };
}

function setPolls(polls: null | Array<Poll> | ErrorCapsule) {
  return {
    type: ACTION_SET_POLL,
    polls,
  };
}

export function pollXReducer(
  state: PollState = initialState,
  action: AnyAction,
) {
  switch (action.type) {
    case ACTION_UPDATE_POLLCOUNT:
      return {
        ...state,
        newPollsCount: action.newPollsCount,
      };
    case ACTION_SET_POLL:
      return {
        ...state,
        polls: action.polls,
      };
    default:
      return state;
  }
}

export function getNewPollCount(reduxState) {
  return reduxState.polls.pollX.newPollsCount;
}

// Extract new unVoted Polls Count for notification
async function extractCount(polls, userId) {

  const currentUserId = userId;
  let myUnreadPollCount = 0;
  const userVotes = await listPollVotes({ userId: currentUserId }, voteShape);
  let unVotedPolls = polls.filter(
    (value) => {
      const temp = isVoted(value.questions, userVotes);
      return !temp;
    },
  );
  unVotedPolls = unVotedPolls.filter(
    (value) =>
      value.questions.length !== 0 &&
      isPublished(value.publishAt),
  );

  myUnreadPollCount += unVotedPolls.length;
  return setPollCount(myUnreadPollCount);
}

export function startTrackingNewPolls(dispatch, userId): () => void {
  return onAllLivePolls(userId, pollShape, (packet) => {
    dispatch([extractCount(packet.reset, userId), setPolls(packet.reset)]);
  });
}
