// @flow
import _reduce from "lodash/reduce";
import { AnyAction } from "redux";
import { markChatRead, onMyUnreadChats } from "../model/Chat.client";

type ChatState = {
  unread: { [string]: number },
  unreadByEvent: { [string]: number },
  newMessageCount: ?number,
};

const initialState: ChatState = {
  unread: {},
  unreadByEvent: {},
  newMessageCount: null,
};

const ACTION_UPDATE_UNREAD = "messenger/Chat/ACTION_UPDATE_UNREAD";

function decodeUnreadToHash(array) {
  const result = {
    unread: {},
    unreadByEvent: {},
  };
  array.forEach(({ chatId, eventId, unread }) => {
    result.unread[chatId] = unread;
    if (eventId) {
      result.unreadByEvent[eventId] = unread;
    }
  });
  return result;
}

function updateUnread({ reset, update }) {
  return {
    type: ACTION_UPDATE_UNREAD,
    reset: reset && decodeUnreadToHash(reset),
    update: update && decodeUnreadToHash(update),
  };
}

export function chatXReducer(
  state: ChatState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case ACTION_UPDATE_UNREAD: {
      const newValues = action.reset || {
        unread: { ...state.unread, ...action.update.unread },
        unreadByEvent: {
          ...state.unreadByEvent,
          ...action.update.unreadByEvent,
        },
      };
      return {
        ...state,
        ...newValues,
        newMessageCount: _reduce(newValues.unread, (r, v) => r + v, 0),
      };
    }

    default:
      return state;
  }
}

export function getNewMessageCount(reduxState) {
  return reduxState.messenger.chatX.newMessageCount;
}

export function getUnreadInEventChat(reduxState, eventId) {
  return reduxState.messenger.chatX.unreadByEvent[eventId];
}

export function getUnreadByChat(reduxState) {
  return reduxState.messenger.chatX.unread;
}

export function startTrackingUnread(dispatch): () => void {
  return onMyUnreadChats((packet) => {
    dispatch(updateUnread(packet));
  });
}

export function emitMarkChatRead(chatId) {
  return [
    updateUnread({
      update: [
        {
          chatId,
          unread: 0,
        },
      ],
    }),
    markChatRead(chatId).then(() => []),
  ];
}
