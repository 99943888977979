// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { pollChoiceMetaModel } from './PollChoice.model';

export function filterPollChoiceInput(values) {
  return _pickBy(values, (_, key) => key in pollChoiceMetaModel.rules || key === 'id');
}

export function listPollChoices(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: PollChoicesFilter) {
        listPollChoices(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function createPollChoice(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PollChoiceCreate!) {
        createPollChoice(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function updatePollChoice(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PollChoicesFilter!) {
        updatePollChoice(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function deletePollChoice(id: string) {
  return apolloSubmitForm(
    gql`
      mutation ($id: String!) {
        deletePollChoice(id: $id)
      }
    `,
    { id },
  );
}