// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';
import { getUser, updateUser } from './model/User.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import ProfilePageView from './views/ProfilePageView';
import type { User } from './model/User.model';
import { getAuthUser } from '../auth/authState';
import { showGlobalError } from '../elements/GlobalErrors';

type ProfileState = {
  user: null | User | ErrorCapsule,
}

const userShape = `{
  id
  isBanned
  firstName
  lastName
  avatar {
    id
    cover(width: 320, height: 335) { width height url }
  }
  position
  companyTitle
  interests
  lookingFor
  publicContacts {
    id
    kind
    title
    content
  }
}`;

const initialState: ProfileState = {
  user: null,
};

const ACTION_SET_USER = 'users/profile/ACTION_SET_USER';

function setUser(user: null | User | ErrorCapsule) {
  return {
    type: ACTION_SET_USER,
    user,
  };
}

async function loadUser(id: string) {
  try {
    const user = await getUser(id, userShape);

    return setUser(user);
  } catch (err) {
    return setUser(new ErrorCapsule(err, () => [
      setUser(initialState.user),
      loadUser(id),
    ]));
  }
}

async function banUser(id: string) {
  try {
    const user = await updateUser({ id, isBanned: true }, userShape);

    return setUser(user);
  } catch (err) {
    return showGlobalError(new ErrorCapsule(err, () => [
      banUser(id),
    ]));
  }
}

export function profileReducer(
  state: ProfileState = initialState,
  action: AnyAction,
) {
  switch (action.type) {

    case ACTION_SET_USER:
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
}

function ProfilePage({
  id,
}: Props) {
  const dispatch = useDispatch();
  const {
    user,
  }: ProfileState = useSelector(({ users }) => users.profile);
  const currentUser = useSelector(getAuthUser);
  // Load User
  useEffect(() => {
    dispatch([
      setUser(null),
      loadUser(id),
    ]);
    return () => dispatch(setUser(null));
  }, [id]);

  // Render
  return (
    <ProfilePageView
      user={user}
      isOwn={currentUser && (id === currentUser.id)}
      isAdmin={currentUser && currentUser.isAdmin}
      onBanUser={() => dispatch(banUser(id))}
    />
  );
}

export function profilePageRoutes(): Routes {
  return [
    {
      title: t => t('Own Profile'),
      path: [
        '/people/',
        '/people/my/',
      ],
      render: (params, { authState }: RouteAuth) => (
        <Redirect to={`/people/${authState.id}/`}/>
      ),
    },
    {
      title: t => t('Profile'),
      path: '/people/:id/',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        onBack: history => history.goBack(),
        showTitle: true,
      },
      render: (params, auth: RouteAuth) => {
        const sanitizedParams = sanitizeRouteParams(params, {
          id: 'id',
        });
        return (
          <ProfilePage
            {...sanitizedParams}
            isOwn={auth.authState.id === sanitizedParams.id}
          />
        );
      },
      design: require('./views/ProfilePageView.design.own.mobile.png'), // eslint-disable-line global-require
    },
  ];
}
