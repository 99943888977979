// @flow
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import { icons } from "../../elements/icons";
import { DefaultFormRadio } from "../../elements/DefaultForm";
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import type { PollQuestion } from "../model/PollQuestion.model";
import { countChoicePercentage, countUserResponded } from '../utils';

export type PollQuestionPageViewProps = {
  question: null | Array<PollQuestion> | ErrorCapsule,
  isAdmin: boolean,
  publishAt: string,
  choiceVoted: boolean,
  defaultExpanded: boolean,
  choiceName: string,
  totalQuestionsNo: string,
  questionNo: string,
  formName: string,
  deleteVote: () => void,
  countChoiceVotes: () => void,
};

export default function PollQuestionView(props: PollQuestionPageViewProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    question,
    isAdmin,
    choiceVoted,
    publishAt,
    choiceName,
    deleteVote,
    defaultExpanded,
    questionNo,
    totalQuestionsNo,
    formName,
    countChoiceVotes,
  } = props;
  // Set Default expanded State of the Poll Question
  const [isOpen, setIsOpen] = useState(
    defaultExpanded !== null && defaultExpanded !== undefined
      ? defaultExpanded
      : true,
  );

  // Question choice already checked flag
  const [checked, setChecked] = useState(null);

  // Re-vote Function
  const revote = () => {
    deleteVote(question.id);
    setChecked(choiceVoted);
    dispatch(
      change(
        formName,
        `choice${questionNo - 1}`,
        JSON.stringify({
          choiceId: choiceVoted,
          questionId: question.id,
        }),
        true,
        null,
      ),
    );
  };

  // Mark Question Choice
  const markChoice = (choice) => {
    setChecked(choice.id);
    dispatch(
      change(
        formName,
        `choice${questionNo - 1}`,
        JSON.stringify({
          choiceId: choice.id,
          questionId: question.id,
        }),
        true,
        null,
      ),
    );
  };

  return (
    <div className="d-flex flex-row">

      <div>
        {/* if Multi Questions then show question number */}
        {/* {totalQuestionsNo > 1 && isAdmin ? ( */}
        {/*  <div */}
        {/*    className="event-poll__question-number" */}
        {/*    style={{ paddingTop: "10px" }} */}
        {/*  > */}
        {/*    {`${questionNo}`} */}
        {/*  </div> */}
        {/* ) : null} */}

        {/* if Admin then show MultiDots Icon */}
        {/* {isAdmin ? <div className="event-poll__question-multiDots">{icons.multiDots}</div> : null} */}
      </div>

      <div style={{ width: "100%", marginTop: totalQuestionsNo > 1 ? "20px" : "6px" }}>
        <div className="d-flex justify-content-between">
          {/* Show Question Title */}
          <div className="event-poll__question-title">{question.title}</div>

          {/* if Admin Show Poll Expand toggle button else show poll publish time */}
          {isAdmin ? (
            <button
              type="button"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="event-poll__question-toggle"
            >
              {isOpen ? icons.toggleUpArrow : icons.toggleDownArrow}
            </button>
          ) : (
            <div className="event-poll__time">
              {totalQuestionsNo === 1 ? moment(publishAt + "Z").format("H:mm") : `${questionNo} of ${totalQuestionsNo}`}
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between">
          {/* Show Anonymous Poll for single Question Polls */}
          {
            isOpen && totalQuestionsNo === 1 ? (
              <div className="event-poll__question-type">{t("Anonymous Poll")}</div>
            ) : <div/>
          }
          {/* show Users responded for single Question Polls for users */}
          {!isAdmin && totalQuestionsNo === 1 ? (
            <div className="event-poll__question-user-responded">
              {`${countUserResponded(question.choices)} ${t("votes")}`}
            </div>
          ) : <div style={{ margin: "2%" }}/>}
        </div>

        {/* check if the Question is expanded */}
        {isOpen ? (
          // check if Question is already voted
          choiceVoted ? (
            <div className="d-flex flex-column">

              {/* display voted Choices */}
              {question.choices.map((choice, index) => (
                <div
                  className="d-flex event-poll__question-choice"
                  key={choice.id}
                >
                  {/* Show choice percentage and user Votes */}
                  <div className="event-poll__question-choice-percentage">
                    {`${countChoicePercentage(
                      question.choices,
                      index,
                    )}% (${countChoiceVotes(choice.id)})`}
                  </div>

                  {/* display choice title */}
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex event-poll__question-choice-title">

                      {/* show tick icon of voted choice */}
                      {choiceVoted === choice.id ? (
                        <div className="event-poll__question-choice-voted-icon">
                          {icons.tick}
                        </div>
                      ) : null}
                      {choice.title}
                    </div>

                    {/* Show progress bar of the choice */}
                    <div id="progress-bar" className="all-rounded">
                      <div
                        id="progress-bar-percentage"
                        className="all-rounded"
                        style={{
                          width: `${countChoicePercentage(
                            question.choices,
                            index,
                          )}%`,
                          background: choiceVoted ? "#436F98" : "#6C6C6C",
                        }}
                      />
                    </div>

                  </div>
                </div>
              ))}

              {/* if not Admin Show change my answer Button */}
              <div className="text-center mt-1 mb-2">
                {!isAdmin ? (
                  <button
                    type="button"
                    className="event-poll__question-revote"
                    onClick={revote}
                  >
                    {t("Change my answer")}
                  </button>
                ) : null}
              </div>

            </div>
          ) : (
            // if question is not Voted
            <div className="d-flex flex-column">
              {question.choices.map((choice) => {
                return (
                  <div
                    className="d-flex event-poll__question-choice"
                    key={choice.id}
                  >
                    {/* show question choice */}
                    <div className="d-flex flex-column w-100">
                      <button
                        type="button"
                        className="d-flex event-poll__question-choice-title "
                        onClick={() => markChoice(choice)}
                      >
                        <DefaultFormRadio
                          attribute={choiceName}
                          label={null}
                          value={JSON.stringify({
                            choiceId: choice.id,
                            questionId: question.id,
                          })}
                          styleClass="event-poll__question-choice-radio "
                          checked={checked === choice.id}
                        />
                        <div className="ml-2"> {choice.title}</div>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}
