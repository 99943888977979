// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import _filter from 'lodash/filter';
import gql from 'graphql-tag';
import moment from 'moment';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';
import { listEvents } from './model/Event.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import EventsListPageView from './views/EventsListPageView';
import type { Event } from './model/Event.model';
import { apolloMutate } from '../core/data/apolloClient';

type EventsListState = {
  events: null | Array<Event> | ErrorCapsule,
}

const eventShape = `{
  id
  title
  cover {
    id
    cover(width: 64, height: 139) { width height url }
  }
  start
  startTime
}`;

const initialState: EventsListState = {
  events: null,
};

const ACTION_SET_EVENTS = 'events/eventsList/ACTION_SET_EVENTS';
const ACTION_DELETE_EVENT = 'events/eventsList/ACTION_DELETE_EVENT';

function setEvents(events: null | Array<Event> | ErrorCapsule) {
  return {
    type: ACTION_SET_EVENTS,
    events,
  };
}

async function loadEvents(filter?: ?{ [string]: string }) {
  try {
    const events = await listEvents({ ...filter, _sort: 'start,startTime' }, eventShape);

    return setEvents(events);
  } catch (err) {
    return setEvents(new ErrorCapsule(err, () => [
      setEvents(initialState.events),
      loadEvents(filter),
    ]));
  }
}

async function deleteEvent(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deleteEvent(id: $id)
      }
    `,
    { id },
  );

  return {
    type: ACTION_DELETE_EVENT,
    id,
  };
}

export function eventsListReducer(
  state: EventsListState = initialState,
  action: AnyAction,
) {
  switch (action.type) {

    case ACTION_SET_EVENTS:
      return {
        ...state,
        events: action.events,
      };

    case ACTION_DELETE_EVENT:
      return {
        ...state,
        events: _filter(state.events, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

type Props = {
  isAdmin: boolean,
}

function EventsListPage({
  isAdmin,
}: Props) {
  const dispatch = useDispatch();
  const {
    events,
  }: EventsListState = useSelector(({ events: { eventsList } }) => eventsList);

  // Load Events
  useEffect(() => {
    const filter = isAdmin
      ? null
      : { startSince: moment().subtract(3, 'days').utc().format('YYYY-MM-DD') };
    dispatch([
      setEvents(null),
      loadEvents(filter),
    ]);
    return () => dispatch(setEvents(null));
  }, []);


  // Render
  return (
    <EventsListPageView
      events={events}
      hideFilter // not implemented
      isAdmin={isAdmin}
      onDeleteEvent={id => dispatch(deleteEvent(id))}
    />
  );
}

export function eventsListPageRoutes(): Routes {
  return [
    {
      title: t => t('Events'),
      path: '',
      nav: {
        showHamburger: true,
        showTitle: true,
      },
      render: (params, { authState }: RouteAuth) => (
        <EventsListPage
          {...sanitizeRouteParams(params, {}, {})}
          isAdmin={authState.user && authState.user.user.isAdmin}
        />
      ),
      design: require('./views/EventsListPageView.design.mobile.png'), // eslint-disable-line global-require
    },
  ];
}
