// @flow
import React, { useContext, useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { getEvent } from './model/Event.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import EventPageView, { JoinButtons } from './views/EventPageView';
import type { Event } from './model/Event.model';
import LayoutContext from '../layout/LayoutContext';
import { createUserEvent } from './model/UserEvent.client';
import { getAuth } from '../auth/authState';
import { showGlobalError } from '../elements/GlobalErrors';
import { useDialog } from '../elements/Dialog';

type EventState = {
  event: null | Event | ErrorCapsule,
}

const eventShape = `{
  id
  title
  start
  startTime
  end
  endTime
  cover {
    id
    cover(width: 320, height: 330) { width height url }
  }
  description
  organizerId
  organizer {
    id
    title
  }
  address {
    title
    address
    mapPoint
    notes
  }
  currentUserRelation { id }
}`;

const initialState: EventState = {
  event: null,
};

const ACTION_SET_EVENT = 'events/event/ACTION_SET_EVENT';

function setEvent(event: null | Event | ErrorCapsule) {
  return {
    type: ACTION_SET_EVENT,
    event,
  };
}

async function loadEvent(id: string) {
  try {
    const event = await getEvent(id, eventShape);

    return setEvent(event);
  } catch (err) {
    return setEvent(new ErrorCapsule(err, () => [
      setEvent(initialState.event),
      loadEvent(id),
    ]));
  }
}

export async function joinEvent(eventId: string, userId: string) {
  try {
    const { event } = await createUserEvent(
      {
        eventId,
        userId,
        status: 'joined',
        remindOptions: { byEmail: true },
      },
      `{ event ${eventShape} }`
    );

    return setEvent(event);
  } catch (err) {
    return showGlobalError(new ErrorCapsule(err, () => [
      joinEvent(eventId, userId),
    ]));
  }
}

export function eventReducer(
  state: EventState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_EVENT:
      return {
        ...state,
        event: action.event,
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
}

function EventPage({
  id,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    event,
  }: EventState = useSelector(({ events }) => events.event);
  const { id: currentUserId } = useSelector(getAuth);
  const { setMeta } = useContext(LayoutContext);
  const { popupMenu, notify } = useDialog();

  // Load Event
  useEffect(() => {
    dispatch([
      setEvent(null),
      loadEvent(id),
    ]);
    if (!currentUserId) {
      popupMenu({ renderButtons: props => <JoinButtons {...props} eventId={id} /> });
    }
    return () => dispatch(setEvent(null));
  }, [id]);

  // Join event
  async function doJoinEvent() {
    const actions = await joinEvent(id, currentUserId);
    notify(() => t('You’ve successfully joined the event'));
    return actions;
  }

  // Set meta
  useEffect(() => {
    if (event) {
      setMeta({ eventMenu: event.currentUserRelation ? 'event' : null});
    }
  }, [event]);

  // Render
  return (
    <EventPageView
      event={event}
      onJoin={() => dispatch(doJoinEvent())}
      guestMode={!currentUserId}
    />
  );
}

export function eventPageRoutes(): Routes {
  return [
    {
      title: t => t('Event'),
      path: ':id/',
      nav: {
        showHamburger: true,
        eventMenu: null, // Will show inside
        showTitle: true,
      },
      render: params => (
        <EventPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          }, {
          })}
        />
      ),
      design: require('./views/EventPageView.design.mobile.png'), // eslint-disable-line global-require
    },
  ];
}
