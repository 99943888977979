// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { renderImageMeta, renderSwitch } from 'metaup/render/visualUtils';

import type { User } from '../model/User.model';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { useDialog } from '../../elements/Dialog';

export type ProfilePageViewProps = {
  user: null | User | ErrorCapsule,
  isOwn: boolean,
  isAdmin?: boolean,
  onBanUser: () => void,
}

export default function ProfilePageView({
  user,
  isOwn,
  isAdmin,
  onBanUser,
}: ProfilePageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!user) return <Loader />;
  if (user instanceof ErrorCapsule) return <PageLoadFailed error={user} />;

  const {
    id,
    isBanned,
    firstName,
    lastName,
    avatar,
    position,
    companyTitle,
    interests,
    lookingFor,
    publicContacts,
  } = user;

  return (
    <div className="profile">
      <div className="page-cover">
        {avatar ? renderImageMeta(avatar.cover, t('Avatar'),"layout__obj-fit") : null}
        {isOwn
          ? <NavLink to="/profile/" className="page-cover__btn">{t('Edit')}</NavLink>
          : <NavLink to={`/messenger/to/${id}/`} className="page-cover__btn">{t('Chat')}</NavLink>
        }
      </div>
      <p className="profile__name">{firstName} {lastName}</p>
      <p className="profile__position">{position}</p>
      <p className="profile__company-title">{companyTitle}</p>
      <p className="profile__detail-text">
        <span className="profile__detail-title">{t('Interested in:')}</span>
        {' '}
        <span>{interests}</span>
      </p>
      <p className="profile__detail-text">
        <span className="profile__detail-title">{t('Looking for:')}</span>
        {' '}
        <span>{lookingFor}</span>
      </p>
      {publicContacts.map(({ id: contactId, kind, title, content }) => (
        <p key={contactId} className={`profile__detail-text profile__detail-text_${kind}`}>
          <span className="profile__detail-title">
            {t('{{title}}:', {
              title: title || renderSwitch(kind, {
                phone: () => t('Phone'),
                email: () => t('Email'),
              }),
            })}
          </span> {renderSwitch(kind, {
            email: () => <a href={`mailto:${content}`}>{content}</a>,
            phone: () => <a href={`tel:${content}`}>{content}</a>,
          }, content)}
        </p>
      ))}
      {isAdmin && (
        isBanned
          ? <div className="alert alert-warning">This user is banned</div>
          : (
            <div className="text-center mt-2">
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={e => {
                  confirm({
                    renderBody: () => `Ban ${firstName}?`,
                    handler: () => onBanUser(),
                  });
                }}
              >
                ban user
              </button>
            </div>
          )
      )}
    </div>
  );
}
