// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import type { MetaModel } from '../../core/data/rules';
import { string } from '../../core/data/rules';

export type Session = {|
  id: string,
  round: string,
  name: string,
  status: string,
  duration: string,
|}


export const sessionMetaModel: MetaModel = {
  name: 'Session',
  rules: {
    id: [string()],
    round: [string()],
    name: [string()],
    status: [string()],
    duration: [string()],
  },
};
