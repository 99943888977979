// @flow
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { pollAddReducer } from './PollAddPage';
import { pollXReducer } from './redux/Poll.redux';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'polls';

export const reducer = safeCombineReducers({
  pollX: pollXReducer,
  pollAdd: pollAddReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
