// @flow
import React, { useState } from "react";
import type { RouteAuth, Routes } from "metaup/routing/routingUtils";
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import { createSession, updateSession } from './model/Session.client';
import PageLoadFailed from '../elements/PageLoadFailed';
import SessionAddPageView from './views/SessionAddPageView';
import { createSessionRound } from './model/SessionRound.client';

const sessionShape = `{
  id
  round
  status
  duration
}`;

const sessionRoundShape = `{
  id
  sessionId
  status
  round_no
  start
  end
}`;


function SessionAddPage() {

  // const [session, setSession] = useState({id: "495971e3b0a943f3b9beaa", round: "3", status: "created", duration: "80000", __typename: "Session"});

  const [session, setSession] = useState(null);

  async function makeSession(duration, rounds) {

    const name = Math.random().toString(36).substring(7);
    try {
      const s = await createSession({ name, duration, round: rounds }, sessionShape);
      return setSession(s);
    } catch (err) {
      return setSession(new ErrorCapsule(err, () => [
        setSession(null),
        createSession({ name }, sessionShape),
      ]));
    }
  }

  async function startSession() {
    try {
      if (session) {
        const s = await updateSession({ id: session.id, status: "started" }, sessionShape);
        await createSessionRound({ sessionId: s.id, round_no: s.round }, sessionRoundShape);
        setSession(s);
      }

      return;
    } catch (err) {
      setSession(new ErrorCapsule(err, () => [
        setSession(null),
        updateSession({ id: session.id, status: "started" }, sessionShape),
      ]));
    }
  }

  async function endSession() {
    try {
      if (session) {
        const s = await updateSession({ id: session.id, status: "ended" }, sessionShape);
        setSession(s);
      }

      return;
    } catch (err) {
      setSession(new ErrorCapsule(err, () => [
        setSession(null),
        updateSession({ id: session.id, status: "started" }, sessionShape),
      ]));
    }
  }


  if (session instanceof ErrorCapsule) return <PageLoadFailed error={session}/>;
  // Render
  return (
    <SessionAddPageView session={session} makeSession={makeSession} startSession={startSession}
                        endSession={endSession}/>
  );
}

export function sessionAddPageRoutes(): Routes {
  return [
    {
      title: (t) => t('Add Session'),
      path: '/sessionAdd/',
      isEnabled: ({ authState }: RouteAuth) => authState.user && authState.user.user.isAdmin,
      nav: {
        showHamburger: true,
        showTitle: true,
        onBack: null,
        eventMenu: 'Westa Networking',
      },
      render: (params, { authState }: RouteAuth) => (
        <SessionAddPage
        />
      ),
    },

  ];
}


