// @flow
import gql from 'graphql-tag';
import type { SubscriptionListener } from '../../core/data/apolloClient';
import {
  apolloQuery,
  apolloSubmitForm,
  apolloSubscribe,
  getSubscriptionAuthorization,
} from '../../core/data/apolloClient';


export function getSession(id: string, shape: string) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getSession(id: $id) ${shape}
      }
    `,
    { id },
  );
}

export function listSessions(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: SessionsFilter) {
        listSessions(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function updateSession(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: SessionsFilter!) {
        updateSession(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function createSession(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: SessionCreate!) {
        createSession(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function getLiveSessions(shape, listener: SubscriptionListener): () => void {
  return apolloSubscribe(
    gql`
      subscription ($authorization: String!) {
        getLiveSessions (authorization: $authorization) {
          reset ${shape}
          update ${shape}
        }
      }
    `,
    { authorization: getSubscriptionAuthorization() },
    listener,
  );
}
