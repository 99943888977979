// @flow
import { AnyAction } from "redux";
import ErrorCapsule from "../../core/exceptions/ErrorCapsule";
import type { Call } from '../model/Call.model';
import { getLiveCalls } from '../model/Call.client';

export type CallState = {
  calls: null | Call | ErrorCapsule,
};

const initialState: CallState = {
  calls: null,
};

const callShape = `{
    id
    sessionId
    pairId
    usersNo
    status
  }`;


const ACTION_SET_CALL = 'calls/callX/ACTION_SET_CALL';

function setCalls(calls: null | Array<Call> | ErrorCapsule) {
  return {
    type: ACTION_SET_CALL,
    calls,
  };
}

export function callXReducer(
  state: CallState = initialState,
  action: AnyAction,
) {
  switch (action.type) {
    case ACTION_SET_CALL:
      return {
        ...state,
        calls: action.calls,
      };
    default:
      return state;
  }
}


export function startTrackingNewCalls(dispatch, sessionId): () => void {
  return getLiveCalls(sessionId, callShape, (packet) => {
    dispatch([setCalls(packet.reset)]);
  });
}
