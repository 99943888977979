// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';
import {
  getUser,
  updateUser,
  filterUserInput,
} from '../users/model/User.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import FirstVisitPageView from './views/FirstVisitPageView';
import type { User } from '../users/model/User.model';

type FirstVisitState = {
  user: null | User | ErrorCapsule,
  redirectTo: ?string,
}

const userShape = `{
  id
  email
  firstName
  lastName
  avatar {
    id
    cover(width: 320, height: 335) { width height url }
  }
  isOrganizer
  isAdmin
  position
  companyTitle
  companyId
  company {
    id
    title
  }
  interests
  lookingFor
  publicContacts {
    id
    userId
    kind
    title
    content
  }
}`;

const initialState: FirstVisitState = {
  user: null,
  redirectTo: null,
};

const ACTION_SET_USER = 'onboarding/firstVisit/ACTION_SET_USER';
const ACTION_SET_REDIRECT_TO = 'onboarding/firstVisit/ACTION_SET_REDIRECT_TO';

function setUser(user: null | User | ErrorCapsule) {
  return {
    type: ACTION_SET_USER,
    user,
  };
}

export function setRedirectTo(redirectTo) {
  return {
    type: ACTION_SET_REDIRECT_TO,
    redirectTo,
  };
}

async function loadUser(id: string) {
  try {
    const user = await getUser(id, userShape);

    return setUser(user);
  } catch (err) {
    return setUser(new ErrorCapsule(err, () => [
      setUser(initialState.user),
      loadUser(id),
    ]));
  }
}

function getNextUrl(currentMode, redirectTo) {
  switch (currentMode) {
    case 'base': return '/onboarding/job/';
    case 'job': return '/onboarding/info/';
    case 'info': return redirectTo || '/';
    default: return '/onboarding/';
  }
}

async function saveUser(values, currentMode, redirectTo) {
  const enrichedValues = currentMode === 'info' ? { ...values, isOnboarded: true } : values;
  const user = await updateUser(filterUserInput(enrichedValues), userShape);

  return [
    setUser(user),
    jumpNext(currentMode, redirectTo),
  ];
}

async function uploadAvatar(userId, file) {
  const user = await updateUser({ id: userId, avatar: file }, userShape);
  return setUser(user);
}

function jumpNext(currentMode, redirectTo) {
  return redirect(getNextUrl(currentMode, redirectTo));
}

export function firstVisitReducer(
  state: FirstVisitState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case ACTION_SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.redirectTo,
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
  mode: string,
}

function FirstVisitPage({
  id,
  mode,
}: Props) {
  const dispatch = useDispatch();
  const {
    user,
    redirectTo,
  }: FirstVisitState = useSelector(({ onboarding }) => onboarding.firstVisit);

  // Load User
  useEffect(() => {
    dispatch([
      setUser(null),
      loadUser(id),
    ]);
    return () => dispatch(setUser(null));
  }, [id]);

  // Render
  return (
    <FirstVisitPageView
      user={user}
      mode={mode}
      onSkip={() => dispatch(jumpNext(mode, redirectTo))}
      onSubmit={(values: User) => dispatch(saveUser(values, mode, redirectTo))}
      onAvatarUpload={file => dispatch(uploadAvatar(id, file))}
    />
  );
}

export function firstVisitPageRoutes(): Routes {
  return [
    {
      title: t => t('First Visit'),
      path: [
        '/',
        '/:mode(info|job)/',
      ],
      isEnabled: ({ isUser }) => isUser,
      render: (params, auth: RouteAuth) => (
        <FirstVisitPage
          {...sanitizeRouteParams(params, {
            mode: '?string',
          }, {
            mode: 'base',
          })}
          id={auth.authState.user.id}
        />
      ),
      design: ({ mode = 'base' }) => {
        switch (mode) {
          case 'base': return require('./views/FirstVisitPageView.design.base@mobile.png'); // eslint-disable-line global-require
          case 'info': return require('./views/FirstVisitPageView.design.info@mobile.png'); // eslint-disable-line global-require
          case 'job': return require('./views/FirstVisitPageView.design.job@mobile.png'); // eslint-disable-line global-require
          default: return null;
        }
      },
    },
  ];
}
