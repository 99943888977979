// @flow
import React from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import _trim from 'lodash/trim';
import { supportMocking } from 'metaup/dev/supportMocking';
import SendMessageWidgetView from './views/SendMessageWidgetView';

type SendMessageState = {
  text: string,
}

const initialState: SendMessageState = {
  text: '',
};

const ACTION_SET_TEXT = 'messenger/sendMessage/ACTION_SET_TEXT';

function setText(text: string) {
  return {
    type: ACTION_SET_TEXT,
    text,
  };
}

export function sendMessageReducer(
  state: SendMessageState = initialState,
  action: AnyAction,
) {
  switch (action.type) {

    case ACTION_SET_TEXT:
      return {
        ...state,
        text: action.text,
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
  onSend: string => void,
}

function SendMessageWidget({
  id,
  onSend,
}: Props) {
  const dispatch = useDispatch();
  const {
    text,
  }: SendMessageState = useSelector(({ messenger }) => messenger.sendMessage);

  return (
    <SendMessageWidgetView
      text={text}
      onSubmit={textToSend => {
        const cleanText = _trim(textToSend);
        if (cleanText) {
          // Reset
          dispatch(setText(''));

          // Emit
          onSend(cleanText);
        }
      }}
      onChange={newText => dispatch(setText(newText))}
    />
  );
}

export default supportMocking(SendMessageWidget, SendMessageWidgetView);
