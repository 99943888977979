// @flow
import React, { useEffect } from "react";
import _orderBy from "lodash/orderBy";
import { AnyAction } from "redux";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import type { Routes } from "metaup/routing/routingUtils";
import { sanitizeRouteParams } from "metaup/routing/routingUtils";
import { listChats } from "./model/Chat.client";
import ErrorCapsule from "../core/exceptions/ErrorCapsule";

import ChatsListPageView from "./views/ChatsListPageView";
import type { Chat } from "./model/Chat.model";
import type { Event } from "../events/model/Event.model";
import { getUnreadByChat } from "./redux/Chat.redux";
import { constantEmptyObject } from "../core/utils";
import { listPollVotes } from '../polls/model/PollVote.client';
import { isPublished } from '../polls/utils';

type ChatsListState = {
  chats: null | Array<Chat> | ErrorCapsule,
  poll: null | Array<Poll> | ErrorCapsule,
};

const chatShape = `{
  id
  event {
    id
    title
    cover {
      id
      cover(width: 48, height: 60) { width height url }
    }
    start
    startTime
    end
    endTime
    organizerId
  }
  partner {
    id
    firstName
    lastName
    avatar {
      id
      cover(width: 48, height: 60) { width height url }
    }
  }
  lastMessage {
    id
    text
    sent
  }
}`;

const voteShape = `{
  id
  choiceId
  questionId
  userId
}`;

const initialState: ChatsListState = {
  chats: null,
};

const ACTION_SET_CHATS = "messenger/chatsList/ACTION_SET_CHATS";

function setChats(chats: null | Array<Chat> | ErrorCapsule) {
  return {
    type: ACTION_SET_CHATS,
    chats,
  };
}

function isEventGoing(now: string, { start, startTime, end, endTime }: Event) {
  const since = start + " " + startTime;
  const till =
    end && endTime
      ? end + " " + endTime
      : moment.utc(since).add(2, "hours").format("YYYY-MM-DD HH:mm:ss");
  return since <= now && now <= till;
}

export function isVoted(questions, userVotes) {
  let j;
  // eslint-disable-next-line no-restricted-syntax
  for (j = 0; j < questions.length; j++) {
    let i;
    for (i = 0; i < userVotes.length; i++) {
      if (
        userVotes[i].questionId === questions[j].id
      ) {
        return true;
      }
    }
  }
  return false;
};

async function loadChats(filter?: ?{ [string]: string }, currentUserId, poll) {
  try {
    let rawChats = await listChats(filter, chatShape);
    rawChats = await Promise.all(
      rawChats.map(async (chat) => {
        if (chat.event) {
          const tmpPolls = await getPollLocal(chat.event.id, poll);

          const userVotes = await listPollVotes({ userId: currentUserId }, voteShape);

          let unVotedPolls = tmpPolls.filter(
            (value) => {
              const temp = isVoted(value.questions, userVotes);
              return !temp;
            },
          );

          // filter out scheduled poles
          const scheduledPolls = unVotedPolls.filter(
            (value) =>
              value.questions.length !== 0 &&
              isPublished(value.publishAt),
          );

          unVotedPolls = unVotedPolls.filter(
            (value) =>
              value.questions.length !== 0 &&
              isPublished(value.publishAt),
          );

          if (
            currentUserId === chat.event.organizerId ||
            currentUserId === "admin" ||
            unVotedPolls.length
          ) {
            return { ...chat, polls: unVotedPolls.length };
          } else if ((unVotedPolls.length <= 0 && scheduledPolls.length > 0) || (tmpPolls.length > 0 && unVotedPolls.length <= 0)) {
            return { ...chat, polls: -1 };
          } else {
            return chat;
          }
        } else {
          return chat;
        }
      }),
    );

    // Sort by current event and last message
    const now = moment.utc().format("YYYY-MM-DD HH:mm:ss");
    const chats = _orderBy(
      rawChats,
      [
        ({ lastMessage, event }) =>
          (event && isEventGoing(now, event) ? "1" : "0") +
          "-" +
          (lastMessage ? lastMessage.sent : "0"),
      ],
      ["desc"],
    );

    return [setChats(chats)];
  } catch (err) {
    return setChats(
      new ErrorCapsule(err, () => [
        setChats(initialState.chats),
        loadChats(filter),
      ]),
    );
  }
}

async function getPollLocal(eventId, poll) {
  if (poll) {
    return Promise.all(
      poll.filter((p) => {
        return p.eventId === eventId;
      }),
    );
  } else {
    return [];
  }
}

export function chatsListReducer(
  state: ChatsListState = initialState,
  action: AnyAction,
) {
  switch (action.type) {
    case ACTION_SET_CHATS:
      return {
        ...state,
        chats: action.chats,
      };

    default:
      return state;
  }
}


type Props = {};

function ChatsListPage({}: Props) {
  const dispatch = useDispatch();
  const { chats }: ChatsListState = useSelector(
    ({ messenger }) => messenger.chatsList,
  );
  // eslint-disable-next-line no-shadow
  const { polls }: PollState = useSelector(({ polls }) => polls.pollX);
  const unread = useSelector(getUnreadByChat) || constantEmptyObject;
  const currentUserId = useSelector(({ auth }) => auth.state.id);
  // Load Chats
  useEffect(() => {
    dispatch([
      setChats(null),
      loadChats(/* { field: mode } */ null, currentUserId, polls),
    ]);
    return () => dispatch(setChats(null));

  }, [polls]);

  // Render
  return <ChatsListPageView chats={chats} unread={unread}/>;
}

export function chatsListPageRoutes(): Routes {
  return [
    {
      title: (t) => t("Chats"),
      path: "/",
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        onBack: history => history.goBack(),
        showTitle: true,

      },
      render: (params) => (
        <ChatsListPage
          {...sanitizeRouteParams(params, {
            id: "id",
          })}
        />
      ),
      design: require("./views/ChatsListPageView.design.mobile.png"), // eslint-disable-line global-require
    },
  ];
}
