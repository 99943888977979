// @flow
import {
  createStore, combineReducers, applyMiddleware, compose, Dispatch, AnyAction,
} from 'redux';
import reduxCatch from 'redux-catch';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';
import _extend from 'lodash/extend';

function exposeErrorHandler(error, getState, lastAction, dispatch) {
  /* eslint-disable no-console */
  console.error(error);
  console.debug('current state', getState());
  console.debug('last action was', lastAction);
  /* eslint-enable no-console */
}

export type GQLDate = string;

export type ComponentState = Object;
export type ModuleState = ComponentState | { [string]: ComponentState };
export type AppState = { [string]: ModuleState };

export type GetState = () => AppState;
type ThunkAction = (dispatch: Dispatch, getState: GetState) => void;
export type AsyncAction = AnyAction | ThunkAction | Promise<AsyncAction> | Array<AsyncAction>;

export type Reducer = (state?: ModuleState, action: AnyAction) => ModuleState;
export type ReduxModule = {
  namespace: string,
  initialState: ModuleState,
  reducer: Reducer,
  init?: ThunkAction,
}

export type StateListenerParams = { dispatch: Dispatch };
export interface StateListener {
  constructor(StateListenerParams): void;
  setState(value: AppState): void;
}

// Return this from connected components, when a condition ends up with no action
export const noAction: AnyAction = {
  type: '',
};

export function createReduxStore(
  preloadedState: ?AppState,
  libraryReducers: { [string]: Reducer },
  reduxModules: Array<ReduxModule>,
  listenerClasses: Array<?Class<StateListener>>
) {

  function mapOurModules(mapper) {
    const result = {};
    reduxModules.forEach(module => {
      result[module.namespace] = mapper(module);
    });
    return result;
  }

  const onActionListeners = [];
  const onActionMiddleware = store => next => action => {
    // Handle array type of action
    if (Array.isArray(action)) {
      action.forEach(actionItem => dispatch(actionItem));
      return;
    }

    // Pass before onAction
    next(action);

    // Handle, when passed
    onActionListeners.forEach(onAction => onAction(action, dispatch, getState));
  };

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    combineReducers({
      ...libraryReducers,
      ...mapOurModules(({ reducer }) => reducer),
    }),
    preloadedState
      ? mapOurModules(
        ({ initialState, namespace }) => _extend({}, initialState, preloadedState[namespace])
      )
      : undefined,
    composeEnhancers(
      applyMiddleware(
        reduxCatch(exposeErrorHandler),
        promiseMiddleware,
        thunkMiddleware,
        onActionMiddleware,
      ),
    ),
  );

  const dispatch = store.dispatch.bind(store);
  const getState = store.getState.bind(store);

  reduxModules.forEach(({ onAction, init }) => {
    if (onAction) {
      onActionListeners.push(onAction);
    }
    if (init) {
      // Support thunk actions signature for init
      init(dispatch, getState);
    }
  });

  listenerClasses.forEach(Cls => {
    if (Cls /* can be null to support ternary operator at level above */) {
      const instance = new Cls({ dispatch });
      store.subscribe(() => instance.setState(store.getState()));

      // Trigger manually, if the state is preloaded
      if (preloadedState) {
        instance.setState(store.getState());
      }
    }
  });

  return store;
}

/**
 * Wrapper for thunk actions that expect full state and emit actions
 */
export function stateBasedAction(fn: (state: AppState) => AsyncAction): ThunkAction {
  // Extract state by redux-thunk
  return (dispatch, getState) => dispatch(fn(getState()));
}
