// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';
import { Event } from './model/Event.model';
import {
  getEvent,
  createEvent,
  updateEvent,
  filterEventInput,
} from './model/Event.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import EventEditPageView from './views/EventEditPageView';

type EventEditState = {
  event: null | Event | ErrorCapsule,
}

const eventShape = `{
  id
  title
  start
  startTime
  end
  endTime
  cover {
    id
    cover(width: 100, height: 100) { width height url }
  }
  description
  organizerId
  organizer {
    id
    title
  }
  address {
    title
    address
    mapPoint
    notes
  }
}`;

const initialState: EventEditState = {
  event: null,
};

const ACTION_SET_EVENT = 'events/eventEdit/ACTION_SET_EVENT';

function setEvent(event: null | Event | ErrorCapsule) {
  return {
    type: ACTION_SET_EVENT,
    event,
  };
}

async function loadEvent(id: string) {
  try {
    const event = await getEvent(id, eventShape);

    return setEvent(event);
  } catch (err) {
    return setEvent(new ErrorCapsule(err, () => [
      setEvent(initialState.event),
      loadEvent(id),
    ]));
  }
}

export async function saveEvent(values) {
  const event = !values.id
    ? await createEvent(filterEventInput(values), eventShape)
    : await updateEvent(filterEventInput(values), eventShape);

  return [
    setEvent(event),
    redirect('/events/' + event.id + '/'),
  ];
}

export function eventEditReducer(
  state: EventEditState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_EVENT:
      return {
        ...state,
        event: action.event,
      };

    default:
      return state;
  }
}

type Props = {
  id?: string,
}

function EventEditPage({
  id,
}: Props) {
  const dispatch = useDispatch();
  const {
    event,
  }: EventEditState = useSelector(({ events }) => events.eventEdit);

  // Load Event
  useEffect(() => {
    dispatch(id
      ? [
        setEvent(null),
        loadEvent(id),
      ]
      : setEvent({}));
    return () => dispatch(setEvent(null));
  }, [id]);

  // Render
  return (
    <EventEditPageView
      event={event}
      onSubmit={(values: Event) => dispatch(saveEvent(values))}
    />
  );
}

export function eventEditPageRoutes(): Routes {
  return [
    {
      title: 'Add Event',
      path: '/edit/new/',
      isEnabled: ({ authState }: RouteAuth) => authState.user && authState.user.user.isAdmin,
      render: params => (
        <EventEditPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Edit Event',
      path: '/edit/:id/',
      isEnabled: ({ authState }: RouteAuth) => authState.user && authState.user.user.isAdmin,
      render: params => (
        <EventEditPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
