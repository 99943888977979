// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { firstVisitPageRoutes } from './FirstVisitPage';
// ### GENERATED IMPORTS GO HERE ###

export function onboardingRoutes() {
  return prefixRoutes('/onboarding/', [
    firstVisitPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
