// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';

export function homeRoutes(): Routes {
  return [
    {
      title: 'Home',
      path: '/',
      render: (params, { isGuest }: RouteAuth) => (
        <Redirect to="/events/" />
      ),
    },
  ];
}
