// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type Error404PageViewProps = {
}

export default function Error404PageView({
}: Error404PageViewProps) {
  const { t } = useTranslation();

  return (
    <div className="error-404">
      <h1>{t('Error 404')}</h1>
    </div>
  );
}
