// @flow
import React from 'react';
import LoadFailed from './LoadFailed';
import type { LoadFailedProps } from './LoadFailed';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import Error404Page from '../statics/Error404Page';

export default function PageLoadFailed(props: LoadFailedProps) {
  const { error } = props;
  const e = error instanceof ErrorCapsule ? error.error : error;

  if (e.graphQLErrors && e.graphQLErrors[0]) {
    const gqlErr = e.graphQLErrors[0];
    if (gqlErr.extensions && gqlErr.extensions.code === 'NOT_FOUND') {
      return <Error404Page />;
    }
  }

  return <LoadFailed {...props} />;
}
