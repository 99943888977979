// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';

import { pollPageRoutes } from './PollPage';
import { pollAddPageRoutes } from './PollAddPage';
import { pollFullPageRoutes } from './PollFullPage';

// ### GENERATED IMPORTS GO HERE ###

export function pollsRoutes() {
  return prefixRoutes('/polls/', [

    pollPageRoutes(),
    pollAddPageRoutes(),
    pollFullPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
