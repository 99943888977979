// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { userContactMetaModel } from './UserContact.model';

export function filterUserContactInput(values) {
  return _pickBy(values, (_, key) => key in userContactMetaModel.rules || key === 'id');
}

export function listUserContacts(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: UserContactsFilter) {
        listUserContacts(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function getUserContact(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getUserContact(id: $id) ${shape}
      }
    `,
    { id },
  );
}

export function createUserContact(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: UserContactCreate!) {
        createUserContact(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function updateUserContact(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: UserContactUpdate!) {
        updateUserContact(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}
