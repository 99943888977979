// @flow
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { renderImageMeta } from 'metaup/render/visualUtils';
import type { Event } from '../model/Event.model';
import { formatEventStart } from '../../core/render/customVisualUtils';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { icons } from '../../elements/icons';
import LayoutContext from '../../layout/LayoutContext';

type JoinButtonsProps = {
  eventId: string,
  onClose: () => void,
}

export function JoinButtons({ eventId, onClose }: JoinButtonsProps) {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('To see the event information you should be logged in')}</p>
      <Link
        to={`/join/${eventId}/login/`}
        className="btn btn-default btn-block"
        onClick={e => onClose()}
      >
        {t('Login')}
      </Link>
      <Link
        to="/events/"
        className="btn btn-default btn-block"
        onClick={e => onClose()}
      >
        {t('Back to events list')}
      </Link>
    </>
  );
}

export type EventPageViewProps = {
  event: null | Event | ErrorCapsule,
  onJoin: () => void,
  guestMode: boolean,
}

export default function EventPageView({
  event,
  onJoin,
  guestMode,
}: EventPageViewProps) {
  const { t } = useTranslation();
  const { pageBodyHeight } = useContext(LayoutContext);

  if (!event) return <Loader />;
  if (event instanceof ErrorCapsule) return <PageLoadFailed error={event} />;

  const {
    title,
    description,
    start,
    startTime,
    cover,
    currentUserRelation,
  } = event;

  return (
    <div
      className="event layout__revert-bottom-padding d-flex flex-column"
      style={{ height: pageBodyHeight + 'px' }}
    >
      <div className="layout__nested-body">
        <div className="page-cover">
          {cover ? renderImageMeta(cover.cover, t('Event picture')) : null}
        </div>
        <div className="event__title">{title}</div>
        <div className="event__date">
          <span className="event__date-icon">{icons.calendar}</span>
          <span className="event__date-start">{formatEventStart(t, start, startTime)}</span>
        </div>
        {description && <div className="event__description">{description}</div>}
      </div>
      {(!currentUserRelation && !guestMode) && (
        <div className="layout__bottom-button-place">
          <button
            type="button"
            className="btn btn-default btn-block"
            onClick={e => onJoin()}
          >
            {t('Join this event')}
          </button>
        </div>
      )}
    </div>
  );
}
