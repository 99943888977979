// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { required, email } from 'redux-form-validators';
import { Trans, useTranslation } from 'react-i18next';

import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
} from '../../elements/DefaultForm';
import type { MetaModel } from '../../core/data/rules';
import { string } from '../../core/data/rules';

export type LoginPageViewProps = {
  urlPrefix?: string,
  onSubmit: (values: { [string]: any }) => void,
}

export const authLoginMetaModel: MetaModel = {
  rules: {
    email: [string(), required(), email()],
    password: [string(), required()],
  },
};

export default function LoginPageView({
  urlPrefix = '',
  onSubmit,
}: LoginPageViewProps) {
  const { t } = useTranslation();

  return (
    <div className="login">
      <div className="login__logo">
        <img src="/img/layout/logo.svg" width="160" height="172" alt="Logo" />
        <div className="login__logo-text">Westa</div>
      </div>
      <p className="login__text login__text_waccont">{t('Sign in using your Westa account')}</p>
      <DefaultForm
        form="login"
        metaModel={authLoginMetaModel}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormText attribute="email" type="email" autoFocus />
        <DefaultFormText attribute="password" type="password" />
        <DefaultFormSubmit label={t('Sign In')} />
      </DefaultForm>
      <p className="login__text login__text_tail">
        <Trans
          defaults={'Don\'t have an account?<0 />– <1>register</1>'}
          components={[
            <br />,
            <Link to={urlPrefix + '/register/'} />,
          ]}
        />
      </p>
    </div>
  );
}
