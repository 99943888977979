// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bemCompose, renderImageMeta } from 'metaup/render/visualUtils';
import { formatSent } from '../../core/render/customVisualUtils';
import type { Chat } from '../model/Chat.model';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { icons } from '../../elements/icons';

export type ChatsListPageViewProps = {
  chats: null | Array<Chat> | ErrorCapsule,
  unread: { [string]: number },
};

export default function ChatsListPageView({
  chats,
  unread,
}: ChatsListPageViewProps) {
  const { t } = useTranslation();

  if (!chats) return <Loader/>;
  if (chats instanceof ErrorCapsule) return <PageLoadFailed error={chats}/>;

  return (
    <div className="chats-list">
      {chats.map(
        ({
          id, event, title, partner, lastMessage, __typename, polls,
        }) => (
          <div className="chats-list__chatContainer" key={id}>
            <Link
              to={
                __typename === "Poll"
                  ? `/polls/${event.id}/`
                  : event
                  ? `/messenger/event/${event.id}/`
                  : `/messenger/to/${partner.id}/`
              }
              key={id}
              className="chats-list__chat d-flex"
            >
              <span className="chats-list__avatar">
                {event
                  ? event.cover
                    ? renderImageMeta(event.cover.cover, "","layout__obj-fit")
                    : null
                  : partner.avatar
                    ? renderImageMeta(partner.avatar.cover, "","layout__obj-fit")
                    : null}
                {unread[id] ? (
                  <span className="chats-list__unread">{unread[id]}</span>
                ) : null}
              </span>
              <span className="chats-list__body">
                <span className="d-flex justify-content-between">
                <span className="chats-list__name">
                  {event
                    ? __typename === "Poll"
                      ? title
                      : event.title
                    : `${partner.firstName} ${partner.lastName}`}
                </span>
              <span
                className="chats-list__sent">{lastMessage && formatSent(t, lastMessage.sent)}</span>
            </span>
            <span className="d-flex justify-content-between">
              <span className="chats-list__message">
                {lastMessage && lastMessage.text}
              </span>
              {unread[id] ? <span className="chats-list__unread">{unread[id]}</span> :
                <span className="chats-list__unread-margin"/>}
            </span>
          </span>
            </Link>
            <div className="d-flex">
              {polls !== undefined || polls === -1 ? (
                <Link
                  to={`/polls/${event.id}/`}
                  className="chats-list__pollButton"
                >
                  {icons.poll}
                  <div
                    className={bemCompose("chats-list", "pollButtonText", { 'circle': polls > 0 })}>
                    {polls !== -1 ? polls : null}
                  </div>
                </Link>
              ) : null}
              {/* {polls !== undefined ? ( */}
              {/*  <div */}
              {/*    className="chats-list__pollButton" */}
              {/*  > */}
              {/*    {icons.messages} */}
              {/*    <div className="chats-list__pollButtonText">0</div> */}
              {/*  </div> */}
              {/* ) : null} */}
            </div>
          </div>
        ))}

    </div>
  );
}
