// @flow
import { AnyAction } from 'redux';

type RetryFn = () => ?AnyAction;

export default class ErrorCapsule {
  +error: Error;

  +retry: ?RetryFn;

  constructor(error: Error, retry?: RetryFn) {
    this.error = error;
    this.retry = retry || null;
  }
}
