// @flow
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { FormProps } from '../core/forms/Form';
import Form from '../core/forms/Form';
import FormGlobalValidation from '../core/forms/FormGlobalValidation';
import type { FieldProps, FieldWrapperProps } from '../core/forms/Field';
import Field, { FieldArray, getBootstrapValidationClassName } from '../core/forms/Field';
// eslint-disable-next-line import/no-cycle
import PollAddQuestionView from '../polls/views/PollAddQuestionView';
// eslint-disable-next-line import/no-cycle
import PollAddChoiceView from '../polls/views/PollAddChoiceView';

export function DefaultForm(props: FormProps) {
  return <Form {...props} />;
}

export function DefaultFormGlobalValidation() {
  return (
    <FormGlobalValidation
      render={({ error, warning }) =>
        error || warning ? (
          <div className="layout__alert layout__alert_error" role="alert">
            {error && <p>{error}</p>}
            {warning && <p>{warning}</p>}
          </div>
        ) : null
      }
    />
  );
}

function WrapperComponent(props: FieldWrapperProps) {
  const {
    input: { name, value },
    label,
    hint,
    meta: { form, touched, error, warning },
    renderControl,
  } = props;
  const id = form + '-' + name;
  const hintId = hint ? id + '-help' : null;
  return (
    <div className="form-group">
      {renderControl({ ...props, id, hintId })}

      {label && (
        <label
          htmlFor={id}
          className={`form-label ${value ? 'form-label_is-filled' : ''}`}
        >
          {label}
        </label>
      )}

      {touched &&
      ((error && <div className="invalid-feedback">{error}</div>) ||
        (warning && <div className="warning-feedback">{warning}</div>))}
      {hint && (
        <div id={hintId} className="form-text text-muted small">
          {hint}
        </div>
      )}
    </div>
  );
}

function WrapperComponent2(props: FieldWrapperProps) {
  const {
    input: { name, value },
    label,
    hint,
    meta: { form, touched, error, warning },
    renderControl,
    stretched,
  } = props;
  const id = form + '-' + name;
  return (
    <div
      className={
        stretched
          ? 'form-group default-form__form mb-1 default-form__stretch'
          : 'form-group default-form__form mb-1 '
      }
    >
      {label && (
        <label
          htmlFor={id}
          className={`${value ? 'form-label_is-filled' : ''}`}
        >
          {label}
        </label>
      )}
      {renderControl({ ...props, id, hint })}
      {touched &&
      ((error && <div className="invalid-feedback">{error}</div>) ||
        (warning && <div className="warning-feedback">{warning}</div>))}
    </div>
  );
}


export function DefaultFormFieldWrapper(props: FieldWrapperProps) {
  return <Field {...props} component={WrapperComponent}/>;
}

export function DefaultFormFieldWrapper2(props: FieldWrapperProps) {
  return <Field {...props} component={WrapperComponent2}/>;
}

export function DefaultFormFieldArray(props: FieldWrapperProps) {
  return <FieldArray {...props} component={PollAddChoiceView}/>;
}

export function DefaultFormFieldArray2(props: FieldWrapperProps) {
  return <FieldArray {...props} component={PollAddQuestionView}/>;
}

export type TextFieldProps = FieldProps & {
  type?: string,
  styleClass?: string,
};

export function DefaultFormText(props: TextFieldProps) {
  return (
    <DefaultFormFieldWrapper
      {...props}
      renderControl={({
        input,
        id,
        hintId,
        meta: { touched, error, warning },
      }) => (
        <input
          {...input}
          autoFocus={props.autoFocus}
          type={props.type || 'text'}
          className={classNames(
            'form-control',
            { 'form-control_is-filled': input.value },
            getBootstrapValidationClassName(touched, error, warning),
          )}
          id={id}
          aria-describedby={hintId}
        />
      )}
    />
  );
}

export function DefaultFormText2(props: TextFieldProps) {

  return (
    <DefaultFormFieldWrapper2
      {...props}
      renderControl={({
        input,
        input: { value },
        id,
        hint,
        meta: { touched, error, warning },
      }) => (
        <input
          {...input}
          autoFocus={props.autoFocus}
          type={props.type || 'text'}
          className={classNames(
            'form-control',
            { 'form-control_is-filled': input.value },
            getBootstrapValidationClassName(touched, error, warning),
          )}
          id={id}
          placeholder={hint}
          value={typeof value === 'object' ? null : value}
          maxLength={200}
        />
      )}
    />
  );
}

export function DefaultFormArrayField(props: TextFieldProps) {
  return <DefaultFormFieldArray {...props} />;
}

export function DefaultFormArrayField2(props: TextFieldProps) {
  return <DefaultFormFieldArray2 {...props} />;
}

export function DefaultFormSelect(props: TextFieldProps) {
  return (
    <DefaultFormFieldWrapper2
      {...props}
      renderControl={({
        input,
        id,
        hint,
        meta: { touched, error, warning },
      }) => (
        <select
          {...input}
          className={classNames(
            'form-control',
            { 'form-control_is-filled': input.value },
            getBootstrapValidationClassName(touched, error, warning),
          )}
          id={id}
          disabled={props.disabled}
        >
          {props.options.map((option) => (
            <option
              value={option.value}
              key={option.text}
            >
              {option.text}
            </option>
          ))}
        </select>
      )}
    />
  );
}

export function DefaultFormRadio(props: TextFieldProps) {
  return (
    <DefaultFormFieldWrapper2
      {...props}
      renderControl={({
        input,
        id,
        hint,
        meta: { touched, error, warning },
      }) => (
        <input
          {...input}
          autoFocus={props.autoFocus}
          type={props.type || 'radio'}
          className={
            classNames(
              'form-control',
              { 'form-control_is-filled': input.value },
              getBootstrapValidationClassName(touched, error, warning),
            ) +
            ' ' +
            props.styleClass
              ? props.styleClass
              : null
          }
          value={props.value}
          id={id}
          checked={props.checked}
          onClick={() => {
            if (props.value === "Later") {
              props.setPublishAt(true);
            } else if (props.value === "Now") {
              props.setPublishAt(false);
            }
          }}
        />
      )}
    />
  );
}

export function DefaultFormTextArea(props: FieldProps) {
  return (
    <DefaultFormFieldWrapper
      {...props}
      renderControl={({
        input,
        id,
        hintId,
        meta: { touched, error, warning },
      }) => (
        <textarea
          {...input}
          autoFocus={props.autoFocus}
          className={classNames(
            'form-control',
            { 'form-control_is-filled': input.value },
            getBootstrapValidationClassName(touched, error, warning),
          )}
          id={id}
          aria-describedby={hintId}
        />
      )}
    />
  );
}

export function DefaultFormDateTime(props: TextFieldProps) {
  const {
    value,
  } = props;
  const [dateTime, setDateTime] = useState(value);
  return (
    <DefaultFormFieldWrapper2
      {...props}
      renderControl={({
        input,
        input: { valuee },
        id,
        hint,
        meta: { touched, error, warning },
      }) => (
        <input
          {...input}
          autoFocus={props.autoFocus}
          type={props.type || 'datetime-local'}
          className={classNames(
            'form-control',
            { 'form-control_is-filled': input.value },
            getBootstrapValidationClassName(touched, error, warning),
          )}
          id={id}
          placeholder={hint}
          min={props.min}
          max={props.max}
          value={dateTime}
          onChange={(newValue) => {
            setDateTime(newValue.target.value);
            props.onChange(newValue.target.value);
          }}
        />
      )}
    />
  );
}

/** @see https://github.com/erikras/redux-form/issues/3686#issuecomment-353116360 */
function handleFileChange(handler, transformer) {
  return ({ target: { files } }) => handler(transformer(files));
}

function transformSingleFile(files) {
  return files.length > 0 ? files[0] : null;
}

function transformMultipleFiles(files) {
  return files[0];
}

function fileNameOrCta(value, choseFileText) {
  if (!value) return choseFileText;
  if (Array.isArray(value)) return value.map((file) => file.name).join(', ');
  return value.name;
}

type FileProps = FieldProps & {|
  accept?: string,
  multiple?: boolean,
|};

export function DefaultFormFile({ accept, multiple, ...props }: FileProps) {
  const { t } = useTranslation(); // Not the best example of hooks usage

  const transformer = multiple ? transformMultipleFiles : transformSingleFile;
  return (
    <DefaultFormFieldWrapper
      type="file"
      {...props}
      renderControl={({
        input: { onChange, onBlur, value, ...input },
        type,
        id,
        hintId,
        meta: { touched, error, warning },
      }) => (
        <div className="custom-file">
          <input
            onChange={handleFileChange(onChange, transformer)}
            onBlur={handleFileChange(onBlur, transformer)}
            {...input}
            accept={accept}
            multiple={multiple}
            autoFocus={props.autoFocus}
            type={type}
            className="custom-file-input"
            id={id}
            aria-describedby={hintId}
          />
          <label // eslint-disable-line jsx-a11y/label-has-associated-control
            htmlFor={id}
            className={classNames(
              'custom-file-label',
              getBootstrapValidationClassName(touched, error, warning),
            )}
          >
            {fileNameOrCta(value, t('Choose file'))}
          </label>
        </div>
      )}
    />
  );
}

export function DefaultFormCheckBox(props: FieldProps) {
  return (
    <Field
      type="checkbox"
      {...props}
      component={(params) => {
        const {
          input,
          label,
          type,
          hint,
          meta: { form, touched, error, warning },
        } = params;
        const id = form + '-' + input.name;
        const hintId = hint ? id + '-help' : null;
        return (
          <div className="form-group form-check">
            <input
              {...input}
              type={type}
              autoFocus={props.autoFocus}
              className={classNames(
                'form-check-input',
                getBootstrapValidationClassName(touched, error, warning),
              )}
              id={id}
              aria-describedby={hintId}
            />
            <label className="form-check-label" htmlFor={id}>
              {label}
            </label>
            {touched &&
            ((error && <div className="invalid-feedback">{error}</div>) ||
              (warning && <div className="warning-feedback">{warning}</div>))}
            {hint && (
              <div id={hintId} className="form-text text-muted small">
                {hint}
              </div>
            )}
          </div>
        );
      }}
    />
  );
}

type SubmitProps = {
  label: string,
};
type SubmitProps2 = {
  label: string,
  styleClass?: string,
};

export function DefaultFormSubmit({ label }: SubmitProps) {
  return (
    <button type="submit" className="btn btn-default btn-block">
      {label}
    </button>
  );
}

export function DefaultFormSubmit2({ label, styleClass }: SubmitProps2) {
  return (
    <button type="submit" className={styleClass ? `btn ${styleClass}` : 'btn'}>
      {label}
    </button>
  );
}
