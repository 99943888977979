// @flow
import { combineReducers, Dispatch } from 'redux';
import { authStateReducer, authStateInit } from './authState';
import type { GetState } from '../core/data/reduxStore';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'auth';

export const reducer = combineReducers({
  state: authStateReducer,
  // ### GENERATED REDUCERS GO HERE ###
});

export function init(dispatch: Dispatch, getState: GetState): void {
  authStateInit(dispatch, getState);
}
