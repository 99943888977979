// @flow
import React, { useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import type { Routes } from 'metaup/routing/routingUtils';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import PollAddPageView from './views/PollAddPageView';
import type { Event } from '../events/model/Event.model';
import { getEvent } from '../events/model/Event.client';
import { redirect } from '../core/data/router.redux';
import Loader from '../elements/Loader';
import PageLoadFailed from '../elements/PageLoadFailed';
import { createNewPoll, editWholePoll } from './utils';

type PollAddState = {
  event: null | Event | ErrorCapsule,
};

const eventShape = `{
  id
  title
  organizerId
  start
  startTime
  end
  endTime
}`;

const initialState: PollAddState = {
  event: null,
};

const ACTION_SET_EVENT = 'events/event/ACTION_SET_EVENT';

function setEvent(event: null | Event | ErrorCapsule) {
  return {
    type: ACTION_SET_EVENT,
    event,
  };
}

async function loadEvent(id: string) {
  try {
    const event = await getEvent(id, eventShape);
    return setEvent(event);
  } catch (err) {
    return setEvent(
      new ErrorCapsule(err, () => [setEvent(initialState.event), loadEvent(id)]),
    );
  }
}

export function pollAddReducer(
  state: PollAddState = initialState,
  action: AnyAction,
) {
  switch (action.type) {
    case ACTION_SET_EVENT:
      return {
        ...state,
        event: action.event,
      };
    default:
      return state;
  }
}

type Props = {
  id?: string,
  pollId?: string,
};

function PollAddPage({ id, pollId }: Props) {
  const dispatch = useDispatch();
  const { event }: PollAddState = useSelector(({ polls }) => polls.pollAdd);
  // eslint-disable-next-line no-shadow
  const { polls }: PollState = useSelector(({ polls }) => polls.pollX);
  const [poll, setPoll] = useState(null);

  // Load Event
  useEffect(() => {
    dispatch([setEvent(null), loadEvent(id)]);
    return () => dispatch(setEvent(null));
  }, [id]);

  // Load Poll if poll Id
  useEffect(() => {
    if (pollId && polls) {
      const tmp = polls.filter((p) => {
        return p.id === pollId;
      });
      setPoll(tmp[0]);
    }
  }, [pollId, polls]);

  // Redirect to polls if cancel
  function onCancel() {
    dispatch([redirect('/polls/' + event.id + '/')]);
  }

  if (!event) return <Loader/>;

  if (event instanceof ErrorCapsule) return <PageLoadFailed error={event}/>;
  // Render
  return (
    <PollAddPageView
      onSubmit={async (values) => {
        if (pollId) {
          dispatch([setPoll(null), editWholePoll(values, event, pollId)]);
        } else {
          dispatch([setPoll(null), createNewPoll(values, event)]);
        }
      }}
      onCancel={onCancel}
      poll={poll}
      pollId={pollId}
    />
  );
}

export function pollAddPageRoutes(): Routes {
  return [
    {
      title: 'Add Poll',
      path: '/:id/new/',
      isEnabled: ({ isUser }) => isUser,
      render: (params) => (
        <PollAddPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Edit Poll',
      path: '/:id/edit/:pollId/',
      isEnabled: ({ isUser }) => isUser,
      render: (params) => (
        <PollAddPage
          {...sanitizeRouteParams(params, {
            id: 'id',
            pollId: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
