// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { eventMetaModel } from './Event.model';
import { addressMetaModel } from './Address.model';

export function filterEventInput(values) {
  const result = _pickBy(values, (_, key) => key in eventMetaModel.rules || key === 'id');
  if (result.address) {
    result.address = _pickBy(result.address, (_, key) => key in addressMetaModel.rules);
  }
  return result;
}

export function listEvents(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: EventsFilter) {
        listEvents(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getEvent(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getEvent(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createEvent(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: EventCreate!) {
        createEvent(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateEvent(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: EventUpdate!) {
        updateEvent(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
