// @flow
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { chatReducer } from './ChatPage';
import { chatsListReducer } from './ChatsListPage';
import { sendMessageReducer } from './SendMessageWidget';
import { chatXReducer } from './redux/Chat.redux';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'messenger';

export const reducer = safeCombineReducers({
  chatX: chatXReducer,
  chat: chatReducer,
  chatsList: chatsListReducer,
  sendMessage: sendMessageReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
