// @flow
import _isString from 'lodash/isString';
import { setErrorHandler } from '../../core/exceptions/errorHandler';

export function attachSentry(Sentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // Apollo generates too long messages
    // TODO: Reformat its messages in beforeSend
    maxValueLength: 3000,
  });

  setErrorHandler((error, errorId, extras) => {
    Sentry.withScope((scope) => {
      scope.setTag('errorId', errorId);
      if (extras !== undefined) {
        let safeExtras = _isString(extras) ? extras : JSON.stringify(extras);
        if (safeExtras.length > 100000) {
          safeExtras = safeExtras.substr(0, 100000) + '...';
        }
        scope.setExtra('extra', safeExtras);
      }
      Sentry.captureException(error);
    });
  });
}
