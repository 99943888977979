// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { required } from 'redux-form-validators';
import { bemCompose, renderImageMeta } from 'metaup/render/visualUtils';
import type { User } from '../../users/model/User.model';
import Loader from '../../elements/Loader';
import PageLoadFailed from '../../elements/PageLoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormText,
} from '../../elements/DefaultForm';
import { userMetaModel } from '../../users/model/User.model';
import { icons } from '../../elements/icons';

export type FirstVisitPageViewProps = {
  user: null | User | ErrorCapsule,
  mode: 'base' | 'info' | 'job',
  onSkip?: () => void,
  onSubmit: (values: { [string]: any }) => void,
  onAvatarUpload?: x => void,
}

function Step({ isCurrent, link, isLinkEnabled }: any) {
  return isLinkEnabled
    ? <Link to={link} className={bemCompose('first-visit', 'step-mark', { current: isCurrent })} />
    : <span className={bemCompose('first-visit', 'step-mark', { current: isCurrent })} />;
}

export default function FirstVisitPageView({
  user,
  mode,
  onSkip,
  onSubmit,
  onAvatarUpload,
}: FirstVisitPageViewProps) {
  const { t } = useTranslation();

  if (!user) return <Loader />;
  if (user instanceof ErrorCapsule) return <PageLoadFailed error={user} />;

  const { avatar } = user;

  return (
    <div className="first-visit">
      {mode === 'base' && (
        <div className="page-cover">
          {avatar ? renderImageMeta(avatar.cover, t('Avatar')) : null}
          <label className="page-cover__btn">
            {t('Photo')}
            <input
              type="file"
              style={{ position: 'absolute', left: '-9999em' }}
              onChange={e => {
                if (e.target.files.length) {
                  onAvatarUpload(e.target.files[0]);
                }
              }}
            />
          </label>
        </div>
      )}
      {mode === 'base' && <div className="first-visit__base-space" />}
      {mode === 'job' && (
        <div className="first-visit__guide">
          {t('Please fill out information for attendees')}
        </div>
      )}
      {mode === 'info' && (
        <div className="first-visit__guide">
          {t('Please add your interests (e.g. Psychology, Basketball)')}
        </div>
      )}

      <DefaultForm
        className="first-visit__form"
        form="firstVisit"
        metaModel={userMetaModel}
        initialValues={user}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />

        {mode === 'base' && (
          <>
            <DefaultFormText
              attribute="firstName"
              label={userMetaModel.labels.firstName(t) + '*'}
              rules={[required(), ...userMetaModel.rules.firstName]}
              autoFocus
            />
            <DefaultFormText
              attribute="lastName"
              label={userMetaModel.labels.lastName(t) + '*'}
              rules={[required(), ...userMetaModel.rules.lastName]}
            />
          </>
        )}
        {mode === 'job' && (
          <>
            <DefaultFormText attribute="position" autoFocus />
            <DefaultFormText attribute="companyTitle" />
          </>
        )}
        {mode === 'info' && (
          <>
            <DefaultFormText attribute="interests" autoFocus />
            {/* <DefaultFormText attribute="lookingFor" /> */}
          </>
        )}

        <div className="first-visit__steps-block">
          {mode === 'job' && (
            <button type="button" className="btn btn-link" onClick={e => onSkip()}>
              {t('Skip')}
            </button>
          )}

          <div className="first-visit__steps d-inline-flex justify-content-between">
            <Step isCurrent={mode === 'base'} link="/onboarding/" isLinkEnabled={mode !== 'base'} />
            <Step isCurrent={mode === 'job'} link="/onboarding/job/" isLinkEnabled={mode === 'info'} />
            <Step isCurrent={mode === 'info'} />
          </div>

          <button type="submit" className="btn btn-link">
            {mode === 'info' ? t('Finish') : t('Next')}
            <span className="first-visit__next">{icons.back}</span>
          </button>
        </div>
      </DefaultForm>
    </div>
  );
}
