// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { attendeesReducer } from './AttendeesPage';
import { eventReducer } from './EventPage';
import { eventsListReducer } from './EventsListPage';
import { eventEditReducer } from './EventEditPage';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'events';

export const reducer = safeCombineReducers({
  attendees: attendeesReducer,
  event: eventReducer,
  eventsList: eventsListReducer,
  eventEdit: eventEditReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
