// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { chatPageRoutes } from './ChatPage';
import { chatsListPageRoutes } from './ChatsListPage';
// ### GENERATED IMPORTS GO HERE ###

export function messengerRoutes() {
  return prefixRoutes('/messenger/', [
    chatPageRoutes(),
    chatsListPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
