// @flow
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { callXReducer } from './redux/Call.redux';
import { sessionXReducer } from './redux/Session.redux';
import { sessionRoundXReducer } from './redux/SessionRound.redux';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'network';

export const reducer = safeCombineReducers({
  callX: callXReducer,
  sessionX: sessionXReducer,
  sessionRoundX: sessionRoundXReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
