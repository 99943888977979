// @flow
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';


export function getPair(id: string,shape: string) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getPair(id: $id) ${shape}
      }
    `,
    { id },
  );
}

export function listPairs(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: PairsFilter) {
        listPairs(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}
export function listAllPairs(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: PairsFilter) {
        listAllPairs(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function updatePair(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PairsFilter!) {
        updatePair(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

