// @flow
import _isObject from 'lodash/isObject';
import { AnyAction, Dispatch } from 'redux';
import { createBrowserHistory } from 'history';

import type { AsyncAction, GetState, ModuleState } from './reduxStore';

export const namespace = 'router';

const history = createBrowserHistory();

export const initialState = history.location;

const ACTION_SET_LOCATION = namespace + '/ACTION_SET_LOCATION';
const ACTION_REDIRECT = namespace + '/ACTION_REDIRECT';

export function reducer(state: ModuleState = initialState, action: AnyAction) {

  switch (action.type) {
    case ACTION_SET_LOCATION:
      return action.location;
    default:
      return state;
  }
}

export function getHistory() {
  return history;
}

export function onAction(action: AsyncAction, dispatch, getState) {
  if (_isObject(action) && action.type === ACTION_REDIRECT) {
    instantRedirect(action.pathinfo);
  }
}

export function instantRedirect(pathinfo) {
  history.push(pathinfo);
}

export function redirect(pathinfo): AsyncAction {
  return {
    type: ACTION_REDIRECT,
    pathinfo,
  };
}

export function init(dispatch: Dispatch, getState: GetState): void {
  history.listen((location, action) => {
    dispatch({
      type: ACTION_SET_LOCATION,
      location,
    });
  });
}
