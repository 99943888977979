// @flow
import gql from 'graphql-tag';
import type { SubscriptionListener } from '../../core/data/apolloClient';
import {
  apolloQuery,
  apolloSubmitForm,
  apolloSubscribe,
  getSubscriptionAuthorization,
} from '../../core/data/apolloClient';


export function getSessionRound(id: string, shape: string) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getSessionRound(id: $id) ${shape}
      }
    `,
    { id },
  );
}

export function listSessionRounds(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: SessionRoundsFilter) {
        listSessionRounds(filter: $filter) ${shape}
      }
    `,
    { filter },
  );
}

export function updateSessionRound(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: SessionRoundsFilter!) {
        updateSessionRound(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function createSessionRound(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: SessionRoundCreate!) {
        createSessionRound(input: $input) ${outputShape}
      }
    `,
    { input: inputValues },
  );
}

export function getLiveSessionRounds(sessionId: string,shape, listener: SubscriptionListener): () => void {
  return apolloSubscribe(
    gql`
      subscription ($authorization: String!, $sessionId: String!) {
        getLiveSessionRounds (authorization: $authorization, sessionId: $sessionId) {
          reset ${shape}
          update ${shape}
        }
      }
    `,
    {sessionId, authorization: getSubscriptionAuthorization() },
    listener,
  );
}
