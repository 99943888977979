// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import type { MetaModel } from '../../core/data/rules';
import { datetime, string } from '../../core/data/rules';
import type { PollChoice } from './PollChoice.model';
import type { Poll } from './Poll.model';

export type PollQuestion = {|
  id: string,
  title: string,
  questionType: ?string,
  pollId: ?string,
  poll: ?Poll,
  choices: [PollChoice],
  createdAt: ?string,
|}


export const pollQuestionMetaModel: MetaModel = {
  name: 'PollQuestion',
  rules: {
    title: [string(), required()],
    questionType: [string()],
    pollId: [string()],
    createdAt: [datetime()],
  },
};
