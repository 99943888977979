// @flow
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { profileEditPageRoutes } from './ProfileEditPage';
import { profilePageRoutes } from './ProfilePage';
// ### GENERATED IMPORTS GO HERE ###

export function usersRoutes() {
  return prefixRoutes('/', [
    profileEditPageRoutes(),
    profilePageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
