// @flow
import React, { useEffect, useRef, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

type Props = {
  className?: string,
  style?: Object,
  children: React$Node,
}

const BOTTOM_SNAP = 10;
const CHILDREN_POLL_INTERVAL = 100;

export default function ScrollToBottomArea({ className, children, style = {} }: Props) {

  const areaRef = useRef(null);
  const [isAuto, setIsAuto] = useState(true);

  function getContentsHeight() {
    return areaRef.current.scrollHeight;
  }

  useEffect(() => {
    if (!isAuto) {
      return undefined;
    }

    // Scroll on render
    let lastContentsHeight = getContentsHeight();
    areaRef.current.scrollTo({ top: lastContentsHeight, behavior: 'auto' });

    // Watch contents
    const internalId = setInterval(() => {
      if (getContentsHeight() !== lastContentsHeight) {
        lastContentsHeight = getContentsHeight();
        areaRef.current.scrollTo({ top: lastContentsHeight, behavior: 'smooth' });
      }
    }, CHILDREN_POLL_INTERVAL);
    return () => {
      clearInterval(internalId);
    };
  }, [isAuto]);

  // Stop following on scroll up. Resume on scroll to the bottom edge
  function onScroll(e) {
    setIsAuto(
      e.currentTarget.offsetHeight + e.currentTarget.scrollTop
        >= (getContentsHeight() - BOTTOM_SNAP)
    );
  }

  return (
    <div
      className={className}
      style={{ overflowY: 'auto', ...style }}
      ref={areaRef}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
}
