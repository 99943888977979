// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import _isPlainObject from 'lodash/isPlainObject';
import _find from 'lodash/find';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';
import {
  getUser,
  updateUser,
  filterUserInput,
} from './model/User.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import ProfileEditPageView from './views/ProfileEditPageView';
import type { User } from './model/User.model';
import { createUserContact, updateUserContact } from './model/UserContact.client';
import { apolloSubmitForm } from '../core/data/apolloClient';

type ProfileEditState = {
  user: null | User | ErrorCapsule,
}

const contactShape = `{
  id
  kind
  content
}`;

const userShape = `{
  id
  firstName
  lastName
  avatar {
    id
    cover(width: 320, height: 335) { width height url }
  }
  position
  companyTitle
  interests
  lookingFor
  publicContacts ${contactShape}
}`;

const initialState: ProfileEditState = {
  user: null,
};

const ACTION_SET_USER = 'users/profileEdit/ACTION_SET_USER';

function setUser(user: null | User | ErrorCapsule) {
  return {
    type: ACTION_SET_USER,
    user,
  };
}

async function loadUser(id: string) {
  try {
    const user = await getUser(id, userShape);

    return setUser(user);
  } catch (err) {
    return setUser(new ErrorCapsule(err, () => [
      setUser(initialState.user),
      loadUser(id),
    ]));
  }
}

function saveContact({
  old,
  content,
  userId,
  kind,
}) {
  if (old) {
    if (content) {
      return updateUserContact({ id: old.id, content }, contactShape);
    } else {
      return apolloSubmitForm(
        gql`
          mutation ($id: String!) {
            deleteUserContact(id: $id)
          }
        `,
        { id: old.id },
      ).then(() => null);
    }
  } else {
    if (content) {
      return createUserContact({ userId, kind, content }, contactShape);
    } else {
      return null;
    }
  }
}

export async function saveUser({ contactEmail, phone, ...values }) {
  const user = await updateUser(filterUserInput(values), userShape);
  const publicContacts = [];

  const phoneContact = await saveContact({
    old: values.phoneContact,
    content: phone,
    userId: values.id,
    kind: 'phone',
  });
  if (phoneContact) {
    publicContacts.push(phoneContact);
  }

  const emailContact = await saveContact({
    old: values.emailContact,
    content: contactEmail,
    userId: values.id,
    kind: 'email',
  });
  if (emailContact) {
    publicContacts.push(emailContact);
  }

  return [
    setUser({ ...user, publicContacts }),
    redirect(`/people/${values.id}/`),
  ];
}

export async function uploadAvatar(userId, file) {
  const user = await updateUser({ id: userId, avatar: file }, userShape);
  return setUser(user);
}

export function profileEditReducer(
  state: ProfileEditState = initialState,
  action: AnyAction,
) {
  switch (action.type) {

    case ACTION_SET_USER:
      return {
        ...state,
        // Merge or replace user
        user: (
          _isPlainObject(state.user)
          && _isPlainObject(action.user)
        )
          ? {
            ...state.user,
            ...action.user,
          }
          : action.user,
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
}

function ProfileEditPage({
  id,
}: Props) {
  const dispatch = useDispatch();
  const {
    user,
  }: ProfileEditState = useSelector(({ users }) => users.profileEdit);

  // Load User
  useEffect(() => {
    dispatch([
      setUser(null),
      loadUser(id),
    ]);
    return () => dispatch(setUser(null));
  }, [id]);

  // Extend model
  let extendedUser = user;
  let phoneContact;
  let emailContact;
  if (_isPlainObject(user)) { // Not an error
    phoneContact = _find(user.publicContacts, ({ kind }) => kind === 'phone');
    emailContact = _find(user.publicContacts, ({ kind }) => kind === 'email');
    extendedUser = {
      ...user,
      phone: phoneContact ? phoneContact.content : '',
      contactEmail: emailContact ? emailContact.content : '',
    };
  }

  // Render
  return (
    <ProfileEditPageView
      user={extendedUser}
      onSubmit={(values: User) => dispatch(saveUser({ ...values, phoneContact, emailContact }))}
      onAvatarUpload={file => dispatch(uploadAvatar(id, file))}
    />
  );
}

export function profileEditPageRoutes(): Routes {
  return [
    {
      title: t => t('Profile Edit'),
      path: '/profile/',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        onBack: history => history.goBack(),
        showTitle: true,
      },
      render: (params, auth: RouteAuth) => (
        <ProfileEditPage
          {...sanitizeRouteParams(params, {})}
          id={auth.authState.user.id}
        />
      ),
      design: require('./views/ProfileEditPageView.design.mobile.png'), // eslint-disable-line global-require
    },
  ];
}
