// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
} from '../../elements/DefaultForm';
import { userContactMetaModel } from '../model/UserContact.model';
import type { UserContact } from '../model/UserContact.model';

import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';

export type ProfileEditContactWidgetViewProps = {
  userContact: null | UserContact | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
}

export default function ProfileEditContactWidgetView({
  userContact,
  onSubmit,
}: ProfileEditContactWidgetViewProps) {
  const { t } = useTranslation();

  if (!userContact) return <Loader inline />;
  if (userContact instanceof ErrorCapsule) return <LoadFailed error={userContact} />;
  const isCreating = userContact.id == null;

  return (
    <div className="profile-edit-contact">
      <h1>{t('Profile Edit Contact')}</h1>
      <DefaultForm
        form="profileEditContact"
        metaModel={userContactMetaModel}
        initialValues={userContact}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormText attribute="userId" autoFocus />
        <DefaultFormText attribute="kind" />
        <DefaultFormText attribute="title" />
        <DefaultFormText attribute="content" />
        <DefaultFormSubmit label={isCreating ? t('Create') : t('Update')} />
      </DefaultForm>
    </div>
  );
}
