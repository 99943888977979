// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { ApolloError } from 'apollo-client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import LoadFailedView from './views/LoadFailedView';
import errorMessages from '../core/exceptions/errorMessages';
import { handleError } from '../core/exceptions/errorHandler';

export type LoadFailedProps = {
  error: string | ErrorCapsule | Error,
  onClose?: ?() => void,
}

function decodeException(e: Error) {
  if (e instanceof ApolloError) {
    if (e.networkError) {
      handleError(e, e.networkError); // Log to collect stats
      return errorMessages.connectionProblems;
    }
    // Do not show everything to user at once. Focus on the first.
    // If we'll face some cases where we need different approach, we'll update this logic.
    // Don't change it until then.
    if (e.graphQLErrors && e.graphQLErrors[0]) {
      const gqlErr = e.graphQLErrors[0];
      if (gqlErr.extensions && gqlErr.extensions.code === 'NOT_FOUND') {
        return errorMessages.pageNotFound;
      }
      return gqlErr.message;
    }
  }
  return errorMessages.internalProblems;
}

export default function LoadFailed({ error, onClose }: LoadFailedProps) {
  const dispatch = useDispatch();

  // Decode message
  let message;
  if (error instanceof ErrorCapsule) {
    message = decodeException(error.error);
  } else if (error instanceof Error) {
    message = decodeException(error);
  } else {
    message = error;
  }

  // Implement onRetry
  const onRetry = error instanceof ErrorCapsule
    ? () => {
      if (onClose) {
        onClose();
      }
      const action = error.retry();
      if (action) {
        dispatch(action);
      }
    }
    : () =>{
      window.location.reload();
    };

  return (
    <LoadFailedView
      message={message}
      onRetry={onRetry}
      onClose={onClose}
    />
  );
}

export const testOnly = {
  decodeException,
};
