// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import gql from 'graphql-tag';
import type { Routes } from 'metaup/routing/routingUtils';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';

import RegisterPageView from './views/RegisterPageView';
import type { AsyncAction } from '../core/data/reduxStore';
import { apolloSubmitForm } from '../core/data/apolloClient';
import { authShape } from './authState';
import { finishAuth } from './AuthHomePage';

export async function register(
  email: string,
  password: string,
  joinToEventId: ?string,
): ?AsyncAction {
  const result = await apolloSubmitForm(
    gql`
      mutation ($input: AuthRegister!) {
        authRegister(input: $input) ${authShape}
      }
    `,
    { input: { email, password } },
  );

  return finishAuth(result, joinToEventId);
}

type Props = {
  joinToEventId: ?string,
}

function RegisterPage({
  joinToEventId,
}: Props) {
  const dispatch = useDispatch();

  return (
    <RegisterPageView
      urlPrefix={joinToEventId ? `/join/${joinToEventId}` : ''}
      onSubmit={({ email, password }) => dispatch(register(email, password, joinToEventId))}
    />
  );
}

export function registerPageRoutes(): Routes {
  return [
    {
      title: t => t('Sign Up'),
      path: [
        '/register',
        '/join/:joinToEventId/register',
      ],
      render: params => (
        <RegisterPage
          {...sanitizeRouteParams(params, {
            joinToEventId: '?id',
          }, {
            joinToEventId: null,
          })}
        />
      ),
      design: require('./views/RegisterPageView.design.png'), // eslint-disable-line global-require
    },
  ];
}
