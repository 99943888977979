// @flow
import React, {useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Set } from 'immutable';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { listUserEvents } from './model/UserEvent.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import AttendeesPageView from './views/AttendeesPageView';
import type { UserEvent } from './model/UserEvent.model';

type AttendeesState = {
  userEvents: null | Array<UserEvent> | ErrorCapsule,
  filter: string,
  filtered: null | Array<UserEvent> | ErrorCapsule,
  expanded: Set<string>,
}

const userEventShape = `{
  id
  user {
    id
    email
    firstName
    lastName
    avatar {
      id
      cover(width: 96, height: 96) { width height url }
    }
    isOrganizer
    isAdmin
    position
    companyTitle
    interests
    lookingFor
  }
}`;

const initialState: AttendeesState = {
  userEvents: null,
  filter: '',
  filtered: null,
  expanded: new Set<string>(),
};

const ACTION_SET_USER_EVENTS = 'events/attendees/ACTION_SET_USER_EVENTS';
const ACTION_SET_FILTER = 'events/attendees/ACTION_SET_FILTER';
const ACTION_SET_EXPANDED = 'events/attendees/ACTION_SET_EXPANDED';

function setUserEvents(userEvents: null | Array<UserEvent> | ErrorCapsule) {
  return {
    type: ACTION_SET_USER_EVENTS,
    userEvents,
  };
}

function setFilter(filter: string) {
  return {
    type: ACTION_SET_FILTER,
    filter,
  };
}

function setExpanded(userId: string, expanded: boolean) {
  return {
    type: ACTION_SET_EXPANDED,
    userId,
    expanded,
  };
}

async function loadUserEvents(filter?: ?{ [string]: string }) {
  try {
    const userEvents = await listUserEvents(filter, userEventShape);

    return setUserEvents(userEvents);
  } catch (err) {
    return setUserEvents(new ErrorCapsule(err, () => [
      setUserEvents(initialState.userEvents),
      loadUserEvents(filter),
    ]));
  }
}

function strContains(substr, subject: ?string) {
  return subject && subject.toLocaleLowerCase().indexOf(substr) !== -1;
}

function filterEvents(filter, events) {
  if (!Array.isArray(events)) {
    return events;
  }
  const lcFilter = filter.toLocaleLowerCase();
  return events.filter(
    ({
      user: {
        firstName,
        lastName,
        position,
        companyTitle,
        interests,
        lookingFor,
      },
    }: UserEvent) => (
      strContains(lcFilter, firstName)
      || strContains(lcFilter, lastName)
      || strContains(lcFilter, position)
      || strContains(lcFilter, companyTitle)
      || strContains(lcFilter, interests)
      || strContains(lcFilter, lookingFor)
    )
  );
}

export function attendeesReducer(
  state: AttendeesState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_USER_EVENTS:
      return {
        ...state,
        userEvents: action.userEvents,
        filtered: filterEvents(state.filter, action.userEvents),
      };

    case ACTION_SET_FILTER:
      return {
        ...state,
        filter: action.filter,
        filtered: filterEvents(action.filter, state.userEvents),
      };

    case ACTION_SET_EXPANDED:
      return {
        ...state,
        expanded: action.expanded
          ? state.expanded.add(action.userId)
          : state.expanded.delete(action.userId),
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
}

function AttendeesPage({
  id,
}: Props) {
  const dispatch = useDispatch();
  const {
    filtered: userEvents,
    // filter,
    expanded,
  }: AttendeesState = useSelector(({ events }) => events.attendees);
  // Load UserEvents
  useEffect(() => {
    dispatch([
      setUserEvents(null),
      loadUserEvents({ eventId: id }),
    ]);
    return () => dispatch(setUserEvents(null));
  }, []);

  // Render
  return (
    <AttendeesPageView
      userEvents={userEvents}
      filter={false} // not implemented, hide
      expanded={expanded}
      onFilterChange={newValue => dispatch(setFilter(newValue))}
      onSetExpanded={(userId, newValue) => dispatch(setExpanded(userId, newValue))}
    />
  );
}

export function attendeesPageRoutes(): Routes {
  return [
    {
      title: t => t('Attendees'),
      path: ':id/attendees/',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        showHamburger: true,
        eventMenu: 'attendees',
        showTitle: true,
      },
      render: params => (
        <AttendeesPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: require('./views/AttendeesPageView.design.mobile.png'), // eslint-disable-line global-require
    },
  ];
}
