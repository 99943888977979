// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { date, required } from 'redux-form-validators';
import type { MetaModel } from '../../core/data/rules';
import { image, nested, string, time } from '../../core/data/rules';
import type { Address } from './Address.model';
import { addressMetaModel } from './Address.model';
import type { Company } from '../../users/model/Company.model';
import type { UserEvent } from './UserEvent.model';

export type Event = {|
  id: string,
  title: string,
  start: string,
  startTime: ?string,
  end: string,
  endTime: ?string,
  cover: ?ImageField,
  description: ?string,
  organizerId: ?string,
  organizer: ?Company,
  address: Address,
  currentUserRelation: ?UserEvent,
|}


export const eventMetaModel: MetaModel = {
  name: 'Event',
  rules: {
    title: [string(), required()],
    start: [date({ format: 'yyyy-mm-dd' }), required()],
    startTime: [time()],
    end: [date({ format: 'yyyy-mm-dd' }), required()],
    endTime: [time()],
    cover: [image()],
    description: [string()],
    organizerId: [string()],
    address: [nested(addressMetaModel), required()],
  },
};
